import { ReactNode } from "react";
import { Model } from "api-client";

import { IconMap } from "~/lib/constants";

export interface IconProps {
  className?: string;
  name?: Model["icon"] | null;
  fallback?: ReactNode;
  "aria-hidden"?: boolean;
}

export function Icon({
  name,
  className,
  fallback = null,
  "aria-hidden": ariaHidden = true,
}: IconProps) {
  const Component = name ? IconMap[name] : null;
  if (!Component) return fallback;
  return <Component className={className} aria-hidden={ariaHidden} />;
}
