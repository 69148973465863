import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  AnnotationQuestion,
  Home02,
  Settings02,
  X,
} from "@untitled-ui/icons-react";
import {
  Favorite,
  Model,
  ModelRecord,
  organizationsModelQueryOptions,
  organizationsModelsRecordQueryOptions,
  reorderFavoritesMutationOptions,
  unfavoriteModelMutationOptions,
  unfavoriteRecordMutationOptions,
  useInfiniteData,
  useOrganizationsApplicationsFavoritesInfiniteQuery,
  useOrganizationsApplicationsModelsInfiniteQuery,
} from "api-client";
import {
  animate,
  AnimatePresence,
  LayoutGroup,
  m,
  useMotionValue,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { useDebouncedCallback } from "use-debounce";

import { cn } from "@baselayer/ui/lib/cn";
import { easeOutSmooth } from "@baselayer/ui/lib/motion";

import { AccountDropdown } from "./AccountDropdown";
import { SidebarGroup, SidebarItem } from "./Item";

import { modelActions } from "~/actions/model";
import { recordActions } from "~/actions/record";
import { RecordThumbnail } from "~/components/_records/RecordThumbnail";
import { ActionMenu } from "~/components/ActionMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { DesktopNavigation } from "~/components/Sidebar/DesktopNavigation";
import { FeedbackPopover } from "~/components/Sidebar/FeedbackPopover";
import { useScreenMatches } from "~/lib/breakpoint";
import { useParams } from "~/router";

interface ModelItemProps {
  model: Model;
  reorderOptions?: { value: string };
  action?: ReactNode;
  exact?: boolean;
}
function ModelItem({
  model: initialData,
  reorderOptions,
  action,
  exact,
}: ModelItemProps) {
  const modelQuery = useQuery({
    ...organizationsModelQueryOptions({
      organizationSlug: initialData.organization_slug,
      id: initialData.id,
    }),
    initialData,
  });
  const model = modelQuery.data;

  return (
    <ActionMenu
      as="contextmenu"
      actions={modelActions}
      target={model}
      trigger={
        <SidebarItem
          icon={() => (
            <Icon
              name={model.icon}
              className="w-4 h-4"
              fallback={<div className="w-4 h-4 rounded bg-avatar" />}
            />
          )}
          label={model.name}
          to="/o/:org/w/:app/m/:model"
          params={{
            org: model.organization_slug,
            app: model.application_slug,
            model: model.id,
          }}
          exact={exact}
          reorderOptions={reorderOptions}
          action={action}
        />
      }
    />
  );
}

interface ModelRecordItemProps {
  record: ModelRecord;
  reorderOptions?: { value: string };
  action?: ReactNode;
  exact?: boolean;
}
function ModelRecordItem({
  record: initialData,
  reorderOptions,
  action,
  exact,
}: ModelRecordItemProps) {
  const recordQuery = useQuery({
    ...organizationsModelsRecordQueryOptions({
      organizationSlug: initialData.organization_slug,
      modelId: initialData.model_id,
      id: initialData.id,
    }),
    initialData,
  });
  const record = recordQuery.data;

  const modelQuery = useQuery(
    organizationsModelQueryOptions({
      organizationSlug: record.organization_slug,
      id: record.model_id,
    }),
  );
  const model = modelQuery.data;

  return (
    <ActionMenu
      as="contextmenu"
      actions={recordActions}
      target={record}
      trigger={
        <SidebarItem
          icon={() => <RecordThumbnail record={record} model={model} />}
          label={record.title ?? record.id}
          to="/o/:org/w/:app/m/:model/r/:record"
          params={{
            org: record.organization_slug,
            app: record.application_slug,
            model: record.model_id,
            record: record.id,
          }}
          reorderOptions={reorderOptions}
          action={action}
          exact={exact}
        />
      }
    />
  );
}

function ModelsGroup() {
  const params = useParams("/o/:org/w/:app");

  const modelsQuery = useOrganizationsApplicationsModelsInfiniteQuery({
    organizationSlug: params.org,
    applicationSlug: params.app,
  });
  const data = useInfiniteData(modelsQuery.data);

  useEffect(() => {
    if (modelsQuery.hasNextPage && !modelsQuery.isFetchingNextPage) {
      modelsQuery.fetchNextPage();
    }
  }, [modelsQuery]);

  if (modelsQuery.isPending) {
    return (
      <div className="p-2">
        <div className="w-[60%] h-4 rounded-md bg-subtle" />
      </div>
    );
  }

  if (!modelsQuery.isPending && !data.length) {
    return (
      <div className="p-2">
        <p className="text-sm text-placeholder">No models</p>
      </div>
    );
  }

  return (
    <AnimatePresence initial={false}>
      {data.map((model) => (
        <ModelItem key={model.id} model={model} />
      ))}
    </AnimatePresence>
  );
}

function FavoritesGroup() {
  const params = useParams("/o/:org/w/:app");

  const favoritesQuery = useOrganizationsApplicationsFavoritesInfiniteQuery({
    organizationSlug: params.org,
    applicationSlug: params.app,
  });
  const favorites = useInfiniteData(favoritesQuery.data);

  const getKey = useCallback((favorite: Favorite) => {
    switch (favorite.favoritable.type) {
      case "Model":
        return favorite.favoritable.id;
      case "ModelRecord":
        return `${favorite.favoritable.model_id}/${favorite.favoritable.id}`;
      default:
        return favorite.id;
    }
  }, []);

  const reorderValues = useMemo(
    () => favorites.map(getKey),
    [favorites, getKey],
  );
  const [items, setItems] = useState(reorderValues);
  useLayoutEffect(() => {
    setItems(reorderValues);
  }, [reorderValues]);

  const orderedFavorites = useMemo(() => {
    const favoritesMap = new Map(
      favorites.map((favorite) => [getKey(favorite), favorite]),
    );
    return items.flatMap((key) => {
      const favorite = favoritesMap.get(key);
      if (!favorite) return [];
      return [favorite];
    });
  }, [favorites, items, getKey]);

  const unfavoriteModel = useMutation(unfavoriteModelMutationOptions());
  const unfavoriteRecord = useMutation(unfavoriteRecordMutationOptions());

  const reorder = useMutation(reorderFavoritesMutationOptions());
  const debouncedReorder = useDebouncedCallback(
    () => {
      if (!items.length) return;

      const favoritesMap = new Map(
        favorites.map((favorite) => [getKey(favorite), favorite]),
      );
      const reorderMap = new Map(
        reorderValues.map((key, index) => [key, index]),
      );
      const itemsMap = new Map(items.map((key, index) => [key, index]));

      const diff: { id: string; position: number }[] = [];
      reorderMap.forEach((index, key) => {
        const favorite = favoritesMap.get(key);
        const currentIndex = itemsMap.get(key)!;
        if (favorite && currentIndex !== index) {
          diff.push({ id: favorite.id, position: currentIndex });
        }
      });

      if (!diff.length) return;

      reorder.mutate({
        organizationSlug: params.org,
        applicationSlug: params.app,
        items: diff,
      });
    },
    200,
    { trailing: true },
  );
  useEffect(() => {
    debouncedReorder();
  }, [debouncedReorder, items]);

  const disableAnimation = useScreenMatches("collapse-sidebar");

  if (favoritesQuery.isPending) {
    return null;
  }

  return (
    <AnimatePresence initial={false}>
      {favorites.length > 0 && (
        <m.div
          variants={{
            closed: { opacity: 0, height: 0, scale: 0.95 },
            open: { opacity: 1, height: "auto", scale: 1 },
          }}
          initial={disableAnimation ? "open" : "closed"}
          animate="open"
          exit={disableAnimation ? "open" : "closed"}
          transition={{ duration: 0.2, ease: easeOutSmooth }}
        >
          <SidebarGroup
            title="Favorites"
            collapsible
            reorderOptions={{
              values: items,
              onReorder: setItems,
            }}
          >
            {orderedFavorites.map((favorite) => {
              const key = getKey(favorite);
              switch (favorite.favoritable.type) {
                case "Model":
                  return (
                    <ModelItem
                      key={key}
                      model={favorite.favoritable}
                      exact
                      reorderOptions={{ value: key }}
                      action={
                        <IconButton
                          className="-m-1 opacity-0 group-hover/item:opacity-100 focus:opacity-100"
                          icon={X}
                          size="sm"
                          accessibilityLabel="Remove"
                          onClick={() => {
                            if (favorite.favoritable.type !== "Model") return;
                            unfavoriteModel.mutate({
                              organizationSlug:
                                favorite.favoritable.organization_slug,
                              modelId: favorite.favoritable.id,
                            });
                          }}
                        />
                      }
                    />
                  );

                case "ModelRecord":
                  return (
                    <ModelRecordItem
                      key={key}
                      record={favorite.favoritable}
                      exact
                      reorderOptions={{ value: key }}
                      action={
                        <IconButton
                          className="-m-1 opacity-0 group-hover/item:opacity-100 focus:opacity-100"
                          icon={X}
                          size="sm"
                          accessibilityLabel="Remove"
                          onClick={() => {
                            if (favorite.favoritable.type !== "ModelRecord")
                              return;
                            unfavoriteRecord.mutate({
                              organizationSlug:
                                favorite.favoritable.organization_slug,
                              modelId: favorite.favoritable.model_id,
                              recordId: favorite.favoritable.id,
                            });
                          }}
                        />
                      }
                    />
                  );

                default:
                  return null;
              }
            })}
          </SidebarGroup>
        </m.div>
      )}
    </AnimatePresence>
  );
}

export function Sidebar() {
  const params = useParams("/o/:org/w/:app");
  const location = useLocation();

  const scrollRef = useRef<HTMLDivElement>(null);
  const scroll = useScroll({
    container: scrollRef,
  });
  const borderOpacity = useMotionValue(0);
  useMotionValueEvent(scroll.scrollY, "change", (value) => {
    if (value > 0) {
      animate(borderOpacity, 1, { duration: 0.2 });
    } else {
      borderOpacity.stop();
      borderOpacity.set(0);
    }
  });

  return (
    <nav className="size-full max-h-screen sticky top-0 bg-panel">
      <div
        ref={scrollRef}
        className={cn(
          "absolute inset-0 overflow-y-auto",
          "flex flex-col border-r border-primary",
        )}
      >
        <div className="sticky top-0 z-20 bg-panel p-2.5 px-2 w-full flex flex-col gap-2.5">
          <DesktopNavigation />

          <AccountDropdown />

          <m.div
            className="absolute inset-x-0 bottom-0 border-b border-primary"
            style={{ opacity: borderOpacity } as any}
          />
        </div>

        <div
          className="flex flex-col flex-1 p-1.5 pt-0 pb-2"
          key={`${params.org}/${params.app}`}
        >
          <LayoutGroup>
            <SidebarGroup className="-mb-1">
              <SidebarItem
                icon={Home02}
                label="Home"
                to="/o/:org/w/:app"
                params={{ org: params.org, app: params.app }}
                exact
              />
            </SidebarGroup>

            <FavoritesGroup />

            <SidebarGroup title="Models" className="z-10 flex-1">
              <ModelsGroup />
            </SidebarGroup>

            <SidebarGroup className="-mb-2">
              <FeedbackPopover
                trigger={
                  <SidebarItem icon={AnnotationQuestion} label="Feedback" />
                }
              />
              <SidebarItem
                icon={Settings02}
                label="Settings"
                to="/settings/o/:org/w/:app"
                params={{ org: params.org, app: params.app }}
                exact
                state={{ redirect: location.pathname }}
              />
            </SidebarGroup>
          </LayoutGroup>
        </div>
      </div>
    </nav>
  );
}
