import { PropsWithChildren } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Button } from "@baselayer/ui/components/Button";

import { withAuth } from "~/lib/auth";
import { useCurrentUser } from "~/lib/current";
import { useSignOutMutation } from "~/mutations";
import { Navigate } from "~/router";

export interface OnboardingLayoutProps extends PropsWithChildren {}

export function OnboardingLayout({ children }: OnboardingLayoutProps) {
  const location = useLocation();
  const me = useCurrentUser();
  const signOut = useSignOutMutation();

  if (!me) return null;

  if (!me.email_verified && location.pathname !== "/verify") {
    return <Navigate to="/verify" replace />;
  }

  return (
    <div className="flex-1 flex flex-col p-4 pb-0 todesktop:pt-10 gap-3">
      <div className="flex flex-col flex-1 w-full max-w-lg mx-auto py-10 max-sm:pt-6">
        {children ?? <Outlet />}
      </div>

      <footer className="mx-auto w-full max-w-lg">
        <div className="flex justify-between items-start border-t border-primary py-4 gap-4">
          <p className="text-secondary text-sm flex flex-col gap-0.5">
            Signed in as
            <span className="text-primary">{me?.email}</span>
          </p>

          <Button
            variant="subtle"
            size="xs"
            onClick={() => {
              if (!me) return;
              signOut.mutate({ user_id: me.id });
            }}
          >
            Sign out
          </Button>
        </div>
      </footer>
    </div>
  );
}

const ProtectedCreateOrganization = withAuth(OnboardingLayout, {
  type: "authenticated",
});

export default ProtectedCreateOrganization;
