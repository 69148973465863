import { useId, useState } from "react";
import { ChevronDown } from "@untitled-ui/icons-react";

import { cn } from "@baselayer/ui/lib/cn";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";

export interface SelectProps<Option extends string> {
  name?: string;
  options: Option[];
  value: Option;
  label: string;
  hideLabel?: boolean;
  onValueChange(value: Option): void;
  readOnly?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  placeholder?: string;
}

export function Select<Option extends string>({
  name,
  options,
  value,
  onValueChange,
  label,
  hideLabel,
  readOnly,
  size = "lg",
  placeholder,
}: SelectProps<Option>) {
  const id = useId();

  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col items-start gap-2 flex-1 group">
      <FieldLabel hidden={hideLabel} id={id} readOnly={readOnly}>
        {label}
      </FieldLabel>

      <DropdownMenu
        modal
        open={open}
        onOpenChange={setOpen}
        value={value}
        size={size === "sm" || size === "xs" ? "sm" : "md"}
        title={label}
        trigger={
          <button
            className={cn(
              {
                "px-3.5 h-11 gap-2": size === "lg",
                "px-3.5 h-10 gap-2": size === "md",
                "px-3 h-9 text-[15px] gap-1.5": size === "sm",
                "px-3 h-8 text-[15px] gap-1.5": size === "xs",
              },
              "min-w-0 w-full flex items-center",
              "bg-action text-primary rounded-xl ring-1 ring-inset ring-action shadow appearance-none",
              "enabled:isolate-group-hover:bg-action-active",
              "focus:outline-none focus-within:ring-black focus-within:bg-action-active",
              "data-[state=open]:ring-black data-[state=open]:bg-action-active",
              "disabled:text-secondary disabled:bg-action/50",
            )}
            aria-describedby={id}
            name={name}
            disabled={readOnly}
            aria-readonly={readOnly}
          >
            <span
              className={cn(
                "flex-1 truncate text-left",
                !value && "text-placeholder",
              )}
            >
              {value || placeholder}
            </span>
            <ChevronDown
              className={cn(
                "shrink-0 w-4 h-4 -mr-0.5 text-icon",
                readOnly && "hidden",
              )}
            />
          </button>
        }
        popoverClassName="max-w-[var(--radix-popover-trigger-width)]"
      >
        {options.map((option) => (
          <DropdownItem
            key={option}
            value={option}
            onSelect={() => {
              onValueChange(option);
              setOpen(false);
            }}
          >
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </div>
  );
}
