import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AtSign, Plus } from "@untitled-ui/icons-react";
import { useAcceptInviteMutation, useInviteQuery } from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";

import { OrganizationAvatar } from "~/components/OrganizationAvatar";
import { TextField } from "~/components/TextField";
import { handleFormErrors } from "~/lib/handleFormErrors";
import NotFound from "~/pages/404";
import { useNavigate, useParams } from "~/router";
import { Logo } from "~/svgs/Logo";

const InviteFormSchema = z.object({
  username: z.string().min(1),
});

type InviteFormSchema = z.infer<typeof InviteFormSchema>;

function InvitePage() {
  const navigate = useNavigate();
  const params = useParams("/invite/:token");

  const inviteQuery = useInviteQuery({ token: params.token });

  const methods = useForm({
    resolver: zodResolver(InviteFormSchema),
    defaultValues: {
      username: "",
    },
  });

  const accept = useAcceptInviteMutation();
  const submit = methods.handleSubmit((data) => {
    if (!inviteQuery.data) return;

    accept.mutate(
      { username: data.username, inviteToken: params.token },
      {
        onError(error) {
          handleFormErrors(error, methods.setError);
        },
        onSuccess() {
          navigate("/o/:org", {
            params: { org: inviteQuery.data.organization.slug },
          });
        },
      },
    );
  });

  if (inviteQuery.isPending) return null;

  if (!inviteQuery.isSuccess) return <NotFound />;

  const invite = inviteQuery.data;

  return (
    <div className="p-4 flex-1 gap-4 flex flex-col justify-center mx-auto max-w-md">
      <div className="flex items-center gap-3" aria-hidden="true">
        <div className="w-7 h-7 text-icon">
          <Logo />
        </div>
        <Plus className="w-5 h-5 text-icon-subtle" />
        <OrganizationAvatar size="lg" organization={invite.organization} />
      </div>

      <header className="flex flex-col gap-1">
        <h1 className="text-xl font-medium">
          Join your teammates in {invite.organization.name}
        </h1>
        <p className="text-secondary">
          Choose a username to accept this invite
        </p>
      </header>

      <form onSubmit={submit} className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <TextField
            control={methods.control}
            name="username"
            label="Username"
            leadingAccessory={<AtSign />}
          />
        </div>

        <Button type="submit">Accept invite</Button>
      </form>
    </div>
  );
}

export default InvitePage;
