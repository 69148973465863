import { useMutation } from "@tanstack/react-query";
import {
  createUser,
  createUsersAuthenticate,
  createUsersAuthenticateProvider,
  createUsersForgotPassword,
  createUsersResendVerification,
  createUsersResetPassword,
  createUsersSignOut,
  createUsersSwitch,
  createUsersVerify,
  mutationOptions,
  queryClient,
  StoragePresignedPost,
  usersInfiniteQueryOptions,
  usersMeQueryOptions,
} from "api-client";
import axios from "axios";

import { useAuthStateStore } from "~/lib/auth";
import { getFormError } from "~/lib/handleFormErrors";
import { pubsub } from "~/lib/pubsub";

export function useSignupMutation() {
  return useMutation({
    mutationFn: createUser,
    onSuccess(data) {
      queryClient.resetQueries();
      queryClient.setQueryData(usersMeQueryOptions({}).queryKey, data);
      useAuthStateStore.getState().setStatus("success");
    },
  });
}

export function useSigninMutation() {
  return useMutation({
    mutationFn: createUsersAuthenticate,
    onSuccess(data) {
      queryClient.resetQueries();
      queryClient.setQueryData(usersMeQueryOptions({}).queryKey, data);
      useAuthStateStore.getState().setStatus("success");
    },
    onError(error) {
      if (getFormError("email", error).includes("not_verified")) {
        useAuthStateStore.getState().setStatus("pending");
      }
    },
  });
}

export function useSigninWithProviderMutation() {
  return useMutation({
    mutationFn: createUsersAuthenticateProvider,
    onSuccess(data) {
      queryClient.resetQueries();
      queryClient.setQueryData(usersMeQueryOptions({}).queryKey, data);
      useAuthStateStore.getState().setStatus("success");
    },
  });
}

export function useForgotPasswordMutation() {
  return useMutation({
    mutationFn: createUsersForgotPassword,
  });
}

export function useResetPasswordMutation() {
  return useMutation({
    mutationFn: createUsersResetPassword,
  });
}

export function useVerifyMutation() {
  return useMutation(
    mutationOptions({
      mutationFn: createUsersVerify,
      onMutate(_variables, register) {
        register({
          query: usersMeQueryOptions({}),
          update: (cache) => ({
            ...cache,
            email_verified: true,
          }),
        });
      },
      onSuccess() {
        useAuthStateStore.getState().setStatus("success");
      },
    }),
  );
}

export function switchUserMutationOptions() {
  return mutationOptions({
    mutationFn: createUsersSwitch,
    onSuccess() {
      queryClient.resetQueries();
      pubsub.emit("AUTH_STATE_CHANGE");
    },
  });
}

export function useSwitchUserMutation() {
  return useMutation(switchUserMutationOptions());
}

export function signOutMutationOptions() {
  return mutationOptions({
    mutationFn: createUsersSignOut,
    onSuccess(_data, variables) {
      const currentUser = queryClient.getQueryData(
        usersMeQueryOptions({}).queryKey,
      );
      if (currentUser?.id === variables.user_id) {
        queryClient.resetQueries();
      } else {
        queryClient.invalidateQueries({
          queryKey: usersMeQueryOptions({}).queryKey,
          exact: true,
        });
        queryClient.invalidateQueries({
          queryKey: usersInfiniteQueryOptions({}).queryKey,
          exact: true,
        });
      }

      useAuthStateStore.getState().setStatus("none");
    },
  });
}

export function useSignOutMutation() {
  return useMutation(signOutMutationOptions());
}

export function useResendVerificationMutation() {
  return useMutation({
    mutationFn: createUsersResendVerification,
  });
}

export interface UploadFileParams {
  file: Blob;
  presign(): Promise<StoragePresignedPost>;
}
export function uploadFileMutationOptions() {
  return mutationOptions({
    mutationFn: async (params: UploadFileParams) => {
      const post = await params.presign();
      const formData = new FormData();
      Object.entries(post.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", params.file);

      await axios.post(post.url, formData);

      return post.fields.key;
    },
  });
}
