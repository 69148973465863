import { useEffect, useState } from "react";
import toast from "react-hot-toast/headless";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { usersAuthenticateProviderQueryOptions } from "api-client";

import { Button } from "@baselayer/ui/components/Button";

import { useSigninWithProviderMutation } from "~/mutations";
import { useNavigate } from "~/router";
import { Google } from "~/svgs/Google";

export function OAuthProviders() {
  const navigate = useNavigate();

  const googleProviderQuery = useQuery(
    usersAuthenticateProviderQueryOptions({
      provider: "google",
      redirect_uri: `${window.location.origin}/callback`,
    }),
  );
  const googleAuthUrl = googleProviderQuery.data?.location;

  const signInWithProvider = useSigninWithProviderMutation();
  const [webAuthCode, setWebAuthCode] = useState<string | null>(null);
  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.type === "PROVIDER_SIGN_IN_CALLBACK") {
        setWebAuthCode(event.data.code);
      }
    });
  }, []);
  const [search] = useSearchParams();
  const code = search.get("code") ?? webAuthCode;
  useEffect(() => {
    if (!code) return;
    signInWithProvider.mutate(
      { code },
      {
        onSuccess() {
          navigate("/account", { replace: true });
        },
        onError() {
          toast.error("Failed to sign in. Try again later.");
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <Button
      icon={Google}
      size="lg"
      variant="secondary"
      onClick={() => {
        if (!googleAuthUrl) return;
        window.open(googleAuthUrl, "_blank");
      }}
    >
      Continue with Google
    </Button>
  );
}
