import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Copy01 } from "@untitled-ui/icons-react";
import {
  Application,
  createApplicationHmacSecretMutationOptions,
  deleteApplicationHmacSecretMutationOptions,
} from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";

import { AlertDialog } from "~/components/AlertDialog";
import { IconButton } from "~/components/IconButton";
import { TextField } from "~/components/TextField";

const HIDDEN_HMAC_SECRET = "this_is_a_fake_hmac_secret";
const requestSigningSchema = z.object({
  hmac_secret: z.string().optional(),
});
type RequestSigningSchema = z.infer<typeof requestSigningSchema>;

export interface RequestSigningFormProps {
  application: Application;
}

export function RequestSigningForm({ application }: RequestSigningFormProps) {
  const defaultValues: RequestSigningSchema = useMemo(
    () => ({
      hmac_secret: application.has_hmac_secret ? HIDDEN_HMAC_SECRET : undefined,
    }),
    [application],
  );

  const methods = useForm<RequestSigningSchema>({
    resolver: zodResolver(requestSigningSchema),
    defaultValues,
  });

  const createMutation = useMutation(
    createApplicationHmacSecretMutationOptions(),
  );

  const destroyMutation = useMutation(
    deleteApplicationHmacSecretMutationOptions(),
  );

  const hmacSecret = methods.watch("hmac_secret");
  useEffect(() => {
    methods.reset({
      ...defaultValues,
      hmac_secret: hmacSecret ?? defaultValues.hmac_secret,
    });
  }, [defaultValues, hmacSecret, methods]);

  if (!hmacSecret) {
    return (
      <div className="flex flex-col items-start">
        <Button
          type="button"
          variant="secondary"
          busy={createMutation.isPending}
          disabled={createMutation.isPending}
          onClick={() => {
            createMutation.mutate(
              {
                applicationSlug: application.slug,
                organizationSlug: application.organization_slug,
              },
              {
                onSuccess(data) {
                  methods.setValue("hmac_secret", data.hmac_secret);
                },
              },
            );
          }}
        >
          Generate HMAC secret
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start w-full gap-3">
      <div className="flex items-end w-full gap-1.5">
        <TextField
          control={methods.control}
          name="hmac_secret"
          label="HMAC secret"
          type={hmacSecret === HIDDEN_HMAC_SECRET ? "password" : "text"}
          preventReveal
          readOnly
          trailingAccessory={
            hmacSecret !== HIDDEN_HMAC_SECRET && (
              <IconButton
                className="-mr-1.5"
                icon={Copy01}
                accessibilityLabel="Copy secret"
                onClick={() => {
                  navigator.clipboard.writeText(hmacSecret);
                  toast("Copied HMAC secret to clipboard");
                }}
              />
            )
          }
        />

        <AlertDialog
          trigger={
            <Button
              size="lg"
              type="button"
              variant="secondary"
              busy={createMutation.isPending}
              disabled={createMutation.isPending}
            >
              Regenerate
            </Button>
          }
          title="Regenerate HMAC secret"
          description="You're about to regenerate the HMAC secret. You will need to restart your application for the change to take effect."
          action="Regenerate"
          variant="primary"
          onSubmit={() => {
            createMutation.mutate(
              {
                applicationSlug: application.slug,
                organizationSlug: application.organization_slug,
              },
              {
                onSuccess(data) {
                  methods.setValue("hmac_secret", data.hmac_secret);
                },
              },
            );
          }}
        />
      </div>

      <AlertDialog
        trigger={
          <Button type="button" danger disabled={createMutation.isPending}>
            Disable
          </Button>
        }
        title="Disable request signing"
        description="You are about to remove an extra layer of security. You will need to restart your application for the change to take effect."
        action="Disable"
        onSubmit={() => {
          destroyMutation.mutate(
            {
              applicationSlug: application.slug,
              organizationSlug: application.organization_slug,
            },
            {
              onSuccess() {
                methods.setValue("hmac_secret", undefined);
              },
            },
          );
        }}
      />
    </div>
  );
}
