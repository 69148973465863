import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Lock02 } from "@untitled-ui/icons-react";
import {
  createOrganizationsApplicationsPresignedPost,
  destroyApplicationMutationOptions,
  updateApplicationMutationOptions,
  useOrganizationsApplicationQuery,
} from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { Form } from "~/components/_forms/Form";
import { AlertDialog } from "~/components/AlertDialog";
import { AppIcon } from "~/components/AppIcon";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { FileField } from "~/components/FileField";
import { TextField } from "~/components/TextField";
import { Titlebar } from "~/components/Titlebar";
import { uploadFileMutationOptions } from "~/mutations";
import { Link, useNavigate, useParams } from "~/router";

const AppSettingsFormSchema = z.object({
  name: z.string().min(1),
  avatar_key: z.string().nullable().optional(),
  avatar_url: z.string().nullable(),
});

type AppSettingsFormSchema = z.infer<typeof AppSettingsFormSchema>;

function AppSettings() {
  const navigate = useNavigate();
  const params = useParams("/settings/o/:org/w/:app");
  const applicationQuery = useOrganizationsApplicationQuery({
    slug: params.app,
    organizationSlug: params.org,
  });
  const application = applicationQuery.data ?? null;

  const uploadFileMutation = useMutation(uploadFileMutationOptions());

  const defaultValues = useMemo(
    () => ({
      name: application?.name ?? "",
      avatar_key: undefined,
      avatar_url: application?.avatar_url ?? null,
    }),
    [application],
  );

  const methods = useForm<AppSettingsFormSchema>({
    resolver: zodResolver(AppSettingsFormSchema),
    defaultValues,
  });

  const updateApplicationMutation = useMutation(
    updateApplicationMutationOptions(),
  );
  const preventSubmit =
    uploadFileMutation.isPending ||
    !methods.formState.isValid ||
    !methods.formState.isDirty;

  const submit = methods.handleSubmit((data) => {
    if (preventSubmit || updateApplicationMutation.isPending) return;
    updateApplicationMutation.mutate({
      slug: params.app,
      organizationSlug: params.org,
      ...data,
    });
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  const destroyAppMutation = useMutation(destroyApplicationMutationOptions());

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="org">
            <Link className="p-1" to="/settings/o/:org/w/:app" params={params}>
              <span className="flex items-center gap-2 w-full">
                <AppIcon className="w-5 h-5" application={application} />
                <p className="truncate">{application?.name}</p>
                {application?.visibility === "private" && (
                  <Lock02 className={cn("w-3.5 h-3.5 text-icon")} />
                )}
              </span>
            </Link>
          </BreadcrumbItem>,
        ]}
      />
      <div className="p-4 pt-1 flex flex-col flex-1 gap-4 w-full max-w-2xl mx-auto">
        <h1 className="font-semibold text-2xl">
          {application?.name ?? (
            <span className="text-placeholder">Workspace</span>
          )}
        </h1>

        <Form onSubmit={submit} className="flex flex-col gap-4">
          <TextField
            name="name"
            label="Name"
            control={methods.control}
            placeholder="My Workspace"
          />

          <FileField
            label="Avatar"
            busy={uploadFileMutation.isPending}
            accept={["image"]}
            maxSizeMb={2}
            value={methods.watch("avatar_url")}
            onValueChange={(value) => {
              if (!value) {
                methods.setValue("avatar_key", null, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                methods.setValue("avatar_url", null, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              } else {
                uploadFileMutation.mutate(
                  {
                    file: value,
                    presign: () =>
                      createOrganizationsApplicationsPresignedPost({
                        organizationSlug: params.org,
                        applicationSlug: params.app,
                        mime_type: value.type,
                      }),
                  },
                  {
                    onSuccess: (key) => {
                      methods.setValue("avatar_key", key, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                      methods.setValue(
                        "avatar_url",
                        URL.createObjectURL(value),
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        },
                      );
                    },
                  },
                );
              }
            }}
          />

          <Button
            type="submit"
            disabled={preventSubmit}
            busy={updateApplicationMutation.isPending}
          >
            Save
          </Button>
        </Form>

        <section className="flex flex-col items-start gap-3 pt-8">
          <header className="flex flex-col gap-1">
            <h2 className="font-medium text-xl">Delete workspace</h2>
            <p className="text-secondary">
              Permanently delete this workspace and all of its data.
            </p>
          </header>

          {application && (
            <AlertDialog
              title={`Delete "${application.name || "workspace"}"`}
              description="By confirming, the workspace will be deleted. This action cannot be reversed."
              action="Delete this workspace"
              trigger={
                <Button
                  type="button"
                  danger
                  size="sm"
                  busy={destroyAppMutation.isPending}
                >
                  Delete this workspace
                </Button>
              }
              onSubmit={() => {
                destroyAppMutation.mutate(
                  {
                    organizationSlug: application.organization_slug,
                    slug: application.slug,
                  },
                  {
                    onError() {
                      toast.error(
                        `Failed to delete workspace "${application.name}"`,
                      );
                    },
                    onSuccess() {
                      navigate("/o/:org", {
                        params: {
                          org: application.organization_slug,
                        },
                        replace: true,
                      });
                    },
                  },
                );
              }}
            />
          )}
        </section>
      </div>
    </div>
  );
}

export default AppSettings;
