import { ReactNode, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import {
  createModelRecordMutationOptions,
  Model,
  ModelFieldManyReferenceView,
  ModelFieldOneReferenceView,
  ModelRecord,
} from "api-client";

import { Button } from "@baselayer/ui/components/Button";

import { useRecordForm } from "~/components/_forms/RecordForm/hook";
import { RecordFields } from "~/components/_records/RecordFields";
import { Dialog } from "~/components/Dialog";

interface NewRecordDialogContentProps {
  field: ModelFieldOneReferenceView | ModelFieldManyReferenceView;
  model: Model | null;
  record: ModelRecord | null;
  onSuccess(record: ModelRecord): void;
  onDismiss(): void;
}
function NewRecordDialogContent({
  field,
  model: modelHint,
  record,
  onSuccess,
  onDismiss,
}: NewRecordDialogContentProps) {
  const model = modelHint ?? field.models[0];
  const { methods, fields } = useRecordForm(model, null);

  // Set the inverse field to the record
  useEffect(() => {
    if (!record) return;

    const inverseField = fields.find((currentField) => {
      return (
        currentField.field_type === "reference" &&
        currentField.reference_type === "to_one" &&
        currentField.inverse_of?.id === field.id
      );
    });
    if (!inverseField) return;

    methods.setValue(`data.${inverseField.field_name}`, record);
  }, [record, methods, field, fields]);

  const createMutation = useMutation(createModelRecordMutationOptions());
  const canSubmit = methods.formState.isValid && !createMutation.isPending;
  const submit = methods.handleSubmit(async (data) => {
    if (!model) return;
    createMutation.mutate(
      {
        organizationSlug: model.organization_slug,
        modelId: model.id,
        record: data,
      },
      {
        onSuccess(record) {
          onSuccess(record);
          onDismiss();
        },
      },
    );
  });

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col justify-between flex-1 gap-8 p-4 pt-3"
        onSubmit={submit}
      >
        <RecordFields record={methods.watch()} parentField={field} />
        <Button busy={createMutation.isPending} disabled={!canSubmit}>
          Create
        </Button>
      </form>
    </FormProvider>
  );
}

export interface NewRecordDialogProps {
  open?: boolean;
  onOpenChange?(open: boolean): void;
  trigger?: ReactNode;
  field: ModelFieldOneReferenceView | ModelFieldManyReferenceView;
  model?: Model | null;
  record?: ModelRecord | null;
  onSuccess: (newRecord: ModelRecord) => void;
}

export function NewRecordDialog({
  open: openProp,
  onOpenChange: setOpenProp,
  trigger,
  field,
  model,
  record,
  onSuccess,
}: NewRecordDialogProps) {
  const [openState, setOpenState] = useState(false);
  const open = openProp ?? openState;
  const setOpen = setOpenProp ?? setOpenState;

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      trigger={trigger}
      title={`New ${model?.names.singular ?? field.names.humanized_singular}`}
    >
      <NewRecordDialogContent
        field={field}
        model={model ?? null}
        record={record ?? null}
        onDismiss={() => setOpen(false)}
        onSuccess={onSuccess}
      />
    </Dialog>
  );
}
