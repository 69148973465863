import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Edit01, Key02, Lock02 } from "@untitled-ui/icons-react";
import {
  Application,
  updateApplicationMutationOptions,
  useOrganizationsApplicationQuery,
} from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { syncApplicationAction } from "~/actions/application";
import { SyncStepper } from "~/components/_applications/SyncStepper";
import { Form } from "~/components/_forms/Form";
import { AppIcon } from "~/components/AppIcon";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { ActionButton } from "~/components/Button";
import { Dialog } from "~/components/Dialog";
import { TextField } from "~/components/TextField";
import { Titlebar } from "~/components/Titlebar";
import { useApplicationSyncRealtime } from "~/realtime/application-sync";
import { Link, useParams } from "~/router";

const AppSettingsFormSchema = z.object({
  connection_url: z.string(),
  api_key: z.string().optional(),
});

type AppSettingsFormSchema = z.infer<typeof AppSettingsFormSchema>;

interface AppSdkSettingsFormProps {
  application: Application | null;
  onSuccess?: () => void;
}

function AppSdkSettingsForm({
  application,
  onSuccess,
}: AppSdkSettingsFormProps) {
  const params = useParams("/settings/o/:org/w/:app");

  const updateApplicationMutation = useMutation(
    updateApplicationMutationOptions(),
  );

  const defaultValues = useMemo(
    () => ({
      connection_url: application?.connection_url ?? "",
    }),
    [application],
  );

  const methods = useForm<AppSettingsFormSchema>({
    resolver: zodResolver(AppSettingsFormSchema),
    defaultValues,
  });

  const preventSubmit =
    !methods.formState.isValid || !methods.formState.isDirty;

  const submit = methods.handleSubmit((data) => {
    if (preventSubmit || updateApplicationMutation.isPending) return;
    updateApplicationMutation.mutate(
      {
        slug: params.app,
        organizationSlug: params.org,
        ...data,
      },
      {
        onSuccess() {
          if (onSuccess) {
            onSuccess();
          }
        },
      },
    );
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  return (
    <Form onSubmit={submit} className="flex flex-col gap-4 p-4">
      <TextField
        control={methods.control}
        name="connection_url"
        label="URL"
        type="url"
        placeholder="https://api.example.com/baselayer"
      />
      <TextField control={methods.control} name="api_key" label="API key" />
      <Button
        type="submit"
        disabled={preventSubmit}
        busy={updateApplicationMutation.isPending}
      >
        Update
      </Button>
      <p className="text-secondary text-sm">
        Baselayer will automatically sync with the new SDK configuration and
        update models in this workspace after saving.
      </p>
    </Form>
  );
}

function AppSdkSettings() {
  const params = useParams("/settings/o/:org/w/:app");
  const applicationQuery = useOrganizationsApplicationQuery({
    slug: params.app,
    organizationSlug: params.org,
  });
  const application = applicationQuery.data ?? null;
  const [isSdkFormOpen, setIsSdkFormOpen] = useState(false);

  useApplicationSyncRealtime(application);

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="team">
            <Link className="p-1" to="/settings/o/:org/w/:app" params={params}>
              <span className="flex items-center gap-2 w-full">
                <AppIcon className="w-5 h-5" application={application} />
                <p className="truncate">{application?.name}</p>
                {application?.visibility === "private" && (
                  <Lock02 className={cn("w-3.5 h-3.5 text-icon")} />
                )}
              </span>
            </Link>
          </BreadcrumbItem>,
          <BreadcrumbItem key="sdk">
            <Link
              className="p-1"
              to="/settings/o/:org/w/:app/sdk"
              params={params}
            >
              SDK
            </Link>
          </BreadcrumbItem>,
        ]}
      />
      <div className="p-4 pt-1 flex flex-col flex-1 gap-4 w-full max-w-2xl mx-auto">
        <h1 className="font-semibold text-2xl">SDK</h1>

        <div>
          <h2 className="font-medium">Configuration</h2>
          <p className="text-secondary text-sm">
            Connect to the Baselayer SDK in your app
          </p>
        </div>
        <div className="border border-primary rounded-lg p-3 bg-panel shadow w-full flex gap-x-12 gap-y-4 flex-wrap justify-between">
          <dl className="flex flex-col gap-4 overflow-hidden">
            <div>
              <dt className="text-sm text-secondary mb-1">URL</dt>
              <dd className="break-words">{application?.connection_url}</dd>
            </div>
            <div>
              <dt className="text-sm text-secondary mb-1">API Key</dt>
              <div className="flex items-center gap-1.5">
                <Key02 className="w-4 h-4 text-icon" aria-hidden />
                <dd>•••••••••••••••</dd>
              </div>
            </div>
          </dl>
          <Dialog
            open={isSdkFormOpen}
            onOpenChange={setIsSdkFormOpen}
            title="SDK configuration"
            trigger={
              <Button variant="secondary" icon={Edit01} size="sm">
                Edit
              </Button>
            }
          >
            <AppSdkSettingsForm
              application={application}
              onSuccess={() => {
                setIsSdkFormOpen(false);
              }}
            />
          </Dialog>
        </div>

        {application?.latest_sync && (
          <>
            <h2 className="font-medium mt-3">Latest sync</h2>
            <section className="flex flex-col p-4 gap-3 bg-panel rounded-xl border border-primary shadow">
              <SyncStepper sync={application.latest_sync} />
            </section>

            <ActionButton
              action={syncApplicationAction}
              target={application}
              variant="secondary"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default AppSdkSettings;
