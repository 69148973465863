import { useCallback } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  infiniteAppend,
  infiniteSome,
  organizationsModelsRecordsEventsInfiniteQueryOptions,
  queryClient,
  useInfiniteData,
} from "api-client";

import { ModelRecordActionCell } from "~/components/_records/RecordActivity/ModelRecordActionCell";
import { useCurrentRecord } from "~/lib/current";
import { useEventStream } from "~/realtime/event-stream";
import { useParams } from "~/router";

export function RecordActivity() {
  const params = useParams("/o/:org/w/:app/m/:model/r/:record");
  const record = useCurrentRecord();

  const eventsQueryOptions =
    organizationsModelsRecordsEventsInfiniteQueryOptions({
      organizationSlug: params.org,
      modelId: params.model,
      recordId: params.record,
    });
  const eventsQuery = useInfiniteQuery({
    ...eventsQueryOptions,
    refetchOnWindowFocus: "always",
    refetchOnMount: "always",
    enabled: !!record,
  });
  const events = useInfiniteData(eventsQuery.data);

  useEventStream(
    record,
    useCallback(
      (event) => {
        queryClient.setQueryData(eventsQueryOptions.queryKey, (cache) => {
          if (!cache) return;
          if (infiniteSome(cache, (e) => e.id === event.id)) return;
          return infiniteAppend(cache, event);
        });
      },
      [eventsQueryOptions.queryKey],
    ),
  );

  return (
    <section className="pl-4 pr-3 py-3.5 flex flex-col gap-3.5">
      <h2 className="text-sm font-medium">Activity</h2>

      <ol className="flex flex-col gap-4">
        {events.map((event) => {
          switch (event.eventable.type) {
            case "ModelRecordAction":
              return <ModelRecordActionCell key={event.id} event={event} />;
            default:
              return null;
          }
        })}
      </ol>
    </section>
  );
}
