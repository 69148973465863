import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";

import { ErrorMessage } from "~/components/ErrorMessage";
import { TextField } from "~/components/TextField";
import { withAuth } from "~/lib/auth";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useResetPasswordMutation } from "~/mutations";
import NotFound from "~/pages/404";
import { Link } from "~/router";
import { Logo } from "~/svgs/Logo";

const ResetPasswordSchema = z
  .object({
    password: z.string().min(10, "Password must be 10 or more characters long"),
    confirmPassword: z
      .string()
      .min(10, "Password must be 10 or more characters long"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Passwords do not match",
  });

type ResetPasswordForm = z.infer<typeof ResetPasswordSchema>;

function ResetPassword() {
  const {
    formState: { errors },
    setError,
    handleSubmit,
    control,
  } = useForm<ResetPasswordForm>({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [showPasswordHasBeenReset, setShowPasswordHasBeenReset] =
    useState(false);
  const resetPassword = useResetPasswordMutation();
  const token = new URLSearchParams(window.location.search).get("token");

  if (!token) {
    return <NotFound />;
  }

  const onSubmit = handleSubmit(async ({ password }: ResetPasswordForm) => {
    resetPassword.mutate(
      { password, token },
      {
        onSuccess() {
          setShowPasswordHasBeenReset(true);
        },
        onError: (error) => handleFormErrors(error, setError),
      },
    );
  });

  return (
    <div className="p-4 py-12 gap-4 flex-1 flex flex-col items-center justify-center">
      <div className="flex flex-col gap-1 items-center text-center w-full max-w-lg">
        <div className="w-8 aspect-square text-icon-subtle mb-2.5">
          <Logo />
        </div>
        <h1 className="text-xl font-semibold text-primary">Reset password</h1>
        <p className="text-placeholder">
          Something not working?{" "}
          <Link
            className="text-secondary hover:text-primary transition-colors"
            to="/forgot-password"
          >
            Request a new link
          </Link>
        </p>
      </div>

      <div className="flex flex-col gap-6 w-full max-w-lg mt-4">
        {showPasswordHasBeenReset ? (
          <p className="text-secondary text-center">
            Password has successfully been reset! You can now{" "}
            <Link
              className="text-secondary underline hover:text-primary transition-colors"
              to="/signin"
            >
              sign in
            </Link>{" "}
            with your new password.
          </p>
        ) : (
          <Fragment>
            <form className="flex flex-col gap-3" onSubmit={onSubmit}>
              <TextField
                name="password"
                type="password"
                label="Password"
                placeholder="•••••••••••••••"
                control={control}
              />
              <TextField
                name="confirmPassword"
                type="password"
                label="Confirm password"
                placeholder="•••••••••••••••"
                control={control}
              />

              <div className="flex flex-col">
                <Button size="lg">Reset Password</Button>
              </div>
            </form>

            {errors.root && <ErrorMessage label="Form" error={errors.root} />}
          </Fragment>
        )}
      </div>
    </div>
  );
}

const ProtectedResetPassword = withAuth(ResetPassword, { type: "public" });

export default ProtectedResetPassword;
