import { Fragment, useState } from "react";
import { Plus } from "@untitled-ui/icons-react";
import {
  infinitePrepend,
  Model,
  ModelFieldOneReferenceView,
  ModelRecord,
  organizationsModelsFieldsRecordsInfiniteQueryOptions,
  queryClient,
  Where,
} from "api-client";
import { useDebounce } from "use-debounce";

import { Button } from "@baselayer/ui/components/Button";

import { NewRecordDialog } from "~/components/_records/NewRecordDialog";
import { RecordTable } from "~/components/_records/RecordTable";
import { Dialog } from "~/components/Dialog";
import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Wrap } from "~/components/Wrap";

export interface RecordSelectorDrawerProps {
  field: ModelFieldOneReferenceView;
  onValueChange(record: ModelRecord | null): void;
  initialOpen?: boolean;
  onDismiss?(): void;
}

export function RecordSelectorDrawer({
  field,
  initialOpen = false,
  onValueChange,
  onDismiss,
}: RecordSelectorDrawerProps) {
  const [open, setOpen] = useState(initialOpen);

  const [where, setWhere] = useState<Where>({});
  const [debouncedWhere] = useDebounce(where, 300, { leading: true });

  const [currentModel, setCurrentModel] = useState<Model | null>(null);

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      onDismiss={onDismiss}
      title={`Choose ${field.names.humanized}`}
    >
      <div className="relative flex-1 flex flex-col py-3">
        <RecordTable
          inDialog
          models={field.models}
          where={where}
          onWhereChange={setWhere}
          queryOptions={{
            ...organizationsModelsFieldsRecordsInfiniteQueryOptions({
              organizationSlug: field.organization_slug,
              modelId: field.model_id,
              fieldName: field.field_name,
              where: debouncedWhere,
            }),
            enabled: !!field.organization_slug && !!field.model_id,
          }}
          renderTitleCell={({ record, className, children }) => (
            <button
              type="button"
              className={className}
              onClick={() => {
                onValueChange(record);
                setOpen(false);
              }}
            >
              {children}
            </button>
          )}
          actions={
            <Fragment>
              <Wrap
                condition={field.models.length > 1}
                wrap={(children) => (
                  <DropdownMenu align="end" trigger={children} title="Actions">
                    {field.models.map((model) => (
                      <DropdownItem
                        key={model.id}
                        onSelect={() => setCurrentModel(model)}
                      >
                        {model.names.camel_singular}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                )}
              >
                <Button
                  icon={Plus}
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    if (field.models.length === 1) {
                      setCurrentModel(field.models[0]);
                    }
                  }}
                >
                  New
                  {field.models.length > 1
                    ? "..."
                    : ` ${field.models[0].names.singular}`}
                </Button>
              </Wrap>

              <NewRecordDialog
                open={!!currentModel}
                onOpenChange={() => setCurrentModel(null)}
                field={field}
                model={currentModel}
                onSuccess={(newRecord) => {
                  const query =
                    organizationsModelsFieldsRecordsInfiniteQueryOptions({
                      organizationSlug: field.organization_slug,
                      modelId: field.model_id,
                      fieldName: field.field_name,
                    });
                  queryClient.setQueryData(query.queryKey, (cache) => {
                    if (!cache) return cache;
                    return infinitePrepend(cache, newRecord);
                  });
                  queryClient.invalidateQueries(query);

                  setCurrentModel(null);
                }}
              />
            </Fragment>
          }
        />
      </div>
    </Dialog>
  );
}
