import { useMemo } from "react";
import { FeatureFlag } from "api-client";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { useCurrentUser } from "~/lib/current";
import { useParams } from "~/router";

export interface LocalFlagState {
  flags: Partial<Record<FeatureFlag["name"], boolean>>;
  set: (name: FeatureFlag["name"], value: boolean) => void;
  delete: (name: FeatureFlag["name"]) => void;
}

export const useLocalFlagStore = create<LocalFlagState>()(
  persist(
    (set, get) => ({
      flags: {},
      set: (name: FeatureFlag["name"], value: boolean) => {
        const flags = get().flags;
        set({ flags: { ...flags, [name]: value } });
      },
      delete: (name: FeatureFlag["name"]) => {
        const flags = get().flags;
        delete flags[name];
        set({ flags: { ...flags } });
      },
    }),
    {
      name: "local-flags",
      storage: createJSONStorage(() => window.sessionStorage),
    },
  ),
);

export function useCurrentFlags(org?: string): Set<FeatureFlag["name"]> {
  const localFlags = useLocalFlagStore((s) => s.flags);
  const user = useCurrentUser();
  const params = useParams("/o/:org");
  const orgSlug = org ?? params.org;

  return useMemo(() => {
    const userFlags = user?.feature_flags ?? [];
    const membership = user?.organization_memberships.find(
      (m) => m.organization.slug === orgSlug,
    );
    const orgFlags = membership?.feature_flags ?? [];

    const set = new Set([
      ...userFlags.map((f) => f.name),
      ...orgFlags.map((f) => f.name),
    ]);

    Object.entries(localFlags).forEach(([name, value]) => {
      if (value) {
        set.add(name as FeatureFlag["name"]);
      } else {
        set.delete(name as FeatureFlag["name"]);
      }
    });

    return set;
  }, [localFlags, user, orgSlug]);
}

export interface FlagEnabledOptions {
  org?: string;
}
export function useFlagEnabled(
  name: FeatureFlag["name"],
  options?: FlagEnabledOptions,
) {
  const flags = useCurrentFlags(options?.org);
  return flags.has(name);
}
