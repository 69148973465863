import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueries } from "@tanstack/react-query";
import { FilterLines, Plus } from "@untitled-ui/icons-react";
import {
  Filter,
  Model,
  ModelField,
  organizationsModelsFieldsQueryOptions,
  Where,
} from "api-client";
import { format, parseISO } from "date-fns";
import equal from "fast-deep-equal";
import uniqBy from "lodash.uniqby";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { BooleanFieldFilter } from "~/components/_records/RecordTable/Filters/BooleanFieldFilter";
import { DateFieldFilter } from "~/components/_records/RecordTable/Filters/DateFieldFilter";
import { ManyReferenceFieldFilter } from "~/components/_records/RecordTable/Filters/ManyReferenceFieldFilter";
import { NumberFieldFilter } from "~/components/_records/RecordTable/Filters/NumberFieldFilter";
import { StringFieldFilter } from "~/components/_records/RecordTable/Filters/StringFieldFilter";
import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { Popover } from "~/components/Popover";
import { UnionKeys } from "~/types";

interface FieldFilterProps {
  models: Model[];
  field: ModelField;
  value: Filter<string>;
  onValueChange: (value: Filter<string>) => void;
  onRemove: () => void;
}

function FieldFilter({
  models,
  field,
  value,
  onValueChange,
  onRemove,
}: FieldFilterProps) {
  const operator = Object.keys(value)[0] as UnionKeys<typeof value>;
  const rawValue = Object.values(value)[0] as any;

  function onChange<T>(filter: Filter<any>, stringify: (value: T) => string) {
    const operator = Object.keys(filter)[0] as UnionKeys<typeof filter>;
    const value = Object.values(filter)[0];
    onValueChange({
      [operator]: value === null ? null : stringify(value),
    } as Filter<string>);
  }

  if (field.field_type === "string") {
    const string = rawValue === null ? null : rawValue;
    return (
      <StringFieldFilter
        field={field}
        value={{ [operator]: string } as Filter<string>}
        onValueChange={(value) => {
          onChange<string>(value, (value) => value.toString());
        }}
        onRemove={onRemove}
      />
    );
  }

  if (field.field_type === "number") {
    const number = rawValue === null ? null : parseFloat(rawValue);
    return (
      <NumberFieldFilter
        field={field}
        value={{ [operator]: number } as Filter<number>}
        onValueChange={(value) => {
          onChange<number>(value, (value) => value.toString());
        }}
        onRemove={onRemove}
      />
    );
  }

  if (field.field_type === "boolean") {
    const boolean =
      rawValue === null ? null : rawValue === "true" || rawValue === true;
    return (
      <BooleanFieldFilter
        models={models}
        field={field}
        value={{ [operator]: boolean } as Filter<boolean>}
        onValueChange={(value) => {
          onChange<boolean>(value, (value) => value.toString());
        }}
        onRemove={onRemove}
      />
    );
  }

  if (field.field_type === "date") {
    const date = rawValue === null ? null : parseISO(rawValue);
    return (
      <DateFieldFilter
        field={field}
        value={{ [operator]: date } as Filter<Date>}
        onValueChange={(value) => {
          onChange<Date>(value, (value) =>
            field.specificity === "date"
              ? format(value, "yyyy-MM-dd")
              : value.toISOString(),
          );
        }}
        onRemove={onRemove}
      />
    );
  }

  if (
    field.field_type === "reference" &&
    field.reference_type === "to_many" &&
    field.count_field_name
  ) {
    const count = rawValue === null ? null : parseFloat(rawValue);
    return (
      <ManyReferenceFieldFilter
        field={field}
        value={{ [operator]: count } as Filter<number>}
        onValueChange={(value) => {
          onChange<number>(value, (value) => value.toString());
        }}
        onRemove={onRemove}
      />
    );
  }

  return null;
}

export interface RecordFiltersProps {
  models: Model[];
  where?: Where;
  onWhereChange?(where: Where): void;
}

export function RecordFilters({
  models,
  where: propsWhere,
  onWhereChange,
}: RecordFiltersProps) {
  const [stateWhere, setStateWhere] = useState<Record<string, any>>({});
  const where = propsWhere ?? stateWhere;
  const setWhere = onWhereChange ?? setStateWhere;

  const fieldsQueries = useQueries({
    queries: models.map((model) =>
      organizationsModelsFieldsQueryOptions({
        modelId: model.id,
        organizationSlug: model.organization_slug,
      }),
    ),
  });
  const isPending = useMemo(() => {
    return fieldsQueries.some((query) => query.isPending);
  }, [fieldsQueries]);

  const allFields = useMemo(() => {
    return uniqBy(
      fieldsQueries.map((query) => query.data ?? []).flat(),
      (f) => f.field_name,
    );
  }, [fieldsQueries]);
  const visibleFields = useMemo(() => {
    return allFields.filter(
      (field) => field.filterable && !(field.field_name in where),
    );
  }, [allFields, where]);

  const applicableWhere = useMemo(() => {
    if (isPending) return where;

    const fieldSet = new Set(allFields.map((field) => field.field_name));
    const newWhere = { ...where };
    Object.keys(where).forEach((key) => {
      if (!fieldSet.has(key)) {
        delete newWhere[key];
      }
    });
    return newWhere;
  }, [isPending, allFields, where]);

  const whereFields = useMemo(() => {
    const fieldMap = new Map(
      allFields.map((field) => [field.field_name, field]),
    );
    return Object.keys(applicableWhere)
      .map((key) => fieldMap.get(key))
      .filter(Boolean) as ModelField[];
  }, [applicableWhere, allFields]);

  // Remove any fields that are not in the applicable anymore
  useEffect(() => {
    if (!equal(where, applicableWhere)) {
      setWhere(applicableWhere);
    }
  }, [where, applicableWhere, setWhere]);

  const onSelectField = useCallback(
    (field: ModelField) => {
      let defaultValue: Filter<any> | undefined;

      if (field.field_type === "date") {
        if (field.specificity === "date") {
          defaultValue = {
            lte: format(new Date(), "yyyy-MM-dd"),
          };
        } else if (field.specificity === "datetime") {
          defaultValue = { lte: new Date().toISOString() };
        }
      } else if (field.field_type === "boolean") {
        defaultValue = { eq: true };
      } else if (field.field_type === "number") {
        defaultValue = { gte: field.min ?? 1 };
      } else if (field.field_type === "string") {
        if (field.enum_options.length > 0) {
          defaultValue = { eq: field.enum_options[0].value };
        } else {
          defaultValue = { like: "" };
        }
      } else if (
        field.field_type === "reference" &&
        field.reference_type === "to_many" &&
        field.count_field_name
      ) {
        defaultValue = { gte: 1 };
      }

      if (defaultValue) {
        setWhere({
          ...where,
          [field.field_name]: defaultValue,
        });
      }
    },
    [where, setWhere],
  );

  const [open, setOpen] = useState(false);

  return (
    <Popover
      dark={false}
      modal
      alignOffset={-1.5}
      align="start"
      side="bottom"
      title="Filter"
      open={open}
      onOpenChange={setOpen}
      trigger={
        <Button
          size="sm"
          variant="secondary"
          type="button"
          icon={FilterLines}
          disabled={visibleFields.length === 0}
        >
          Filter
          {whereFields.length > 0 && (
            <span
              aria-hidden="true"
              className={cn(
                "ml-2 px-[5px] min-w-4 h-4 flex items-center justify-center",
                "bg-subtle text-2xs text-secondary font-semibold rounded",
              )}
            >
              {whereFields.length}
            </span>
          )}
        </Button>
      }
    >
      <div className="w-full sm:w-[38rem] flex flex-col items-start">
        {whereFields.length > 0 ? (
          <div className="p-2.5 py-3 sm:grid sm:grid-cols-[auto_1fr_2fr_auto] gap-x-1.5 gap-y-2.5 w-full">
            {whereFields.map((field) => (
              <FieldFilter
                key={field.id}
                models={models}
                field={field}
                value={where[field.field_name]}
                onValueChange={(value) => {
                  const newWhere = { ...where };
                  newWhere[field.field_name] = value;
                  setWhere(newWhere);
                }}
                onRemove={() => {
                  const newWhere = { ...where };
                  delete newWhere[field.field_name];
                  setWhere(newWhere);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="w-full px-4 min-h-15 flex items-center justify-center">
            <p className="text-sm text-placeholder">No filters applied</p>
          </div>
        )}

        <div className="w-full p-2 sm:m-px flex justify-between items-center gap-3 border-t border-primary max-sm:sticky max-sm:bottom-0 max-sm:bg-main">
          <DropdownMenu
            modal
            trigger={
              <Button
                icon={Plus}
                size="xs"
                variant="subtle"
                type="button"
                disabled={visibleFields.length === 0}
              >
                Add filter
              </Button>
            }
            align="start"
            className="w-56"
            title="Add filter"
          >
            {visibleFields.map((field) => (
              <DropdownItem
                key={field.id}
                icon={(p) => (
                  <Icon
                    {...p}
                    aria-hidden
                    name={field.icon}
                    fallback={<div className="w-4 h-4 rounded bg-avatar" />}
                  />
                )}
                onSelect={() => onSelectField(field)}
              >
                {field.name}
              </DropdownItem>
            ))}
          </DropdownMenu>

          <div className="flex items-center gap-2">
            {whereFields.length > 0 && (
              <div className="flex items-center gap-1.5">
                <Button
                  size="xs"
                  variant="subtle"
                  type="button"
                  onClick={() => {
                    setWhere({});
                    setOpen(false);
                  }}
                >
                  Clear
                </Button>
              </div>
            )}

            <div className="flex items-center gap-1.5 sm:hidden">
              <Button
                size="xs"
                type="button"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
}
