import { useCallback, useEffect, useMemo } from "react";
import {
  DataTag,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Model,
  ModelField,
  ModelRecord,
  organizationsModelsRecordQueryOptions,
  PAGE_SIZE,
  Pagination,
  useInfiniteCurrentPage,
  useInfiniteQueries,
  useInfiniteTotalCount,
  useOrganizationsModelsFieldsQuery,
} from "api-client";

import {
  FieldPreview,
  FieldPreviewSkeleton,
} from "~/components/_fields/FieldPreview";
import { RecordPreviewLockup } from "~/components/_forms/RecordPreview";
import { Icon } from "~/components/Icon";

const columnHelper = createColumnHelper<ModelRecord>();
type FieldName = Parameters<typeof columnHelper.accessor>[0];
const PLACEHOLDER_COLUMN: ModelField = {
  type: "ModelField",
  id: `__placeholder`,
  name: `Placeholder`,
  names: {
    humanized: `placeholder`,
    humanized_singular: `placeholder`,
    humanized_plural: `placeholders`,
    camelized: `placeholder`,
    camelized_singular: `placeholder`,
    camelized_plural: `placeholders`,
    titleized: `Placeholder`,
    titleized_singular: `Placeholder`,
    titleized_plural: `Placeholders`,
  },
  icon: null,
  hidden: true,
  field_name: ``,
  read_only: true,
  nullable: false,
  show_on_index: true,
  filterable: false,
  field_type: "string",
  default: null,
  enum_options: [],
  min_length: null,
  max_length: null,
  model_id: "",
  organization_slug: "",
};
const PLACEHOLDER_PREVIEW: ModelField = {
  ...PLACEHOLDER_COLUMN,
  field_type: "reference",
  reference_type: "to_one",
  models: [] as any,
  default: null,
  inverse_of: null,
};
const PLACEHOLDER_RECORD = (i: number): ModelRecord => ({
  type: "ModelRecord",
  model_id: "optimistic",
  model_name: "optimistic",
  application_slug: "optimistic",
  organization_slug: "optimistic",
  id: `placeholder_${i}`,
  title: null,
  thumbnail: null,
  created_at: null,
  updated_at: null,
  data: {},
  viewer_favorited_at: null,
  pusher_channel: "private-optimistic",
  pusher_presence_channel: "presence-optimistic",
});

export interface UseRecordTableParams {
  pendingCount?: number;
  record?: ModelRecord | null;
  models: Model[];
  queryOptions: UseInfiniteQueryOptions<
    Pagination<ModelRecord>,
    Error,
    InfiniteData<Pagination<ModelRecord>>,
    Pagination<ModelRecord>,
    QueryKey,
    number | undefined
  > & {
    queryKey: DataTag<QueryKey, InfiniteData<Pagination<ModelRecord>>>;
    enabled?: boolean;
  };
  pageParam: number;
  onPageParamChange(pageParam: number | ((current: number) => number)): void;
}

export function useRecordTable({
  pendingCount = 25,
  record,
  models,
  queryOptions,
  pageParam,
  onPageParamChange,
}: UseRecordTableParams) {
  const firstModel = models.at(0) ?? null;
  const fieldsQueryEnabled = models.length === 1;
  const firstModelFieldsQuery = useOrganizationsModelsFieldsQuery(
    {
      organizationSlug: firstModel?.organization_slug ?? "",
      modelId: firstModel?.id ?? "",
    },
    { enabled: fieldsQueryEnabled },
  );
  const fields = useMemo(
    () => firstModelFieldsQuery.data ?? [],
    [firstModelFieldsQuery.data],
  );

  const renderedFields = useMemo<ModelField[]>(() => {
    if (firstModelFieldsQuery.isPending && fieldsQueryEnabled) {
      return [PLACEHOLDER_COLUMN];
    } else if (models.length === 1) {
      return fields.filter((field) => {
        if (!field.show_on_index || field.hidden) {
          return false;
        }
        if (
          record &&
          field.field_type === "reference" &&
          field.reference_type === "to_one" &&
          field.inverse_of
        ) {
          return field.inverse_of.model_id !== record?.model_id;
        }
        return true;
      });
    } else {
      return [];
    }
  }, [
    fields,
    models,
    fieldsQueryEnabled,
    firstModelFieldsQuery.isPending,
    record,
  ]);

  const pagination = useMemo(
    () => ({ pageIndex: pageParam - 1, pageSize: PAGE_SIZE }),
    [pageParam],
  );
  useEffect(() => {
    if (onPageParamChange) {
      onPageParamChange(pageParam);
    }
  }, [pageParam, onPageParamChange]);

  const recordsQuery = useInfiniteQuery({
    ...queryOptions,
    initialPageParam: pageParam,
    getNextPageParam: (_, pages) => {
      if (pages.some((page) => page.meta.current_page === pageParam)) {
        return null;
      }
      return pageParam;
    },
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
  });
  useEffect(() => {
    if (!recordsQuery.isFetchingNextPage && recordsQuery.hasNextPage) {
      recordsQuery.fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsQuery.isFetchingNextPage, recordsQuery.hasNextPage]);

  const rowCount = useInfiniteTotalCount(recordsQuery.data);
  const currentPage = useInfiniteCurrentPage(
    recordsQuery.data,
    pagination.pageIndex + 1,
  );
  const records = useInfiniteQueries(currentPage?.data ?? [], (record) =>
    organizationsModelsRecordQueryOptions({
      id: record.id,
      modelId: record.model_id,
      organizationSlug: record.organization_slug,
    }),
  );

  const isFieldsPending = useMemo(
    () => firstModelFieldsQuery.isPending && fieldsQueryEnabled,
    [firstModelFieldsQuery.isPending, fieldsQueryEnabled],
  );
  const isPending = useMemo(
    () =>
      queryOptions.enabled &&
      (isFieldsPending ||
        recordsQuery.isPending ||
        recordsQuery.isFetchingNextPage),
    [
      recordsQuery.isPending,
      recordsQuery.isFetchingNextPage,
      isFieldsPending,
      queryOptions.enabled,
    ],
  );

  const data = useMemo(() => {
    if (!queryOptions.enabled) {
      return [];
    } else if (isPending) {
      let count = pendingCount;
      if (rowCount) {
        const start = pagination.pageIndex * pagination.pageSize + 1;
        const end = start + records.length - 1;
        count = end - start + 1;
      }
      return Array.from({ length: count }, (_, i) => PLACEHOLDER_RECORD(i));
    } else {
      return records;
    }
  }, [
    queryOptions.enabled,
    records,
    isPending,
    pagination,
    pendingCount,
    rowCount,
  ]);

  const getRowId = useCallback((record: ModelRecord) => record.id, []);

  const columns = useMemo(() => {
    const fieldColumns = renderedFields.map((field) => {
      let fieldName: FieldName = `data.${field.field_name}`;
      if (!field.field_name) {
        fieldName = "id";
      } else if (
        field.field_type === "reference" &&
        field.reference_type === "to_many" &&
        field.count_field_name
      ) {
        fieldName = `data.${field.count_field_name}`;
      }

      return columnHelper.accessor(fieldName, {
        id: field.id,
        header: () =>
          isFieldsPending ? (
            <div className="w-full h-4 my-0.5 rounded-md bg-subtle" />
          ) : (
            <div className="flex items-center gap-2 w-full">
              <Icon
                className="w-4 h-4 text-icon shrink-0"
                name={field.icon}
                fallback={
                  <div className="w-4 h-4 rounded bg-avatar shrink-0" />
                }
              />
              <p className="truncate w-full">{field.name}</p>
            </div>
          ),
        cell: (info) =>
          isPending ? (
            <FieldPreviewSkeleton field={field} />
          ) : (
            <FieldPreview field={field} value={info.getValue()} />
          ),
      });
    });

    if (isFieldsPending) {
      return fieldColumns;
    } else {
      const primaryKeyField = fields.find(
        (field) => field.id === firstModel?.primary_key_field_id,
      );
      const titleField = fields.find(
        (field) => field.id === firstModel?.title_field_id,
      );
      const modelOrField = titleField ?? primaryKeyField ?? firstModel;
      return [
        columnHelper.accessor("id", {
          id: "__self",
          header: () => (
            <div className="flex items-center gap-2 w-full">
              <Icon
                className="w-4 h-4 text-icon shrink-0"
                name={modelOrField?.icon}
                fallback={
                  <div className="w-4 h-4 rounded bg-avatar shrink-0" />
                }
              />
              <p className="truncate w-full">{modelOrField?.name}</p>
            </div>
          ),
          cell: (info) =>
            isPending ? (
              <FieldPreviewSkeleton field={PLACEHOLDER_PREVIEW} />
            ) : (
              <RecordPreviewLockup
                model={
                  models.find((m) => m.id === info.row.original.model_id) ??
                  null
                }
                record={info.row.original}
              />
            ),
        }),
        ...fieldColumns,
      ];
    }
  }, [renderedFields, fields, firstModel, models, isFieldsPending, isPending]);

  const table = useReactTable({
    data,
    getRowId,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // Pagination
    manualPagination: true,
    onPaginationChange: (pagination) => {
      if (typeof pagination === "function") {
        onPageParamChange(
          (value) =>
            pagination({ pageIndex: value - 1, pageSize: PAGE_SIZE })
              .pageIndex + 1,
        );
      } else {
        onPageParamChange(pagination.pageIndex + 1);
      }
    },
    rowCount,
    state: {
      pagination,
    },
  });

  return useMemo(() => [table, isPending] as const, [table, isPending]);
}
