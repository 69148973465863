import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateInviteMutation } from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";

import { Select } from "~/components/Select";
import { TextField } from "~/components/TextField";
import { handleFormErrors } from "~/lib/handleFormErrors";

const InviteFormSchema = z.object({
  recipient_email: z.string().email(),
  role: z.enum(["member"]),
});

type InviteFormSchema = z.infer<typeof InviteFormSchema>;

export interface InviteFormProps {
  org: string;
  onSuccess?: () => void;
}

export function InviteForm({ org, onSuccess }: InviteFormProps) {
  const invite = useCreateInviteMutation();

  const methods = useForm<InviteFormSchema>({
    resolver: zodResolver(InviteFormSchema),
    defaultValues: {
      recipient_email: "",
      role: "member",
    },
  });

  const submit = methods.handleSubmit((data) => {
    invite.mutate(
      {
        recipient_email: data.recipient_email,
        organizationSlug: org,
      },
      {
        onError(error) {
          handleFormErrors(error, methods.setError);
        },
        onSuccess() {
          methods.reset();

          if (onSuccess) {
            onSuccess();
          }
        },
        onSettled() {
          methods.setFocus("recipient_email");
        },
      },
    );
  });

  return (
    <form onSubmit={submit} className="flex flex-col gap-4">
      <TextField
        name="recipient_email"
        label="Email"
        control={methods.control}
        type="email"
        placeholder="josh@acme.com"
        errorMessages={{
          taken: "This email has already been invited.",
        }}
      />
      <Select
        name="role"
        options={["member"]}
        value="member"
        onValueChange={() => {}}
        label="Role"
        readOnly
      />
      <div className="flex flex-col mt-2">
        <Button
          size="lg"
          type="submit"
          disabled={!methods.formState.isValid}
          busy={invite.isPending}
        >
          Invite
        </Button>
      </div>
    </form>
  );
}
