import { ForwardedRef, forwardRef, HTMLProps, ReactNode } from "react";
import { Slot as RadixSlot } from "@radix-ui/react-slot";

export interface SlotProps<E extends HTMLElement>
  extends Omit<HTMLProps<E>, "ref"> {
  children?: ReactNode;
}

export const Slot = forwardRef(function Slot<E extends HTMLElement>(
  { children, ...props }: SlotProps<E>,
  ref: ForwardedRef<E>,
) {
  return (
    <RadixSlot ref={ref} {...props}>
      {children}
    </RadixSlot>
  );
}) as <E extends HTMLElement>(
  props: SlotProps<E> & { ref?: ForwardedRef<E> },
) => JSX.Element;
