import { Fragment, useRef } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { ChevronRight, DotsHorizontal } from "@untitled-ui/icons-react";
import { ModelFieldOneReferenceView } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import {
  changeOneReferenceFieldAction,
  removeOneReferenceFieldAction,
} from "~/actions";
import { FieldLabel } from "~/components/_fields/FieldLabel";
import { RecordCard } from "~/components/_records/RecordCard";
import { ActionMenu } from "~/components/ActionMenu";
import { ActionIconButton, IconButton } from "~/components/IconButton";
import { useAction } from "~/lib/actions";

export interface OneReferenceFieldProps<T extends FieldValues> {
  field: ModelFieldOneReferenceView;
  name: Path<T>;
  control: Control<T>;
  readOnly?: boolean;
}

export function OneReferenceField<T extends FieldValues>({
  field: config,
  name,
  control,
  readOnly,
}: OneReferenceFieldProps<T>) {
  const { field } = useController<T>({
    name,
    control,
  });

  const triggerRef = useRef<HTMLButtonElement>(null);
  const [node, execute] = useAction<[typeof changeOneReferenceFieldAction]>(
    config,
    {
      record: field.value,
      onChangeRecord: field.onChange,
    },
    {
      onCleanup() {
        triggerRef.current?.focus();
      },
    },
  );

  return (
    <div className="flex flex-col gap-2 group">
      <div className="flex items-center justify-between gap-6 min-h-6">
        <FieldLabel htmlFor={config.id} readOnly={config.read_only}>
          {config.name}
        </FieldLabel>
      </div>

      {node}

      {!field.value ? (
        <button
          ref={triggerRef}
          type="button"
          className={cn(
            "w-full px-3.5 py-3 bg-action border border-action rounded-xl flex items-center justify-between gap-3",
            "hover:bg-action-active",
          )}
          onClick={() => execute(changeOneReferenceFieldAction)}
        >
          <span className="text-placeholder">Choose one</span>
          <ChevronRight className="w-4 h-4 text-icon" />
        </button>
      ) : (
        <ActionMenu
          as="contextmenu"
          target={config}
          context={{
            record: field.value,
            onChangeRecord: field.onChange,
            onRemoveRecord() {
              field.onChange(null);
            },
          }}
          actions={
            readOnly
              ? []
              : [changeOneReferenceFieldAction, removeOneReferenceFieldAction]
          }
          trigger={
            <RecordCard
              field={config}
              value={field.value}
              actions={
                !readOnly && (
                  <Fragment>
                    <ActionIconButton
                      type="button"
                      action={changeOneReferenceFieldAction}
                      target={config}
                      context={{
                        record: field.value,
                        onChangeRecord: field.onChange,
                      }}
                    />

                    <ActionMenu
                      trigger={
                        <IconButton
                          type="button"
                          icon={DotsHorizontal}
                          accessibilityLabel="Actions"
                          hideTooltip
                        />
                      }
                      target={config}
                      context={{
                        record: field.value,
                        onRemoveRecord() {
                          field.onChange(null);
                        },
                      }}
                      actions={[removeOneReferenceFieldAction]}
                    />
                  </Fragment>
                )
              }
            />
          }
        />
      )}
    </div>
  );
}
