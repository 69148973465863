import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "@baselayer/ui/components/Button";

import { withAuth } from "~/lib/auth";
import { Navigate, useNavigate } from "~/router";
import { Logo } from "~/svgs/Logo";

function Callback() {
  const [search] = useSearchParams();
  const code = search.get("code");
  const navigate = useNavigate();

  // Send code to parent window
  const handledRef = useRef(false);
  useEffect(() => {
    if (!code) return;
    if (handledRef.current) return;
    handledRef.current = true;

    if (window.opener) {
      // If there is a parent window, it's safe to assume we're in the web auth flow
      // In this case, we'll send the code to the parent window
      window.opener.postMessage(
        { type: "PROVIDER_SIGN_IN_CALLBACK", code },
        window.location.origin,
      );
      window.close();
    } else {
      // If there is no parent window, it's safe to assume we're in the desktop auth flow
      // In this case, we'll redirect the user to the desktop app
      const url = new URL("/signin", "baselayer://");
      url.searchParams.append("code", code);
      window.open(url.toString());
    }
  }, [code]);

  if (!code) {
    return <Navigate to="/signin" />;
  }

  if (window.opener) {
    return null;
  }

  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="w-full max-w-lg flex flex-col gap-2">
        <div className="flex flex-col gap-1 items-center text-center mb-6">
          <div className="w-8 aspect-square text-icon-subtle mb-2.5">
            <Logo />
          </div>
          <h1 className="text-xl font-semibold text-primary">
            You're signed in
          </h1>
          <p className="text-placeholder">
            You will be automatically redirected to the app.
          </p>
        </div>

        <Button
          size="lg"
          onClick={() => {
            // If there is no parent window, it's safe to assume we're in the desktop auth flow
            // In this case, we'll redirect the user to the desktop app
            const url = new URL("/signin", "baselayer://");
            url.searchParams.append("code", code);
            window.open(url.toString());
          }}
        >
          Open desktop app
        </Button>
        <Button
          size="lg"
          variant="secondary"
          onClick={() => {
            const search = new URLSearchParams({ code });
            navigate({ pathname: "/signin", search: search.toString() });
          }}
        >
          Open in browser
        </Button>
      </div>
    </div>
  );
}

const ProtectedCallback = withAuth(Callback, { type: "public" });

export default ProtectedCallback;
