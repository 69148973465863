import { useEffect, useState } from "react";
import { webContents } from "@todesktop/client-core";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react";

import { IconButton } from "~/components/IconButton";

export function DesktopNavigation() {
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  useEffect(() => {
    if (!isDesktopApp()) return;

    async function onNavigate() {
      setCanGoBack(await webContents.canGoBack());
      setCanGoForward(await webContents.canGoForward());
    }
    onNavigate();

    const offPromise = webContents.on("did-navigate-in-page", onNavigate);
    return () => {
      offPromise.then((off) => off());
    };
  }, []);

  return (
    <div className="web:hidden flex justify-end">
      <div className="flex-1 app-drag" />
      <div className="browser-navigation flex items-center gap-1 no-app-drag">
        <IconButton
          icon={ChevronLeft}
          hideTooltip
          accessibilityLabel="Back"
          disabled={!canGoBack}
          onClick={() => {
            window.history.back();
          }}
        />
        <IconButton
          icon={ChevronRight}
          hideTooltip
          accessibilityLabel="Forward"
          disabled={!canGoForward}
          onClick={() => {
            window.history.forward();
          }}
        />
      </div>
    </div>
  );
}
