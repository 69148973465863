import { PropsWithChildren, ReactNode, useState } from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import { XClose } from "@untitled-ui/icons-react";
import { AnimatePresence, m } from "framer-motion";

import { cn } from "@baselayer/ui/lib/cn";
import { easeOutExpo } from "@baselayer/ui/lib/motion";

import { IconButton } from "~/components/IconButton";

export interface DialogCloseProps extends PropsWithChildren<unknown> {}
export function DialogClose({ children }: DialogCloseProps) {
  return <RadixDialog.Close asChild>{children}</RadixDialog.Close>;
}

export interface DialogProps extends PropsWithChildren<unknown> {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
  onDismiss?(): void;
  trigger?: ReactNode;
  title: string;
  dark?: boolean;
  onOpenAutoFocus?(event: Event): void;
}

export function Dialog({
  trigger,
  children,
  initialOpen = false,
  onDismiss,
  title,
  dark,
  onOpenAutoFocus,
  ...props
}: DialogProps) {
  const [_open, _onOpenChange] = useState(initialOpen);
  const open = props.open ?? _open;
  const onOpenChange = props.onOpenChange ?? _onOpenChange;

  return (
    <RadixDialog.Root
      defaultOpen={initialOpen}
      open={open}
      onOpenChange={onOpenChange}
    >
      <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
      <AnimatePresence onExitComplete={onDismiss}>
        {open && (
          <RadixDialog.Portal forceMount>
            <RadixDialog.Overlay asChild>
              <m.div
                className="z-50 fixed inset-0 bg-reverse/60"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: easeOutExpo, duration: 0.35 }}
              />
            </RadixDialog.Overlay>
            <RadixDialog.Content asChild onOpenAutoFocus={onOpenAutoFocus}>
              <m.div
                className={cn(
                  "z-50 fixed right-0 bottom-staff-bar sm:top-0 w-full max-w-screen-md max-h-[calc(95vh-var(--staff-bar,0px))] sm:max-h-screen outline-none sm:p-3 todesktop:app-no-drag flex",
                  "ease-out-expo duration-350 transition-transform",
                  "max-sm:data-[state=closed]:translate-y-full sm:data-[state=closed]:opacity-0",
                  "data-[state=closed]:animate-dialog-leave-mobile sm:data-[state=closed]:animate-dialog-leave",
                  "data-[state=open]:animate-dialog-enter-mobile sm:data-[state=open]:animate-dialog-enter",
                  "sm:[&:has(~_[role=dialog][data-state=open])]:-translate-x-7 sm:[&:has(~_[role=dialog][data-state=open])]:scale-97",
                )}
              >
                <div
                  className={cn(
                    "flex flex-col flex-1 bg-main rounded-t-lg sm:rounded-lg border border-primary w-full shadow-right-sidebar",
                    dark && "dark",
                  )}
                >
                  <header className="pl-4 pr-3 py-3 flex items-center justify-between border-b border-primary">
                    <RadixDialog.Title asChild>
                      <h2 className="text-sm sm:text-base font-medium">
                        {title}
                      </h2>
                    </RadixDialog.Title>
                    <RadixDialog.Close asChild>
                      <IconButton
                        icon={XClose}
                        accessibilityLabel="Close"
                        onClick={() => onOpenChange(false)}
                        hideTooltip
                      />
                    </RadixDialog.Close>
                  </header>
                  <div className="relative overflow-auto flex flex-col flex-1">
                    {children}
                  </div>
                </div>
              </m.div>
            </RadixDialog.Content>
          </RadixDialog.Portal>
        )}
      </AnimatePresence>
    </RadixDialog.Root>
  );
}
