import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { getUsersExist } from "api-client";
import { AnimatePresence, m } from "framer-motion";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";
import { easeOutSmooth } from "@baselayer/ui/lib/motion";

import { Form } from "~/components/_forms/Form";
import { TextField } from "~/components/TextField";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useSigninMutation, useSignupMutation } from "~/mutations";
import { Link, useNavigate } from "~/router";

const EmailFormSchema = z.object({
  email: z.string().email("Enter a valid email address"),
  password: z.string(),
  confirm_password: z.string(),
});
type EmailForm = z.infer<typeof EmailFormSchema>;

export function EmailForm() {
  const navigate = useNavigate();

  const checkEmailExistsMutation = useMutation({
    mutationFn: (email: string) => {
      return getUsersExist({ email }, { signal: new AbortController().signal });
    },
  });

  let mode: "signin" | "signup" | "idle" = "idle";
  if (checkEmailExistsMutation.isSuccess) {
    mode = "signin";
  } else if (checkEmailExistsMutation.isError) {
    mode = "signup";
  }
  const modeRef = useRef(mode);
  if (!checkEmailExistsMutation.isPending) {
    modeRef.current = mode;
  }
  const currentMode = modeRef.current;

  const ModeAwareSchema = EmailFormSchema.superRefine((val, ctx) => {
    if (currentMode !== "idle" && !val.password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password is required",
        path: ["password"],
      });
    }
    if (currentMode === "signup" && val.password !== val.confirm_password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords do not match",
        path: ["confirm_password"],
      });
    }
  });

  const methods = useForm<EmailForm>({
    resolver: zodResolver(ModeAwareSchema),
    defaultValues: {
      email: import.meta.env.VITE_AUTH_EMAIL ?? "",
      password: import.meta.env.VITE_AUTH_PASS ?? "",
      confirm_password: "",
    },
    reValidateMode: "onSubmit",
  });

  const showPasswordField = currentMode !== "idle";
  const showConfirmPasswordField = currentMode === "signup";

  const email = methods.watch("email");
  useEffect(() => {
    if (currentMode !== "idle" && !email && !methods.watch("password")) {
      checkEmailExistsMutation.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMode, email]);

  const signIn = useSigninMutation();
  const signUp = useSignupMutation();

  const onSubmit = methods.handleSubmit(async (data: EmailForm) => {
    if (currentMode === "idle") {
      checkEmailExistsMutation.mutate(data.email, {
        onSuccess() {
          setTimeout(() => methods.setFocus("password"), 100);
        },
      });
    } else if (currentMode === "signin") {
      signIn.mutate(data, {
        onSuccess(data) {
          if (data.email_verified && data.organization_memberships.length > 0) {
            const org = data.organization_memberships[0].organization.slug;
            navigate("/o/:org", { params: { org }, replace: true });
          } else if (data.email_verified) {
            navigate("/account", { replace: true });
          } else {
            navigate("/verify", { replace: true });
          }
        },
        onError: (error) => handleFormErrors(error, methods.setError),
      });
    } else {
      signUp.mutate(data, {
        onSuccess() {
          navigate("/verify", { replace: true });
        },
        onError: (error) => handleFormErrors(error, methods.setError),
      });
    }
  });

  return (
    <Form className="flex flex-col" onSubmit={onSubmit} noValidate>
      <TextField
        name="email"
        type="email"
        label="Email"
        placeholder="me@acme.com"
        autoFocus
        control={methods.control}
        onBlur={(e) => {
          if (currentMode !== "idle") {
            checkEmailExistsMutation.mutate(e.target.value);
          }
        }}
      />

      <m.div
        initial={{
          visibility: showPasswordField ? "visible" : "hidden",
          height: showPasswordField ? "auto" : 0,
          opacity: showPasswordField ? 1 : 0,
        }}
        animate={{
          visibility: showPasswordField ? "visible" : "hidden",
          height: showPasswordField ? "auto" : 0,
          opacity: showPasswordField ? 1 : 0,
        }}
        transition={{ duration: 0.25, ease: easeOutSmooth }}
      >
        <div className="flex flex-col mt-3">
          <TextField
            name="password"
            type="password"
            label="Password"
            placeholder="••••••••••"
            control={methods.control}
          />
        </div>
      </m.div>

      <AnimatePresence>
        {currentMode === "signup" && (
          <m.div
            initial={{
              visibility: "hidden",
              height: 0,
              opacity: 0,
            }}
            animate={{
              visibility: showConfirmPasswordField ? "visible" : "hidden",
              height: showConfirmPasswordField ? "auto" : 0,
              opacity: showConfirmPasswordField ? 1 : 0,
            }}
            exit={{
              visibility: "hidden",
              height: 0,
              opacity: 0,
            }}
            transition={{ duration: 0.25, ease: easeOutSmooth }}
          >
            <div className="flex flex-col mt-3">
              <TextField
                name="confirm_password"
                type="password"
                label="Confirm password"
                placeholder="••••••••••"
                control={methods.control}
              />
            </div>
          </m.div>
        )}
      </AnimatePresence>

      <div className="flex flex-col gap-3.5 mt-3">
        <Button size="lg">
          {currentMode === "signin" && "Sign in"}
          {currentMode === "signup" && "Create your account"}
          {currentMode === "idle" && "Continue with email"}
        </Button>

        <div className="text-center">
          <Link
            className="text-secondary hover:text-primary transition-colors text-sm text-center"
            to="/forgot-password"
          >
            Forgot password?
          </Link>
        </div>
      </div>
    </Form>
  );
}
