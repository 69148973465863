import { ComponentType } from "react";
import {
  Activity,
  AlertTriangle,
  AlignLeft,
  Attachment01,
  Award02,
  BankNote02,
  BarChart10,
  Bell01,
  BluetoothOn,
  BookOpen01,
  Building05,
  Bus,
  Calendar,
  Camera01,
  Check,
  CheckSquare,
  Clock,
  CloudBlank01,
  Code01,
  Coins02,
  Command,
  CreditCard02,
  Cryptocurrency01,
  Cube01,
  Cursor02,
  Data,
  Database02,
  Download02,
  FaceSmile,
  File04,
  Film01,
  Flag01,
  Folder,
  GamingPad01,
  Gift01,
  GitCommit,
  Globe04,
  Hand,
  HeartRounded,
  Home02,
  Image01,
  Key02,
  Laptop02,
  Link01,
  List,
  Lock03,
  Mail01,
  Map01,
  Mark,
  MarkerPin01,
  MessageCircle02,
  Package,
  Palette,
  Passcode,
  Phone,
  Plane,
  Podcast,
  PuzzlePiece01,
  Receipt,
  Repeat01,
  Server02,
  Settings02,
  Shield01,
  ShoppingBag03,
  ShoppingCart03,
  Shuffle01,
  Square,
  Table,
  Tag01,
  Target04,
  TextInput,
  Thermometer01,
  Ticket01,
  Train,
  Trash04,
  Tv02,
  User01,
  Users01,
  VolumeMax,
  Wallet02,
  Wifi,
} from "@untitled-ui/icons-react";
import { Model } from "api-client";

export const IconMap: Record<
  NonNullable<Model["icon"]>,
  ComponentType<{ className?: string }>
> = {
  user: User01,
  user_group: Users01,
  attachment: Attachment01,
  key: Key02,
  bell: Bell01,
  globe: Globe04,
  heart: HeartRounded,
  settings: Settings02,
  commit: GitCommit,
  download: Download02,
  passcode: Passcode,
  list: List,
  text: AlignLeft,
  activity: Activity,
  app: Square,
  field: TextInput,
  model: Cube01,
  data: Data,
  item: CheckSquare,
  flag: Flag01,
  target: Target04,
  trash: Trash04,
  checkmark: Check,
  building: Building05,
  cloud: CloudBlank01,
  home: Home02,
  link: Link01,
  chart: BarChart10,
  message: MessageCircle02,
  mail: Mail01,
  phone: Phone,
  movie: Film01,
  game: GamingPad01,
  computer: Laptop02,
  podcast: Podcast,
  repeat: Repeat01,
  shuffle: Shuffle01,
  bluetooth: BluetoothOn,
  wifi: Wifi,
  tv: Tv02,
  volume: VolumeMax,
  lock: Lock03,
  password: Passcode,
  shield: Shield01,
  command: Command,
  cursor: Cursor02,
  hand: Hand,
  color: Palette,
  award: Award02,
  book: BookOpen01,
  temperature: Thermometer01,
  clock: Clock,
  calendar: Calendar,
  camera: Camera01,
  image: Image01,
  file: File04,
  folder: Folder,
  alert: AlertTriangle,
  notification: Bell01,
  face: FaceSmile,
  table: Table,
  code: Code01,
  database: Database02,
  package: Package,
  puzzle: PuzzlePiece01,
  server: Server02,
  money: BankNote02,
  coin: Coins02,
  credit_card: CreditCard02,
  cryptocurrency: Cryptocurrency01,
  gift: Gift01,
  receipt: Receipt,
  bag: ShoppingBag03,
  cart: ShoppingCart03,
  tag: Tag01,
  wallet: Wallet02,
  bus: Bus,
  location: Mark,
  map: Map01,
  pin: MarkerPin01,
  plane: Plane,
  ticket: Ticket01,
  train: Train,
};
