import { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Model,
  ModelRecord,
  organizationsModelsRecordsViewsInfiniteQueryOptions,
  organizationsModelsViewsInfiniteQueryOptions,
  useInfiniteData,
  View,
} from "api-client";

import { FacePile, FacePileItem } from "~/components/FacePile";
import { Tooltip } from "~/components/Tooltip";
import { useCurrentUser } from "~/lib/current";
import { formatTimestamp } from "~/lib/date";
import { useFlagEnabled } from "~/lib/flags";
import { useLogView } from "~/lib/presence/views";
import { usePresentUsers } from "~/realtime/presence";

function viewsQueryOptions(model: Model | null, record?: ModelRecord | null) {
  if (typeof record !== "undefined") {
    return {
      ...organizationsModelsRecordsViewsInfiniteQueryOptions({
        organizationSlug: model?.organization_slug ?? "",
        modelId: model?.id ?? "",
        recordId: record?.id ?? "",
      }),
      enabled: !!model && !!record,
    };
  } else {
    return {
      ...organizationsModelsViewsInfiniteQueryOptions({
        organizationSlug: model?.organization_slug ?? "",
        modelId: model?.id ?? "",
      }),
      enabled: !!model,
    };
  }
}

export interface ViewsPile {
  model: Model | null;
  record?: ModelRecord | null;
}

export function ViewsPile({ model, record }: ViewsPile) {
  const me = useCurrentUser();

  const viewsQuery = useInfiniteQuery({
    ...viewsQueryOptions(model, record),
    refetchOnWindowFocus: true,
  });
  const views = useInfiniteData(viewsQuery.data);

  const presentUserIds = usePresentUsers(record ?? model);
  useEffect(() => {
    if (!presentUserIds.size) return;
    const presentUserIdsArray = Array.from(presentUserIds);
    const viewsUserIdsSet = new Set(views.map((view) => view.viewer.user.id));

    const isMissingViews = presentUserIdsArray.some(
      (id) => !viewsUserIdsSet.has(id),
    );
    if (isMissingViews) {
      viewsQuery.refetch();
    }
  }, [viewsQuery, views, presentUserIds]);

  const orderedViews = useMemo(() => {
    if (!me && !presentUserIds.size) return views;

    const ordered: View[] = [];
    for (const view of views) {
      if (view.viewer.user.id === me?.id) continue;
      if (presentUserIds.has(view.viewer.user.id)) {
        ordered.unshift(view);
      } else {
        ordered.push(view);
      }
    }
    return ordered;
  }, [views, me, presentUserIds]);

  useLogView(model, record);

  const hasViewsFlag = useFlagEnabled("views");
  if (!hasViewsFlag) return null;

  return (
    <div className="-my-0.5 max-sm:hidden">
      <FacePile size="sm">
        {me && (
          <Tooltip label="You">
            <FacePileItem user={me} />
          </Tooltip>
        )}

        {orderedViews.map((view) => {
          const isPresent = presentUserIds.has(view.viewer.user.id);
          return (
            <Tooltip
              key={view.id}
              label={view.viewer.user.full_name}
              trailingAccessory={
                !isPresent && (
                  <p className="text-xs font-medium text-secondary">
                    {formatTimestamp(view.updated_at, { addSuffix: false })}
                  </p>
                )
              }
            >
              <FacePileItem user={view.viewer.user} disabled={!isPresent} />
            </Tooltip>
          );
        })}
      </FacePile>
    </div>
  );
}
