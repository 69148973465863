import { DotsHorizontal, Home02 } from "@untitled-ui/icons-react";
import {
  organizationsApplicationsRecentsInfiniteQueryOptions,
  organizationsModelQueryOptions,
  useInfiniteData,
  useInfiniteQueries,
  useOrganizationsApplicationsModelsInfiniteQuery,
} from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { applicationActions } from "~/actions/application";
import { ActionMenu } from "~/components/ActionMenu";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { IconButton } from "~/components/IconButton";
import { ModelBlock } from "~/components/ModelBlock";
import { RecordList } from "~/components/RecordList";
import { Titlebar } from "~/components/Titlebar";
import { useCurrentApplication, useCurrentUser } from "~/lib/current";
import { useParams } from "~/router";

function ApplicationDetail() {
  const params = useParams("/o/:org/w/:app");
  const currentUser = useCurrentUser();
  const app = useCurrentApplication();

  const modelsQuery = useOrganizationsApplicationsModelsInfiniteQuery({
    organizationSlug: params.org,
    applicationSlug: params.app,
  });
  const data = useInfiniteData(modelsQuery.data);
  const models = useInfiniteQueries(data, (model) =>
    organizationsModelQueryOptions({
      id: model.id,
      organizationSlug: model.organization_slug,
    }),
  );

  return (
    <div className="flex-1 flex flex-col w-full">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem
            key="home"
            actions={
              <ActionMenu
                trigger={
                  <IconButton
                    hideTooltip
                    icon={DotsHorizontal}
                    accessibilityLabel="Actions"
                  />
                }
                actions={applicationActions}
                target={app}
              />
            }
          >
            <span className="p-1 flex items-center gap-2">
              <Home02 className="w-4 h-4 text-icon shrink-0" />
              <span>Home</span>
            </span>
          </BreadcrumbItem>,
        ]}
      />

      <div className="p-4 pt-1 pb-6 flex-1 flex flex-col gap-8">
        <header className="flex flex-col -mb-2">
          <h1 className={cn("text-2xl font-semibold")}>
            Welcome back, {currentUser?.full_name}
          </h1>
        </header>

        <section className="flex flex-col gap-2.5">
          <h2 className="text-sm font-medium text-secondary">All models</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-3">
            {models.map((model) => (
              <ModelBlock key={model.id} model={model} />
            ))}
          </div>
        </section>

        <section className="flex flex-col gap-2.5">
          <h2 className="text-sm font-medium text-secondary">
            Recently viewed
          </h2>
          <RecordList
            queryOptions={{
              ...organizationsApplicationsRecentsInfiniteQueryOptions({
                organizationSlug: params.org,
                applicationSlug: params.app,
              }),
              refetchOnMount: "always",
              refetchOnWindowFocus: "always",
            }}
          />
        </section>
      </div>
    </div>
  );
}

export default ApplicationDetail;
