import { Outlet } from "react-router-dom";

import { SidebarProvider } from "~/components/Sidebar/provider";
import { SettingsSidebar } from "~/components/Sidebar/Settings";
import { useScreenMatches } from "~/lib/breakpoint";

function SettingsLayout() {
  const hideSidebar = useScreenMatches("collapse-sidebar");

  return (
    <div className="flex-1 flex">
      {!hideSidebar ? (
        <div className="min-w-sidebar">
          <SettingsSidebar />
        </div>
      ) : null}
      <div className="w-screen collapse-sidebar:w-[calc(100vw-var(--sidebar-width))] flex">
        <div className="w-full">
          <SidebarProvider sidebar={<SettingsSidebar />}>
            <Outlet />
          </SidebarProvider>
        </div>
      </div>
    </div>
  );
}

export default SettingsLayout;
