import { useFocusVisible } from "react-aria";
import { Model } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { RecordThumbnail } from "~/components/_records/RecordThumbnail";
import { Link } from "~/router";

export interface ModelBlockProps {
  model: Model;
}

export function ModelBlock({ model }: ModelBlockProps) {
  const { isFocusVisible } = useFocusVisible();
  return (
    <div
      className={cn(
        "relative flex flex-col rounded-[13px] gap-1.5 p-4 bg-action border border-action overflow-hidden",
        "hover:bg-action-active",
        isFocusVisible && "focus-within:focus-outline",
      )}
    >
      <div className="-ml-0.5">
        <RecordThumbnail size="lg" model={model} />
      </div>
      <p className="mt-1 font-medium">
        <Link
          className="focus-visible:outline-none before:absolute before:inset-0"
          to={`/o/:org/w/:app/m/:model`}
          params={{
            org: model.organization_slug,
            app: model.application_slug,
            model: model.id,
          }}
        >
          {model.name}
        </Link>
      </p>
    </div>
  );
}
