import { ReactNode, useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ApiModel } from "api-client";

import { ExtractContext, ExtractTarget } from "~/components/ActionMenu";
import { useCurrentUser } from "~/lib/current";
import { usePathname } from "~/lib/pathname";
import { Path, useNavigate } from "~/router";
import { Action, ActionContext, ActionEvent } from "~/types";

export function apply<V extends string | number | boolean, T>(
  variableOrFunction: V | ((target: T) => V),
) {
  return typeof variableOrFunction === "function"
    ? variableOrFunction
    : () => variableOrFunction;
}

export function useActionContext<T extends ApiModel, ExtraContext = unknown>(
  target: T | null,
  extraContext?: ExtraContext,
): ActionContext<T, ExtraContext> | null {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const pathname = usePathname();
  const params = useParams();

  return useMemo(() => {
    if (!target) return null;
    return {
      target,
      currentUser,
      location,
      matchPath(path: Path) {
        if (path === pathname) return params;
        return null;
      },
      ...(extraContext as any),
    };
  }, [target, currentUser, location, pathname, params, extraContext]);
}

export interface UseActionHelpersOptions {
  onDismiss?(): void;
  onCleanup?(): void;
}
export function useActionHelpers({
  onDismiss,
  onCleanup,
}: UseActionHelpersOptions) {
  const navigate = useNavigate();
  const location = useLocation();
  const [mountedNode, setMountedNode] = useState<ReactNode>(null);

  const helpers = useMemo(
    () => ({
      dismiss() {
        onDismiss?.();
      },
      mount(node: ReactNode) {
        setMountedNode(node);
      },
      cleanup() {
        setMountedNode(null);
        onCleanup?.();
      },
      navigate,
      location,
    }),
    [navigate, onDismiss, onCleanup, location],
  );

  return [mountedNode, helpers] as const;
}

export function useExecuteAction<T extends ApiModel, ExtraContext = unknown>(
  context: ActionContext<T, ExtraContext> | null,
  helpers: ReturnType<typeof useActionHelpers>[1],
) {
  return useCallback(
    async (action: Action<T, ExtraContext>) => {
      if (!context) return;

      const event = new ActionEvent(context);
      const promise = action.execute(event, helpers);
      if (!event.defaultPrevented) {
        helpers.dismiss();
      }
      const maybeNode = await promise;
      if (maybeNode) {
        helpers.mount(maybeNode);
      }
    },
    [context, helpers],
  );
}

export function useAction<Actions extends Action<any, any>[]>(
  target: ExtractTarget<Actions> | null,
  extraContext?: ExtractContext<Actions>,
  options?: UseActionHelpersOptions,
) {
  const context = useActionContext(target, extraContext);
  const [node, helpers] = useActionHelpers(options ?? {});
  const execute = useExecuteAction(context, helpers);

  return [node, execute] as const;
}
