import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { DropzoneState, FileError, useDropzone } from "react-dropzone";

import { Slot } from "~/components/Slot";

const ACCEPT_TYPES = {
  image: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
};

interface FileInputContextValue {
  dropzone: DropzoneState;
  value: string | Blob | null;
  isDragging: boolean;
  disabled: boolean;
  name?: string;
  remove: () => void;
}

const FileInputContext = createContext<FileInputContextValue>({
  dropzone: null as any,
  value: null,
  isDragging: false,
  disabled: false,
  name: undefined,
  remove: () => {},
});

export interface FileInputProps extends PropsWithChildren<unknown> {
  className?: string;
  name?: string;
  value: string | Blob | null;
  onValueChange(value: Blob | null): void;
  onError?(error: FileError): void;
  disabled?: boolean;
  accept: (keyof typeof ACCEPT_TYPES)[];
  maxSizeMb?: number;
  allowClick?: boolean;
}

export function FileInput({
  className,
  name,
  children,
  value,
  onValueChange,
  onError,
  allowClick = true,
  disabled = false,
  accept,
  maxSizeMb = 1,
}: FileInputProps) {
  const [internalValue, setInternalValue] = useState<string | Blob | null>(
    value,
  );
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const [isDragging, setIsDragging] = useState(false);
  const dropzone = useDropzone({
    accept: accept
      .map((key) => ACCEPT_TYPES[key])
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    maxSize: maxSizeMb * 1024 * 1024,
    maxFiles: 1,
    noClick: !allowClick,
    onDropAccepted(files) {
      setInternalValue(files[0]);
      onValueChange(files[0]);
    },
    onDragEnter() {
      setIsDragging(true);
    },
    onDragLeave() {
      setIsDragging(false);
    },
    onDrop() {
      setIsDragging(false);
    },
    onDropRejected(rejections) {
      const error = rejections.at(0)?.errors.at(0);
      if (error) {
        onError?.(error);
      }
    },
    disabled,
  });

  const remove = useCallback(() => {
    setInternalValue(null);
    onValueChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FileInputContext.Provider
      value={{
        dropzone,
        value: internalValue,
        name,
        isDragging,
        disabled,
        remove,
      }}
    >
      <div className={className} data-dragging={isDragging}>
        {children}
      </div>
    </FileInputContext.Provider>
  );
}

export interface FileInputTriggerProps extends PropsWithChildren<unknown> {
  asChild: true;
}
export function FileInputTrigger({ children }: FileInputTriggerProps) {
  const ctx = useContext(FileInputContext);
  return <Slot onClick={() => ctx.dropzone.open()}>{children}</Slot>;
}

export interface FileInputRemoveProps extends PropsWithChildren<unknown> {
  asChild: true;
}
export function FileInputRemove({ children }: FileInputRemoveProps) {
  const ctx = useContext(FileInputContext);

  if (!ctx.value) return null;

  return (
    <Slot type="button" onClick={() => ctx.remove()}>
      {children}
    </Slot>
  );
}

export interface FileInputPreviewProps {
  children(url: string | null): ReactNode;
  asChild: true;
}
export function FileInputPreview({ children }: FileInputPreviewProps) {
  const ctx = useContext(FileInputContext);

  let url: string | null;
  if (typeof ctx.value === "string") {
    url = ctx.value;
  } else if (ctx.value) {
    url = URL.createObjectURL(ctx.value);
  } else {
    url = null;
  }

  return (
    <div {...ctx.dropzone.getRootProps({ className: "flex outline-none" })}>
      <input
        {...ctx.dropzone.getInputProps({ name: ctx.name, id: ctx.name })}
      />

      {children(url)}
    </div>
  );
}
