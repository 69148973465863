import { useCallback } from "react";
import {
  Application,
  infiniteFlatten,
  organizationsApplicationQueryOptions,
  organizationsApplicationsModelsInfiniteQueryOptions,
  organizationsModelsFieldsQueryOptions,
  queryClient,
} from "api-client";

import { useRealtimeChannel, useRealtimeEvent } from "~/lib/pusher";

export function useApplicationSyncRealtime(application: Application | null) {
  const channel = useRealtimeChannel(application?.pusher_channel);
  useRealtimeEvent(
    channel,
    "application_sync_update",
    useCallback(
      (event) => {
        if (!application) return;

        // Update the application
        const applicationQuery = organizationsApplicationQueryOptions({
          organizationSlug: application.organization_slug,
          slug: application.slug,
        });
        queryClient.setQueryData(applicationQuery.queryKey, (data) => {
          if (!data) return;
          return {
            ...data,
            latest_sync: event.data,
          };
        });
        // Invalidate models
        const modelsQuery = organizationsApplicationsModelsInfiniteQueryOptions(
          {
            organizationSlug: application.organization_slug,
            applicationSlug: application.slug,
          },
        );
        queryClient.invalidateQueries(modelsQuery);
        const infiniteModels = queryClient.getQueryData(modelsQuery.queryKey);
        const models = infiniteFlatten(infiniteModels);
        // Invalidate fields
        models.forEach((model) => {
          const fieldsQuery = organizationsModelsFieldsQueryOptions({
            organizationSlug: application.organization_slug,
            modelId: model.id,
          });
          queryClient.invalidateQueries(fieldsQuery);
        });
      },
      [application],
    ),
  );
}
