import { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { InfoCircle } from "@untitled-ui/icons-react";
import { createModelRecordMutationOptions } from "api-client";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { ActionsBar } from "~/components/_forms/ActionsBar";
import { Form } from "~/components/_forms/Form";
import { useRecordForm } from "~/components/_forms/RecordForm/hook";
import { RecordFields } from "~/components/_records/RecordFields";
import { RecordThumbnail } from "~/components/_records/RecordThumbnail";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { Titlebar } from "~/components/Titlebar";
import { useCurrentModel, useCurrentRecord } from "~/lib/current";
import { Link, useNavigate, useParams } from "~/router";

function NewRecord() {
  const params = useParams("/o/:org/w/:app/m/:model/new");
  const navigate = useNavigate();

  const model = useCurrentModel();
  const record = useCurrentRecord();
  const { methods, fields } = useRecordForm(model, record);

  const titleField = useMemo(
    () => fields.find((field) => field.id === model?.title_field_id),
    [fields, model?.title_field_id],
  );
  const currentTitle = titleField
    ? methods.watch(`data.${titleField.field_name}`)?.toString()
    : null;

  useEffect(() => {
    methods.setValue("title", currentTitle ?? "");
  }, [currentTitle, methods]);

  const createMutation = useMutation(createModelRecordMutationOptions());
  const isDirty = methods.formState.isDirty;
  const canSubmit = methods.formState.isValid && !createMutation.isPending;
  const submit = methods.handleSubmit(async (data) => {
    if (!model) return;
    createMutation.mutate(
      {
        organizationSlug: model.organization_slug,
        modelId: model.id,
        record: data,
      },
      {
        onSuccess(data) {
          navigate("/o/:org/w/:app/m/:model/r/:record", {
            params: {
              org: model.organization_slug,
              app: model.application_slug,
              model: model.id,
              record: data.id,
            },
            replace: true,
          });
        },
      },
    );
  });

  return (
    <FormProvider {...methods}>
      <div className="flex-1 flex flex-col w-full">
        <Titlebar
          breadcrumbs={
            <BreadcrumbItem>
              <Link
                className="text-secondary"
                to="/o/:org/w/:app/m/:model/new"
                params={params}
              >
                New
              </Link>
            </BreadcrumbItem>
          }
        />

        <div className="py-4 pb-0 flex flex-col flex-1 items-center gap-6">
          <header className="flex flex-col items-start gap-3 max-w-2xl w-full px-4">
            <RecordThumbnail size="xl" model={model} />
            <h1
              className={cn(
                "text-2xl font-semibold",
                !currentTitle && "text-placeholder",
              )}
            >
              {currentTitle || titleField?.name || "Untitled"}
            </h1>
          </header>

          <Form
            className="flex-1 flex flex-col max-w-2xl w-full"
            onSubmit={submit}
          >
            <RecordFields record={methods.watch()} />

            <ActionsBar hidden={!isDirty}>
              <div className="flex items-center gap-10 pl-1.5">
                <div className="flex items-center gap-2">
                  <InfoCircle className="w-4 h-4 text-icon" />
                  <p className="text-sm font-medium">Unsaved changes</p>
                </div>

                <div className="flex items-center gap-1.5">
                  <Button
                    type="submit"
                    size="sm"
                    disabled={!canSubmit}
                    busy={createMutation.isPending}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </ActionsBar>
          </Form>
        </div>
      </div>
    </FormProvider>
  );
}

export default NewRecord;
