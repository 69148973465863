"use client";

import { cn } from "@baselayer/ui/lib/cn";

export interface ActivityIndicatorProps {
  className?: string;
}

export function ActivityIndicator({ className }: ActivityIndicatorProps) {
  return (
    <svg
      className={cn("animate-spin", className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.3"
        cx="8"
        cy="8"
        r="7"
        stroke="currentColor"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />

      <path
        d="M15 8C15 5.0148 13.1314 2.46605 10.5 1.45963"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        vectorEffect="non-scaling-stroke"
      />

      <circle cx="8" cy="8" r="3" fill="currentColor" />
    </svg>
  );
}
