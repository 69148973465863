import { useMemo } from "react";
import { resolveValue } from "react-hot-toast";
import { toast, useToaster } from "react-hot-toast/headless";
import {
  AlertCircle,
  CheckCircle,
  InfoCircle,
  XClose,
} from "@untitled-ui/icons-react";
import { AnimatePresence, m } from "framer-motion";

import { cn } from "@baselayer/ui/lib/cn";
import { easeOutSmooth } from "@baselayer/ui/lib/motion";

import { IconButton } from "~/components/IconButton";

const TOAST_DURATION = 4000;

export function Toaster() {
  const { toasts, handlers } = useToaster({
    duration: TOAST_DURATION,
    success: {
      duration: TOAST_DURATION,
    },
    error: {
      duration: TOAST_DURATION,
    },
    blank: {
      duration: TOAST_DURATION,
    },
  });
  const orderedToasts = useMemo(() => {
    return [...toasts].reverse().filter((t) => t.visible);
  }, [toasts]);

  return (
    <m.div
      className="fixed top-0 sm:top-auto sm:bottom-staff-bar inset-x-0 z-max p-3 flex flex-col items-end pointer-events-none"
      onMouseEnter={handlers.startPause}
      onMouseLeave={handlers.endPause}
    >
      <m.ol className="flex flex-col gap-1.5 w-full max-w-sm sm:max-w-md pointer-events-none">
        <AnimatePresence initial={false} mode="popLayout">
          {orderedToasts.map((t, index) => (
            <m.li
              key={t.id}
              {...t.ariaProps}
              layout
              className="rounded-lg pointer-events-auto"
              transition={{ duration: 0.3, ease: easeOutSmooth }}
              style={{ zIndex: index } as any}
            >
              <m.div
                className={cn(
                  "dark border border-primary py-2.5 pl-3 pr-2 rounded-lg shadow",
                  "flex items-start gap-3",
                )}
                initial={{ opacity: 0, y: "100%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 15 }}
                transition={{ duration: 0.3, ease: easeOutSmooth }}
              >
                <div className="flex items-start flex-1 gap-2 mt-0.5">
                  {t.type === "blank" && (
                    <InfoCircle className="w-5 h-5 text-icon shrink-0" />
                  )}
                  {t.type === "success" && (
                    <CheckCircle className="w-5 h-5 text-action shrink-0" />
                  )}
                  {t.type === "error" && (
                    <AlertCircle className="w-5 h-5 text-danger shrink-0" />
                  )}
                  <div className="text-sm font-medium">
                    {resolveValue(t.message, t)}
                  </div>
                </div>
                <IconButton
                  icon={XClose}
                  accessibilityLabel="Close"
                  hideTooltip
                  onClick={() => toast.dismiss(t.id)}
                />
              </m.div>
            </m.li>
          ))}
        </AnimatePresence>
      </m.ol>
    </m.div>
  );
}
