import { ForwardedRef, forwardRef, useEffect, useId, useRef } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { composeRefs } from "@radix-ui/react-compose-refs";

import { cn } from "@baselayer/ui/lib/cn";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { ErrorCode, ErrorMessage } from "~/components/ErrorMessage";

// Make text area take up the height of its content
const updateTextAreaHeight = (el: HTMLTextAreaElement) => {
  el.style.height = "inherit";
  el.style.height = `${el.scrollHeight}px`;
};

export interface TextAreaFieldProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label: string;
  hideLabel?: boolean;
  placeholder?: string;
  errorMessages?: Partial<Record<ErrorCode, string>>;
  size?: "xs" | "sm" | "md" | "lg";
  rows?: number;
  readOnly?: boolean;
}

function TextAreaFieldInner<T extends FieldValues>(
  {
    name,
    control,
    label,
    hideLabel,
    placeholder,
    errorMessages,
    size = "lg",
    rows = 2,
    readOnly,
  }: TextAreaFieldProps<T>,
  forwardedRef: ForwardedRef<HTMLTextAreaElement>,
) {
  const id = useId();
  const errorId = `${id}-error`;
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    if (!textAreaRef.current) {
      return;
    }

    const isTextAreaNode = (el: Element): el is HTMLTextAreaElement =>
      Boolean(textAreaRef.current?.isSameNode(el));

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (isTextAreaNode(entry.target)) {
          updateTextAreaHeight(entry.target);
        }
      }
    });
    observer.observe(textAreaRef.current);

    return () => {
      observer.disconnect();
    };
  });

  return (
    <div className="flex flex-col gap-2 items-start w-full group">
      <FieldLabel hidden={hideLabel} htmlFor={id} readOnly={readOnly}>
        {label}
      </FieldLabel>
      <div
        className={cn(
          {
            "px-3.5 min-h-11 gap-2": size === "lg",
            "px-3.5 min-h-10 gap-2": size === "md",
            "px-3 min-h-9 text-[15px] gap-1.5": size === "sm",
            "px-3 min-h-8 text-[15px] gap-1.5": size === "xs",
          },
          "flex flex-1 min-w-0 w-full items-center gap-2",
          "bg-action text-primary rounded-xl ring-1 ring-inset ring-action shadow",
          readOnly && "bg-action/50",
          !readOnly && "focus-within:ring-black focus-within:bg-action-active",
          !readOnly && "isolate-group-hover:bg-action-active",
          "[&>svg]:w-4 [&>svg]:h-4 [&>svg]:text-icon",
          "[&>p]:text-icon [&>p]:font-medium",
          "[&:has(textarea[aria-invalid='true'])]:ring-danger",
        )}
      >
        <textarea
          {...field}
          ref={composeRefs(textAreaRef, field.ref, forwardedRef)}
          onChange={(e) => {
            updateTextAreaHeight(e.currentTarget);
            field.onChange?.(e);
          }}
          className={cn(
            "flex-1 min-w-0 py-2.5 bg-transparent resize-none",
            "placeholder:text-placeholder",
            "focus/input:outline-none",
            "read-only:text-secondary read-only:cursor-default",
          )}
          id={id}
          name={name}
          placeholder={placeholder}
          aria-invalid={invalid}
          aria-describedby={error ? errorId : undefined}
          rows={rows}
          readOnly={readOnly}
        />
      </div>
      {error?.message && (
        <div className="px-1">
          <ErrorMessage
            label={label}
            error={error}
            overrides={errorMessages}
            id={errorId}
          />
        </div>
      )}
    </div>
  );
}

export const TextAreaField = forwardRef(TextAreaFieldInner) as <
  T extends FieldValues,
>(
  props: TextAreaFieldProps<T> & { ref?: ForwardedRef<HTMLTextAreaElement> },
) => ReturnType<typeof TextAreaFieldInner>;
