import { Outlet } from "react-router-dom";
import { useOrganizationsApplicationQuery } from "api-client";

import { Sidebar } from "~/components/Sidebar";
import { SidebarProvider } from "~/components/Sidebar/provider";
import { useScreenMatches } from "~/lib/breakpoint";
import NotFound from "~/pages/404";
import { useApplicationSyncRealtime } from "~/realtime/application-sync";
import { useParams } from "~/router";

function ApplicationLayout() {
  const params = useParams("/o/:org/w/:app");

  const applicationQuery = useOrganizationsApplicationQuery({
    organizationSlug: params.org,
    slug: params.app,
  });
  const application = applicationQuery.data ?? null;

  useApplicationSyncRealtime(application);

  const hideSidebar = useScreenMatches("collapse-sidebar");

  if (applicationQuery.isPending) return null;

  if (!applicationQuery.data) {
    return <NotFound />;
  }

  return (
    <div className="flex-1 flex">
      {!hideSidebar ? (
        <div className="min-w-sidebar">
          <Sidebar />
        </div>
      ) : null}

      <div
        className="w-screen collapse-sidebar:w-[calc(100vw-var(--sidebar-width))] flex"
        key={`${params.org}/${params.app}`}
      >
        <div className="w-full flex">
          <SidebarProvider sidebar={<Sidebar />}>
            <Outlet />
          </SidebarProvider>
        </div>
      </div>
    </div>
  );
}

export default ApplicationLayout;
