import toast from "react-hot-toast/headless";
import { generatePath } from "react-router-dom";
import {
  Copy01,
  Link01,
  Star01,
  Trash04,
  Type01,
} from "@untitled-ui/icons-react";
import {
  destroyModelRecordMutationOptions,
  favoriteRecordMutationOptions,
  ModelRecord,
  mutate,
  unfavoriteRecordMutationOptions,
} from "api-client";

import { AlertDialog } from "~/components/AlertDialog";
import { Path } from "~/router";
import { Favorite } from "~/svgs/Favorite";
import { Action } from "~/types";

export const favoriteRecordAction: Action<ModelRecord> = {
  id: "favoriteRecord",
  label: "Favorite",
  icon: Star01,
  applicable: (context) => !context.target.viewer_favorited_at,
  execute: async (evt) => {
    await mutate(favoriteRecordMutationOptions(), {
      organizationSlug: evt.context.target.organization_slug,
      modelId: evt.context.target.model_id,
      recordId: evt.context.target.id,
    });
  },
};

export const unfavoriteRecordAction: Action<ModelRecord> = {
  id: "unfavoriteRecord",
  label: "Unfavorite",
  icon: Favorite,
  applicable: (context) => !!context.target.viewer_favorited_at,
  execute: async (evt) => {
    await mutate(unfavoriteRecordMutationOptions(), {
      organizationSlug: evt.context.target.organization_slug,
      modelId: evt.context.target.model_id,
      recordId: evt.context.target.id,
    });
  },
};

export const copyRecordIdAction: Action<ModelRecord> = {
  id: "copyRecordId",
  group: "copy",
  label: "Copy ID",
  shortcut: "meta+shift+.",
  icon: Copy01,
  execute: async (evt) => {
    const text = evt.context.target.id;
    await navigator.clipboard.writeText(text);
    toast(`"${text}" copied to clipboard`);
  },
};

export const copyRecordTitleAction: Action<ModelRecord> = {
  id: "copyRecordTitle",
  group: "copy",
  label: "Copy title",
  shortcut: "meta+shift+'",
  icon: Type01,
  execute: async (evt) => {
    const title = evt.context.target.title ?? evt.context.target.id;
    await navigator.clipboard.writeText(title);
    toast(`"${title}" copied to clipboard`);
  },
};

export const copyRecordUrlAction: Action<ModelRecord> = {
  id: "copyRecordUrl",
  group: "copy",
  label: "Copy URL",
  shortcut: "meta+shift+,",
  icon: Link01,
  execute: async (evt) => {
    const pathname: Path = "/o/:org/w/:app/m/:model/r/:record";
    const asPath = generatePath(pathname, {
      org: evt.context.target.organization_slug,
      app: evt.context.target.application_slug,
      model: evt.context.target.model_id,
      record: evt.context.target.id,
    });
    const url = new URL(asPath, window.location.origin);
    await navigator.clipboard.writeText(url.toString());

    const title = evt.context.target.title ?? evt.context.target.id;
    toast(`Record "${title}" URL copied to clipboard`);
  },
};

export const destroyRecordAction: Action<ModelRecord> = {
  id: "destroyRecord",
  label: "Delete",
  icon: Trash04,
  variant: "danger",
  execute: async (evt, helpers) => {
    return (
      <AlertDialog
        initialOpen
        onDismiss={helpers.cleanup}
        title={`Delete "${evt.context.target.title ?? evt.context.target.id}"`}
        description={`By confirming, the record will be deleted. This action cannot be reversed.`}
        action="Delete"
        onSubmit={() => {
          mutate(
            {
              ...destroyModelRecordMutationOptions(),
              onSuccess() {
                const params = evt.context.matchPath(
                  "/o/:org/w/:app/m/:model/r/:record",
                );
                if (
                  params &&
                  params.model === evt.context.target.model_id &&
                  params.record === evt.context.target.id
                ) {
                  helpers.navigate("/o/:org/w/:app/m/:model", {
                    params: {
                      org: evt.context.target.organization_slug,
                      app: evt.context.target.application_slug,
                      model: evt.context.target.model_id,
                    },
                    replace: true,
                  });
                }
              },
            },
            {
              organizationSlug: evt.context.target.organization_slug,
              modelId: evt.context.target.model_id,
              id: evt.context.target.id,
            },
          );
        }}
      />
    );
  },
};

export const recordActions = [
  favoriteRecordAction,
  unfavoriteRecordAction,
  copyRecordIdAction,
  copyRecordTitleAction,
  copyRecordUrlAction,
];
