import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "@generouted/react-router";
import * as Sentry from "@sentry/react";
import { client } from "api-client";

import "./index.scss";

// Set up API URL for client
client.defaults.baseURL = import.meta.env.VITE_API_URL;

// Set up Sentry
Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: "https://5d5cda9750c9be93db804c7b2837b6af@o4506798023639040.ingest.sentry.io/4506827022336000",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/api\.baselayer\.upstatement\.dev/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Render routes
const router = createBrowserRouter(routes);
createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>,
);
