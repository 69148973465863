import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  AlertCircle,
  Check,
  ChevronSelectorVertical,
  LogOut01,
  Plus,
  Settings02,
} from "@untitled-ui/icons-react";
import {
  Application,
  Organization,
  organizationQueryOptions,
  organizationsApplicationQueryOptions,
  organizationsApplicationsInfiniteQueryOptions,
  queryClient,
  useInfiniteData,
  useOrganizationsApplicationsInfiniteQuery,
} from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { CreateApplicationDialog } from "~/components/_applications/CreateApplicationDialog";
import { AppIcon } from "~/components/AppIcon";
import { Avatar } from "~/components/Avatar";
import { IconButton } from "~/components/IconButton";
import { OrganizationAvatar } from "~/components/OrganizationAvatar";
import { Popover } from "~/components/Popover";
import {
  useCurrentApplication,
  useCurrentOrganization,
  useCurrentUser,
} from "~/lib/current";
import { useSignOutMutation } from "~/mutations";
import { useApplicationSyncRealtime } from "~/realtime/application-sync";
import { Link, useNavigate, useParams } from "~/router";

interface CreateAppDialogProps {
  org: string;
  onSuccess(): void;
}

function CreateAppDialog({
  org,
  children,
  onSuccess,
}: React.PropsWithChildren<CreateAppDialogProps>) {
  const navigate = useNavigate();
  const [createApp, setCreateApp] = useState(false);

  return (
    <CreateApplicationDialog
      open={createApp}
      onOpenChange={setCreateApp}
      trigger={children}
      form={{
        organizationSlug: org,
        onSuccess: (app) => {
          setCreateApp(false);
          navigate("/o/:org/w/:app", {
            params: { org, app: app.slug },
          });
          onSuccess();
        },
      }}
    />
  );
}

interface AppLinkProps {
  org: string;
  app: Application;
  onClose(): void;
}
function AppLink({ org, app: initialData, onClose }: AppLinkProps) {
  const params = useParams("/o/:org/w/:app");
  const applicationQuery = useQuery({
    ...organizationsApplicationQueryOptions({
      organizationSlug: initialData.organization_slug,
      slug: initialData.slug,
    }),
    initialData,
  });
  const app = applicationQuery.data ?? null;
  useApplicationSyncRealtime(app);

  return (
    <Link
      to="/o/:org/w/:app"
      params={{ org, app: app.slug }}
      className={cn(
        "group p-2 gap-2 flex items-center justify-between font-medium text-sm rounded-md",
        "border-t border-transparent",
        "hover:bg-subtle hover:border-action hover:shadow",
      )}
      onClick={onClose}
    >
      <AppIcon application={app} className="w-6 h-6" />
      <div className="flex-1 flex items-center gap-1.5">
        <p>{app.name}</p>
        {app.latest_sync?.state === "failed" && (
          <AlertCircle className="w-4 h-4 text-danger translate-y-px" />
        )}
      </div>
      {params.app === app.slug ? (
        <>
          <span className="sr-only"> (current workspace)</span>{" "}
          <Check className="shrink-0 w-4 h-4 text-icon mr-1" />
        </>
      ) : null}
    </Link>
  );
}

interface AccountDropdownAppsProps {
  org: string;
  onClose: () => void;
}

function AccountDropdownApps({ org, onClose }: AccountDropdownAppsProps) {
  const allAppsQuery = useOrganizationsApplicationsInfiniteQuery({
    organizationSlug: org,
  });
  const apps = useInfiniteData(allAppsQuery.data);

  return apps.length ? (
    <ul className="pb-0.5">
      {apps.map((app) => (
        <li key={app.id}>
          <AppLink org={org} app={app} onClose={onClose} />
        </li>
      ))}
    </ul>
  ) : (
    <div className="p-2">
      <p className="text-sm min-h-6 flex items-center text-placeholder">
        No workspaces
      </p>
    </div>
  );
}

interface AccountDropdownOrgProps {
  organization: Organization;
  onClose: () => void;
}

function AccountDropdownOrg({
  organization: initialData,
  onClose,
}: AccountDropdownOrgProps) {
  const organizationQuery = useQuery({
    ...organizationQueryOptions(initialData),
    initialData,
  });
  const organization = organizationQuery.data ?? initialData;

  return (
    <div className="p-2 flex items-center justify-between">
      <div className="flex items-center">
        <OrganizationAvatar size="sm" organization={organization} />
        <p className="text-secondary text-xs font-medium ml-2">
          {organization.name}
        </p>
      </div>
      <CreateAppDialog org={organization.slug} onSuccess={onClose}>
        <IconButton icon={Plus} accessibilityLabel="New workspace" />
      </CreateAppDialog>
    </div>
  );
}

export function AccountDropdown() {
  const me = useCurrentUser();
  const org = useCurrentOrganization();
  const app = useCurrentApplication();

  const signOut = useSignOutMutation();

  // Prefetch applications for all organizations the user is a member of
  useEffect(() => {
    me?.organization_memberships.forEach((membership) => {
      const query = organizationsApplicationsInfiniteQueryOptions({
        organizationSlug: membership.organization.slug,
      });
      queryClient.prefetchInfiniteQuery(query);
    });
  }, [me]);

  const [open, setOpen] = useState(false);

  return (
    <Popover
      preventAutoFocus
      open={open}
      onOpenChange={setOpen}
      className="w-68"
      align="start"
      side="bottom"
      title="Account"
      trigger={
        <button
          className={cn(
            "w-full flex-1 flex items-center gap-2.5 py-1.5 px-2 rounded-xl",
            "hover:bg-subtle aria-expanded:bg-subtle",
          )}
        >
          <AppIcon application={app} className="w-8 h-8 rounded-lg" />
          <span className="flex flex-col flex-1 overflow-hidden">
            <span className="text-sm font-medium text-left truncate whitespace-pre">
              {app?.name ?? " "}
            </span>
            {!org ? (
              <span className="w-[60%] h-3 mt-1 rounded-md bg-subtle" />
            ) : (
              <span className="text-xs font-medium text-secondary text-left truncate whitespace-pre">
                {org.name}
              </span>
            )}
          </span>
          <ChevronSelectorVertical className="shrink-0 w-3.5 h-3.5 text-icon" />
        </button>
      }
    >
      <ul className="divide-y divide-primary">
        {me?.organization_memberships.map((membership) => (
          <li key={membership.id}>
            <div className="p-2 sm:p-[5px]">
              <AccountDropdownOrg
                organization={membership.organization}
                onClose={() => setOpen(false)}
              />
              <AccountDropdownApps
                org={membership.organization.slug}
                onClose={() => setOpen(false)}
              />
            </div>
          </li>
        ))}
      </ul>

      <hr className="border-primary mx-px" />

      {me ? (
        <div className="p-4 sm:p-3 pb-2 flex items-center justify-between gap-4">
          <div className="flex items-center overflow-hidden">
            <Avatar user={me} />
            <div className="ml-3 truncate font-medium">
              <p className="text-sm leading-tight truncate">{me.full_name}</p>
              <p className="text-xs text-secondary leading-tight truncate">
                {me.email}
              </p>
            </div>
          </div>
          <IconButton
            to="/settings"
            accessibilityLabel="Settings"
            icon={Settings02}
            state={{ redirect: location.pathname }}
          />
        </div>
      ) : null}

      <div className="p-2 sm:p-[5px]">
        <button
          className="group/item w-full text-left rounded-md"
          onClick={() => signOut.mutate({ user_id: "all" })}
        >
          <span
            className={cn(
              "flex-1 flex items-center gap-2 p-2 rounded-md",
              "border-t border-transparent",
              "group-hover/item:bg-subtle group-hover/item:border-action group-hover/item:shadow",
            )}
          >
            <span className="p-1">
              <LogOut01 className="w-4 h-4 text-icon" />
            </span>
            <p className="text-sm font-medium">Sign out</p>
          </span>
        </button>
      </div>
    </Popover>
  );
}
