import { useState } from "react";
import * as HoverCard from "@radix-ui/react-hover-card";
import { ModelRecord } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { formatDateTime, formatTimestamp } from "~/lib/date";

export interface RecordTimestampProps {
  record: ModelRecord;
}

export function RecordTimestamp({ record }: RecordTimestampProps) {
  const [open, setOpen] = useState(false);

  const timestamp = record.updated_at ?? record.created_at;
  if (!timestamp) return null;

  return (
    <HoverCard.Root open={open} onOpenChange={setOpen}>
      <HoverCard.Trigger asChild>
        <p className="max-md:hidden text-sm text-secondary whitespace-nowrap">
          {record.updated_at ? "Updated" : "Created"}{" "}
          <time dateTime={timestamp}>{formatTimestamp(timestamp)}</time>
        </p>
      </HoverCard.Trigger>

      <HoverCard.Portal>
        <HoverCard.Content
          side="bottom"
          align="end"
          sideOffset={6}
          alignOffset={-8}
          className={cn(
            "z-10 dark px-2 py-1.5 bg-main rounded-lg ring-1 ring-inset ring-primary shadow-lg",
            "flex flex-col items-end gap-0.5",
            "data-[state=open]:animate-popover-enter",
            "data-[state=closed]:animate-popover-leave",
            "origin-[--radix-hover-card-content-transform-origin]",
          )}
        >
          {record.created_at && (
            <p className="text-xs">
              Created on{" "}
              <time dateTime={record.created_at}>
                {formatDateTime(record.created_at)}
              </time>
            </p>
          )}
          {record.updated_at &&
            (!record.created_at || record.updated_at !== record.created_at) && (
              <p className="text-xs">
                Updated on{" "}
                <time dateTime={record.updated_at}>
                  {formatDateTime(record.updated_at)}
                </time>
              </p>
            )}
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
