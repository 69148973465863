import { useEffect, useState } from "react";

export const DEFAULT_SCREENS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const CUSTOM_SCREENS = {
  "collapse-sidebar": 1024,
  "collapse-record-sidebar": 1250,
};

export const SCREENS = {
  ...DEFAULT_SCREENS,
  ...CUSTOM_SCREENS,
};

type OnScreenChangeFn = (matches: boolean, previous: boolean) => void;

export function useScreenMatches(
  screen: keyof typeof SCREENS,
  onScreenChange?: OnScreenChangeFn,
) {
  const mediaQuery = `not all and (min-width: ${SCREENS[screen]}px)`;
  const [matches, setMatches] = useState(
    () => window.matchMedia(mediaQuery).matches,
  );

  useEffect(() => {
    function handleChange(event: MediaQueryListEvent) {
      setMatches(event.matches);
      onScreenChange?.(event.matches, matches);
    }

    const mediaQueryList = window.matchMedia(mediaQuery);
    mediaQueryList.addEventListener("change", handleChange);
    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, [mediaQuery, matches, onScreenChange]);

  return matches;
}
