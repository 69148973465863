import { Fragment, useRef } from "react";
import { ChevronDown, XClose } from "@untitled-ui/icons-react";
import { Filter, ModelFieldDateView } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { DatePicker } from "~/components/DateInput";
import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { formatDate, formatDateTime, formatTime } from "~/lib/date";
import { UnionKeys } from "~/types";

const DATE_OPERATORS = {
  eq: (value) => (value ? "on" : "has no value"),
  neq: (value) => (value ? "not on" : "has value"),
  gt: "after",
  gte: "after or on",
  lt: "before",
  lte: "before or on",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<Date>>,
  string | ((value: Date | null) => string)
>;

const DATETIME_OPERATORS = {
  eq: (value) => (value ? "at" : "has no value"),
  neq: (value) => (value ? "not at" : "has value"),
  gt: "after",
  gte: "after or at",
  lt: "before",
  lte: "before or at",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<Date>>,
  string | ((value: Date | null) => string)
>;

export interface DateFieldFilterProps {
  field: ModelFieldDateView;
  value: Filter<Date>;
  onValueChange: (value: Filter<Date>) => void;
  onRemove: () => void;
}

export function DateFieldFilter({
  field,
  value,
  onValueChange,
  onRemove,
}: DateFieldFilterProps) {
  const date = Object.values(value)[0];
  const operator = Object.keys(value)[0] as UnionKeys<typeof value>;

  const operatorsMap =
    field.specificity === "date" ? DATE_OPERATORS : DATETIME_OPERATORS;

  const getOperatorLabel = (
    operator: UnionKeys<typeof value>,
    overrideDate?: Date | null,
  ) => {
    const operatorLabel = operatorsMap[operator];
    return typeof operatorLabel === "function"
      ? operatorLabel(overrideDate !== undefined ? overrideDate : date)
      : operatorLabel;
  };

  // Store last selected date (to keep in memory between `null` values)
  const lastSelectedDateRef = useRef<Date | null>(null);
  if (date) {
    lastSelectedDateRef.current = date;
  }
  const lastSelectedDate = lastSelectedDateRef.current ?? new Date();
  const currentDate = date ?? lastSelectedDate;

  return (
    <div
      className={cn(
        "sm:contents max-sm:border-t first-of-type:border-t-0 border-primary",
        "items-center sm:rounded-xl sm:h-9 py-3 first-of-type:pt-0 last-of-type:pb-0",
        "relative overflow-hidden group/filter",
      )}
    >
      <div className="flex items-center gap-2 pl-1.5 pr-2 sm:max-w-[220px] mr-7 sm:mr-0">
        <Icon
          className="w-4 h-4 text-icon shrink-0"
          name={field.icon}
          fallback={<div className="w-4 h-4 rounded bg-avatar shrink-0" />}
        />
        <p className="text-sm font-medium truncate">
          {field.names.camelized_singular.replace(/\s+(at|on)$/i, "")}
        </p>
      </div>

      <div className="grid sm:contents min-[450px]:grid-cols-2 items-center gap-1 sm:gap-0 mt-2 sm:mt-0">
        <DropdownMenu
          modal
          value={getOperatorLabel(operator)}
          align="start"
          trigger={
            <button
              className={cn(
                "pl-2.5 pr-2 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
                "flex items-center gap-1.5 w-full max-sm:overflow-hidden",
                "data-[state=open]:bg-action-active",
              )}
            >
              <p className="text-sm truncate flex-1 text-left">
                {getOperatorLabel(operator)}
              </p>
              <ChevronDown className="w-4 h-4 text-icon" />
            </button>
          }
          title="Type"
        >
          {field.nullable && (
            <Fragment>
              <DropdownItem onSelect={() => onValueChange({ neq: null })}>
                {getOperatorLabel("neq", null)}
              </DropdownItem>

              <DropdownItem onSelect={() => onValueChange({ eq: null })}>
                {getOperatorLabel("eq", null)}
              </DropdownItem>
            </Fragment>
          )}

          <DropdownItem onSelect={() => onValueChange({ lt: currentDate })}>
            {getOperatorLabel("lt", currentDate)}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ lte: currentDate })}>
            {getOperatorLabel("lte", currentDate)}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ eq: currentDate })}>
            {getOperatorLabel("eq", currentDate)}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ neq: currentDate })}>
            {getOperatorLabel("neq", currentDate)}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ gt: currentDate })}>
            {getOperatorLabel("gt", currentDate)}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ gte: currentDate })}>
            {getOperatorLabel("gte", currentDate)}
          </DropdownItem>
        </DropdownMenu>

        <div className="flex-1 overflow-hidden">
          {date && (
            <DatePicker
              modal
              trigger={
                <button
                  className={cn(
                    "w-full px-2.5 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
                    "flex items-center gap-1.5",
                    "data-[state=open]:bg-action-active",
                  )}
                >
                  <p className="text-sm truncate flex-1 text-left">
                    {field.specificity === "date"
                      ? formatDate(date)
                      : field.specificity === "datetime"
                        ? formatDateTime(date)
                        : formatTime(date)}
                  </p>
                  <ChevronDown className="w-4 h-4 text-icon" />
                </button>
              }
              value={date}
              onValueChange={(value) => {
                if (!value) return;
                onValueChange({ [operator]: value } as Filter<Date>);
              }}
              type={field.specificity}
              title="Date"
            />
          )}
        </div>
      </div>

      <div className="px-0.5 flex items-center max-sm:absolute max-sm:top-2.5 group-first-of-type/filter:max-sm:-top-0.5 max-sm:right-0">
        <IconButton
          icon={XClose}
          variant="subtle"
          accessibilityLabel="Remove filter"
          hideTooltip
          type="button"
          onClick={onRemove}
        />
      </div>
    </div>
  );
}
