import { Button } from "@baselayer/ui/components/Button";

import { OrganizationAvatar } from "~/components/OrganizationAvatar";
import { useCurrentUser } from "~/lib/current";
import { useFlagEnabled } from "~/lib/flags";
import { Navigate } from "~/router";

function Waitlist() {
  const me = useCurrentUser();
  const alphaEnabled = useFlagEnabled("alpha", {
    org: me?.organization_memberships.at(0)?.organization.slug,
  });

  if (!me) {
    return null;
  } else if (!me.display_name) {
    return <Navigate to="/account" replace />;
  } else if (!me.organization_memberships.length) {
    return <Navigate to="/organization" replace />;
  } else if (alphaEnabled) {
    return (
      <Navigate
        to="/o/:org"
        params={{ org: me.organization_memberships[0].organization.slug }}
        replace
      />
    );
  }

  const firstOrg = me.organization_memberships[0].organization;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-start gap-1 text-icon w-full">
        <h1 className="text-xl font-semibold text-primary">All set up!</h1>

        <p className="text-secondary mt-2">
          Your organization{" "}
          <span className="inline font-medium text-primary">
            <span className="inline-flex mr-1.5 w-5 h-[1em] relative">
              <span className="absolute bottom-0 flex translate-y-1">
                <OrganizationAvatar size="xs" organization={firstOrg} />
              </span>
            </span>
            {firstOrg.name}
          </span>{" "}
          has been reserved.
        </p>

        <section className="p-4 bg-panel border border-primary rounded-lg w-full mt-8 flex flex-col gap-0.5">
          <p className="text-primary font-medium">
            Baselayer is currently in a private alpha.
          </p>
          <p className="text-secondary">
            Join our waitlist to apply for early access.
          </p>

          <Button asChild className="mt-2.5" variant="secondary">
            <a href="https://u9t.notion.site/136b0b1f8f2280519907c8ba18fc9287?pvs=105">
              Join waitlist
            </a>
          </Button>
        </section>
      </div>
    </div>
  );
}

export default Waitlist;
