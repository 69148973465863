import { PropsWithChildren, ReactNode, useRef, useState } from "react";
import * as RadixPopover from "@radix-ui/react-popover";

import { cn } from "@baselayer/ui/lib/cn";

import { Dialog } from "~/components/Dialog";
import { useScreenMatches } from "~/lib/breakpoint";

export interface PopoverProps extends PropsWithChildren<unknown> {
  dark?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
  modal?: boolean;
  className?: string;
  trigger?: ReactNode;
  align?: "start" | "center" | "end";
  alignOffset?: number;
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;
  preventAutoFocus?: boolean;
  noMinWidth?: boolean;
  title: string;
  onOpenAutoFocus?(event: Event): void;
}

export function Popover({
  dark = true,
  open,
  onOpenChange,
  modal,
  className,
  children,
  trigger,
  align,
  alignOffset,
  side,
  sideOffset = 4,
  preventAutoFocus,
  noMinWidth,
  title,
  onOpenAutoFocus,
}: PopoverProps) {
  const ref = useRef<HTMLDivElement>(null);

  const isMobile = useScreenMatches("sm");

  const [openState, setOpenState] = useState(open ?? false);
  if (open !== undefined && open !== openState) {
    setOpenState(open);
  }

  const setOpen = (o: boolean) => {
    onOpenChange?.(o);
    setOpenState(o);
  };

  return isMobile ? (
    <Dialog
      open={openState}
      onOpenChange={setOpen}
      title={title}
      trigger={trigger}
      dark={dark}
      onOpenAutoFocus={onOpenAutoFocus}
    >
      {children}
    </Dialog>
  ) : (
    <RadixPopover.Root modal={modal} open={openState} onOpenChange={setOpen}>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          align={align}
          side={side}
          alignOffset={alignOffset}
          sideOffset={sideOffset}
          collisionPadding={12}
          onOpenAutoFocus={(e) => {
            if (preventAutoFocus) {
              e.preventDefault();
              ref.current?.focus();
            }

            if (onOpenAutoFocus) {
              onOpenAutoFocus(e);
            }
          }}
          asChild
        >
          <div
            ref={ref}
            tabIndex={preventAutoFocus ? -1 : undefined}
            className={cn(
              "group/popover",
              "z-50 bg-main rounded-xl shadow focus-visible:focus-outline overflow-auto",
              "max-h-[var(--radix-popover-content-available-height)] data-[side='bottom']:max-h-[calc(var(--radix-popover-content-available-height)-var(--staff-bar,0px))]",
              "max-w-[var(--radix-popover-content-available-width)]",
              {
                "bg-action": !dark,
                "dark bg-main": dark,
              },
              "flex flex-col",
              "data-[state=open]:animate-popover-enter",
              "data-[state=closed]:animate-popover-leave",
              "origin-[--radix-popover-content-transform-origin]",
              !noMinWidth &&
                "min-w-[max(var(--radix-popover-trigger-width),8rem)]",
              className,
            )}
          >
            <div className="absolute inset-0 ring-1 ring-inset ring-primary rounded-xl pointer-events-none" />
            {children}
          </div>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
