import { useEffect, useRef, useState } from "react";

import { OtpField } from "~/components/OtpField";
import { withAuth } from "~/lib/auth";
import { useResendVerificationMutation, useVerifyMutation } from "~/mutations";
import { useNavigate } from "~/router";

function Verify() {
  const NUM_CODE_DIGITS = 6;
  const COUNTDOWN_SECONDS = 60;

  const formRef = useRef<HTMLFormElement>(null);
  const [code, setCode] = useState("");
  const [codeCountdown, setCodeCountdown] = useState(COUNTDOWN_SECONDS);

  const navigate = useNavigate();
  const verify = useVerifyMutation();
  const resendVerification = useResendVerificationMutation();

  useEffect(() => {
    if (codeCountdown === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      setCodeCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [codeCountdown]);

  useEffect(() => {
    if (code.length === NUM_CODE_DIGITS) {
      formRef.current?.requestSubmit();
    }
  }, [code]);

  const resendCode = () => {
    resendVerification.mutate(
      {},
      {
        onSuccess() {
          setCodeCountdown(COUNTDOWN_SECONDS);
          setCode("");
        },
      },
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1 text-icon w-full mb-6">
        <h2 className="text-xl font-semibold text-primary">
          Verify your email
        </h2>
        <p className="text-secondary">
          Enter the verification code we sent to you
        </p>
      </div>

      <div className="w-full">
        <form
          ref={formRef}
          className="flex flex-col gap-4 mb-4"
          onSubmit={(evt) => {
            evt.preventDefault();
            verify.mutate(
              { code },
              {
                onSuccess() {
                  navigate("/", { replace: true });
                },
              },
            );
          }}
        >
          <OtpField
            value={code}
            onValueChange={setCode}
            numInputs={NUM_CODE_DIGITS}
          />

          <button
            type="submit"
            className="sr-only"
            disabled={code.length !== NUM_CODE_DIGITS}
          >
            Submit
          </button>
        </form>
        <div className="mt-11">
          <p className="text-sm text-placeholder inline">
            Didn't receive a code?
          </p>{" "}
          {codeCountdown != 0 ? (
            <p className="text-sm text-secondary inline">
              Try again in {codeCountdown}s
            </p>
          ) : (
            <button className="text-sm text-primary" onClick={resendCode}>
              Resend code
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const ProtectedVerify = withAuth(Verify, { type: "pending_or_authenticated" });

export default ProtectedVerify;
