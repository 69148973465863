import { useOrganizationsApplicationsInfiniteQuery } from "api-client";

import { CreateApplicationForm } from "~/components/_applications/CreateApplicationForm";
import { OnboardingLayout } from "~/pages/(onboarding)/_layout";
import { Navigate, useParams } from "~/router";

function OrganizationDetail() {
  const params = useParams("/o/:org");
  const applicationsQuery = useOrganizationsApplicationsInfiniteQuery({
    organizationSlug: params.org,
  });
  const applications =
    applicationsQuery.data?.pages.flatMap((page) => page.data) ?? [];
  const firstApp = applications.at(0);

  if (applicationsQuery.isPending) return null;

  if (!firstApp) {
    return (
      <OnboardingLayout>
        <div className="flex flex-col">
          <div className="flex flex-col gap-1 text-icon w-full mb-6">
            <h2 className="text-xl font-semibold text-primary">
              Your first application
            </h2>
            <p className="text-secondary">
              Applications are the home for your product data
            </p>
          </div>
        </div>

        <CreateApplicationForm onSuccess={() => {}} />
      </OnboardingLayout>
    );
  }

  return (
    <Navigate
      to="/o/:org/w/:app"
      params={{ org: params.org, app: firstApp.slug }}
      replace
    />
  );
}

export default OrganizationDetail;
