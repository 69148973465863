import React, { useRef, useState } from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import { Menu05 } from "@untitled-ui/icons-react";
import { AnimatePresence, m } from "framer-motion";

import { cn } from "@baselayer/ui/lib/cn";
import { easeOutSmooth } from "@baselayer/ui/lib/motion";

import { IconButton } from "~/components/IconButton";
import { useScreenMatches } from "~/lib/breakpoint";

interface DialogSidebarProps {
  sidebar: React.ReactNode;
}

export function DialogSidebar({ sidebar }: DialogSidebarProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const isVisible = useScreenMatches("collapse-sidebar");

  if (!sidebar || !isVisible) {
    return null;
  }

  return (
    <RadixDialog.Root open={open} onOpenChange={setOpen}>
      <RadixDialog.Trigger asChild>
        <IconButton
          icon={Menu05}
          hideTooltip
          accessibilityLabel="Toggle menu"
          className="ml-1 sm:mr-1"
        />
      </RadixDialog.Trigger>
      <AnimatePresence>
        {open && (
          <RadixDialog.Portal forceMount>
            <RadixDialog.Overlay asChild>
              <m.div
                className="z-50 fixed inset-0 bg-reverse/60"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
                transition={{ ease: easeOutSmooth, duration: 0.2 }}
              />
            </RadixDialog.Overlay>
            <RadixDialog.Content
              ref={contentRef}
              tabIndex={-1}
              asChild
              onOpenAutoFocus={(evt) => {
                evt.preventDefault();
                contentRef.current?.focus();
              }}
            >
              <m.div
                className={cn(
                  "w-[calc(100%-2.5rem)] max-w-sidebar h-full max-h-screen",
                  "fixed top-0 left-0 z-50 shadow-left-sidebar",
                )}
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                exit={{ x: "-100%" }}
                transition={{ duration: 0.2, ease: easeOutSmooth }}
              >
                <RadixDialog.Title className="sr-only">Menu</RadixDialog.Title>
                <div className="size-full">{sidebar}</div>
              </m.div>
            </RadixDialog.Content>
          </RadixDialog.Portal>
        )}
      </AnimatePresence>
    </RadixDialog.Root>
  );
}
