import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import {
  Application,
  createApplicationMutationOptions,
  CreateOrganizationsApplicationParams,
} from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";

import { TextField } from "~/components/TextField";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useParams } from "~/router";

const createApplicationSchema = z.object({
  name: z.string(),
  slug: z.string(),
  visibility: z.enum(["public", "private"]),
  connection_url: z.string(),
  api_key: z.string(),
  organizationSlug: z.string(),
}) satisfies z.ZodSchema<CreateOrganizationsApplicationParams>;

export interface CreateApplicationFormProps {
  onSuccess(application: Application): void;
  organizationSlug?: string;
}

export function CreateApplicationForm({
  onSuccess,
  organizationSlug,
}: CreateApplicationFormProps) {
  const params = useParams("/o/:org");

  const methods = useForm<CreateOrganizationsApplicationParams>({
    defaultValues: {
      name: "",
      slug: "",
      visibility: "public",
      connection_url: import.meta.env.VITE_APP_DEFAULT_CONNECTION_URL || "",
      api_key: "",
      organizationSlug: organizationSlug || params.org,
    },
    resolver: zodResolver(createApplicationSchema),
  });

  const create = useMutation(createApplicationMutationOptions());
  const submit = methods.handleSubmit((data) => {
    create.mutate(data, {
      onSuccess(application) {
        onSuccess(application);
      },
      onError(error) {
        handleFormErrors(error, methods.setError);
      },
    });
  });

  const name = methods.watch("name");
  useEffect(() => {
    if (!name) {
      methods.resetField("slug");
    } else if (!methods.formState.dirtyFields?.slug) {
      methods.setValue("slug", name.replace(/\s/g, "-").toLocaleLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-4" onSubmit={submit}>
        <TextField
          control={methods.control}
          name="name"
          label="Name"
          placeholder="My Workspace"
          autoFocus
        />
        <TextField
          control={methods.control}
          name="slug"
          label="Slug"
          placeholder="my-workspace"
        />
        <TextField
          control={methods.control}
          name="connection_url"
          label="Connection URL"
          type="url"
          placeholder="https://api.example.com/baselayer"
        />
        <TextField
          control={methods.control}
          name="api_key"
          label="API key"
          type="password"
          placeholder="•••••••••••••••"
        />
        <Button size="lg" type="submit">
          Create
        </Button>
      </form>
    </FormProvider>
  );
}
