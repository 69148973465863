import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  ModelRecord,
  organizationsModelQueryOptions,
  organizationsModelsRecordQueryOptions,
  Pagination,
  useInfiniteData,
  useInfiniteQueries,
} from "api-client";

import { ActivityIndicator } from "@baselayer/ui/components/ActivityIndicator";

import {
  favoriteRecordAction,
  recordActions,
  unfavoriteRecordAction,
} from "~/actions/record";
import { RecordPreviewLockup } from "~/components/_forms/RecordPreview";
import { ActionMenu } from "~/components/ActionMenu";
import { ActionIconButton } from "~/components/IconButton";
import { Link } from "~/router";

interface RecordItemProps {
  record: ModelRecord;
}

function RecordItem({ record }: RecordItemProps) {
  const modelQuery = useQuery(
    organizationsModelQueryOptions({
      id: record.model_id,
      organizationSlug: record.organization_slug,
    }),
  );
  const model = modelQuery.data ?? null;

  return (
    <ActionMenu
      as="contextmenu"
      target={record}
      actions={recordActions}
      trigger={
        <div className="relative flex px-3 py-2 gap-3 items-center min-h-[50px] hover:bg-action-active">
          <Link
            className="flex-1 py-1 truncate before:absolute before:inset-0"
            to={`/o/:org/w/:app/m/:model/r/:record`}
            params={{
              org: record.organization_slug,
              app: record.application_slug,
              model: record.model_id,
              record: record.id,
            }}
          >
            <RecordPreviewLockup record={record} model={model} />
          </Link>

          <div className="relative z-10">
            <ActionIconButton
              action={
                record.viewer_favorited_at
                  ? unfavoriteRecordAction
                  : favoriteRecordAction
              }
              target={record}
            />
          </div>
        </div>
      }
    />
  );
}

export interface RecordListProps {
  queryOptions: UseInfiniteQueryOptions<
    Pagination<ModelRecord>,
    Error,
    InfiniteData<Pagination<ModelRecord>>,
    Pagination<ModelRecord>,
    QueryKey,
    number | undefined
  >;
}

export function RecordList({ queryOptions }: RecordListProps) {
  const infiniteQuery = useInfiniteQuery(queryOptions);
  const data = useInfiniteData(infiniteQuery.data);
  const records = useInfiniteQueries(data, (record) =>
    organizationsModelsRecordQueryOptions({
      id: record.id,
      modelId: record.model_id,
      organizationSlug: record.organization_slug,
    }),
  );

  if (!records.length) {
    return (
      <div className="rounded-[13px] flex flex-col bg-action border border-primary divide-y divide-primary">
        <div className="min-h-48 flex items-center justify-center">
          {infiniteQuery.isPending ? (
            <ActivityIndicator className="w-5 h-5 text-placeholder" />
          ) : (
            <p className="text-sm text-placeholder">No records</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <ul className="rounded-[13px] flex flex-col bg-action border border-primary divide-y divide-primary overflow-hidden">
      {records.map((record) => (
        <li key={`${record.model_id}-${record.id}`}>
          <RecordItem record={record} />
        </li>
      ))}
    </ul>
  );
}
