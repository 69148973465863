import { CurrentUser, User } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

const sizeMap = {
  sm: cn("w-6 h-6"),
  md: cn("w-8 h-8"),
  lg: cn("w-11 h-11"),
};

export type AvatarSize = keyof typeof sizeMap;

const textSizeMap: Record<AvatarSize, string> = {
  sm: cn("text-2xs"),
  md: cn("text-xs"),
  lg: cn("text-sm"),
};

export interface AvatarProps {
  size?: AvatarSize;
  user: User | CurrentUser | null;
  disabled?: boolean;
}

export function Avatar({ size = "md", user, disabled }: AvatarProps) {
  const initials = user?.full_name
    .split(" ")
    .slice(0, 2)
    .map((word) => word.at(0))
    .join("")
    .toUpperCase();

  return (
    <span className={cn("shrink-0 rounded-full bg-main", sizeMap[size])}>
      <span
        className={cn(
          "relative w-full h-full flex items-center justify-center bg-avatar rounded-full",
          disabled && "opacity-30",
        )}
      >
        <span
          aria-hidden="true"
          className={cn(
            "relative font-medium text-secondary select-none touch-none",
            textSizeMap[size],
          )}
        >
          {initials}
        </span>

        <span className="absolute inset-0 rounded-full overflow-hidden">
          {user?.avatar_url && (
            <img
              className="w-full h-full object-cover"
              src={user.avatar_url}
              alt=""
            />
          )}
        </span>
      </span>
    </span>
  );
}
