import { useCallback } from "react";
import { Event, ModelRecord } from "api-client";

import { useRealtimeChannel, useRealtimeEvent } from "~/lib/pusher";

type EventTarget = ModelRecord;

export function useEventStream(
  target: EventTarget | null,
  onEvent: (event: Event) => void,
) {
  const channel = useRealtimeChannel(target?.pusher_channel);

  useRealtimeEvent(
    channel,
    "event_create",
    useCallback(
      (event) => {
        onEvent(event.data);
      },
      [onEvent],
    ),
  );
}
