import { createContext } from "react";

interface SidebarContextType {
  sidebar: React.ReactNode;
}

export const SidebarContext = createContext<SidebarContextType>({
  sidebar: null,
});

export const SidebarProvider = ({
  sidebar,
  children,
}: React.PropsWithChildren<SidebarContextType>) => {
  return (
    <SidebarContext.Provider value={{ sidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
