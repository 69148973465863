import { Organization } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

const sizeMap = {
  xs: cn("w-5 h-5 rounded-md"),
  sm: cn("w-6 h-6 rounded-md"),
  md: cn("w-8 h-8 rounded-lg"),
  lg: cn("w-11 h-11 rounded-xl"),
};

export type OrganizationAvatarSize = keyof typeof sizeMap;

const textSizeMap: Record<OrganizationAvatarSize, string> = {
  xs: cn("text-3xs"),
  sm: cn("text-2xs"),
  md: cn("text-xs"),
  lg: cn("text-sm"),
};

export interface OrganizationAvatarProps {
  organization: Organization | null;
  size?: OrganizationAvatarSize;
}

export function OrganizationAvatar({
  organization,
  size = "md",
}: OrganizationAvatarProps) {
  const initials = organization?.name
    .split(" ")
    .slice(0, 2)
    .map((word) => word.at(0))
    .join("")
    .toUpperCase();

  return (
    <span className={cn("shrink-0 bg-main overflow-hidden", sizeMap[size])}>
      <span
        className={cn(
          "relative w-full h-full bg-avatar flex items-center justify-center",
        )}
      >
        {organization?.avatar_url ? (
          <img
            className="absolute inset-0 object-cover"
            src={organization.avatar_url}
            alt=""
          />
        ) : (
          <span
            aria-hidden="true"
            className={cn(
              "relative font-medium text-secondary select-none touch-none",
              textSizeMap[size],
            )}
          >
            {initials}
          </span>
        )}

        <span
          className={cn(
            "absolute inset-0 ring-1 ring-inset ring-black/5",
            sizeMap[size],
          )}
        />
      </span>
    </span>
  );
}
