import { useId, useState } from "react";
import { DayPicker } from "react-day-picker";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import {
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
} from "@untitled-ui/icons-react";
import {
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isValid,
  setHours,
  setMinutes,
} from "date-fns";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { ErrorCode, ErrorMessage } from "./ErrorMessage";
import { Popover } from "./Popover";

import "./DateInput.scss";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { formatDate, formatDateTime } from "~/lib/date";

export interface DatePickerProps {
  modal?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger: React.ReactNode;
  value: string | Date | null;
  onValueChange: (date: Date | null) => void;
  type?: "date" | "datetime" | "time";
  minDate?: Date;
  maxDate?: Date;
  allowNull?: boolean;
  title: string;
}

export function DatePicker({
  modal,
  open: openProp,
  onOpenChange: setOpenProp,
  trigger,
  value,
  onValueChange,
  type,
  minDate,
  maxDate,
  allowNull,
  title,
}: DatePickerProps) {
  const timeInputId = useId();

  const [openState, setOpenState] = useState(false);
  const open = openProp ?? openState;
  const onOpenChange = setOpenProp ?? setOpenState;

  const currentDate =
    value && isValid(new Date(value)) ? new Date(value) : undefined;

  return (
    <Popover
      modal={modal}
      open={open}
      onOpenChange={onOpenChange}
      trigger={trigger}
      className="p-2"
      align="start"
      noMinWidth
      title={title}
    >
      <DayPicker
        mode="single"
        selected={currentDate}
        onSelect={(date) => {
          if (date) {
            if (type === "datetime" && value) {
              const currentTimeHours = getHours(value);
              const currentTimeMins = getMinutes(value);
              const newDate = setHours(
                setMinutes(date, currentTimeMins),
                currentTimeHours,
              );

              onValueChange(newDate);
            } else {
              onValueChange(date);
            }
          }

          if (type === "date") {
            onOpenChange(false);
          }
        }}
        required
        formatters={{
          formatWeekdayName: (date) => format(date, "iii"),
        }}
        showOutsideDays
        captionLayout="dropdown-buttons"
        // fromDate and toDate are required by react-daypicker when using
        // dropdowns. These defaults are a bit awkward, but should support all
        // use cases for now.
        fromDate={minDate ?? new Date(1000, 0, 1)}
        toDate={maxDate ?? new Date(3000, 0, 1)}
        defaultMonth={currentDate ?? new Date()}
        components={{
          IconLeft: (props) => <ChevronLeft {...props} />,
          IconRight: (props) => <ChevronRight {...props} />,
          Dropdown: (props) => (
            <div className="relative">
              <select
                {...props}
                className={cn(
                  props.className,
                  "text-sm font-medium appearance-none px-2.5 py-1.5 pr-7 bg-transparent rounded-lg border-t border-transparent",
                  "hover:bg-subtle hover:border-action hover:shadow",
                )}
              />
              <ChevronDown className="shrink-0 w-4 h-4 text-icon absolute right-1.5 top-1/2 -translate-y-1/2 pointer-events-none" />
            </div>
          ),
        }}
        footer={
          <div className="pt-3">
            {type === "datetime" ? (
              <div className="pt-3 pb-3 sm:pb-1.5 border-t border-primary">
                <div className="px-4 sm:px-1 flex justify-between items-center">
                  <label
                    htmlFor={timeInputId}
                    className="text-sm text-secondary"
                  >
                    Time
                  </label>
                  <input
                    type="time"
                    id={timeInputId}
                    required
                    name="time"
                    className="text-sm bg-transparent [&::-webkit-calendar-picker-indicator]:invert"
                    value={value ? format(value, "HH:mm") : ""}
                    onChange={(e) => {
                      if (e.target.value) {
                        const newDate = new Date(
                          `${format(value || new Date(), "yyyy-MM-dd")}T${
                            e.target.value
                          }`,
                        );
                        onValueChange(newDate);
                      }
                    }}
                  />
                </div>
              </div>
            ) : undefined}

            {allowNull && (
              <div className="flex p-4 pt-1.5 sm:p-0 sm:pt-1.5">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    onValueChange(null);
                    onOpenChange(false);
                  }}
                  disabled={!value}
                  className="flex-1"
                >
                  Clear
                </Button>
              </div>
            )}
          </div>
        }
      />
    </Popover>
  );
}

interface DateInputProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label: string;
  errorMessages?: Partial<Record<ErrorCode, string>>;
  type?: "date" | "datetime";
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  readOnly?: boolean;
  allowNull?: boolean;
}

export function DateInput<T extends FieldValues>({
  name,
  control,
  label,
  errorMessages,
  type = "date",
  minDate,
  maxDate,
  placeholder,
  readOnly,
  allowNull,
}: DateInputProps<T>) {
  const [open, setOpen] = useState(false);
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const id = useId();

  const onChange = (date: Date | null) => {
    if (!date) {
      if (allowNull) field.onChange(null);
      return;
    }

    const value =
      maxDate && isAfter(date, maxDate)
        ? maxDate
        : minDate && isBefore(date, minDate)
          ? minDate
          : date;
    if (type === "date") {
      field.onChange(format(value, "yyyy-MM-dd"));
    } else if (type === "datetime") {
      field.onChange(format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
    }
  };

  return (
    <div className="flex-1 flex flex-col gap-2 group">
      <FieldLabel id={id} readOnly={readOnly}>
        {label}
      </FieldLabel>

      <DatePicker
        open={open}
        onOpenChange={setOpen}
        trigger={
          <button
            aria-describedby={id}
            type="button"
            className={cn(
              "w-full flex justify-between min-w-0 px-3.5 py-2 min-h-11 items-center gap-7",
              "bg-action text-primary rounded-xl ring-1 ring-inset ring-action shadow",
              "enabled:isolate-group-hover:bg-action-active",
              "aria-expanded:ring-action-active aria-expanded:bg-action-active",
              "disabled:text-secondary disabled:bg-action/50",
            )}
            disabled={readOnly}
            aria-readonly={readOnly}
          >
            <span
              className={cn("text-left", !field.value && "text-placeholder")}
            >
              {field.value && type === "date"
                ? formatDate(field.value)
                : field.value && type === "datetime"
                  ? formatDateTime(field.value)
                  : placeholder ?? "Choose date"}
            </span>
            <Calendar
              className={cn("text-icon w-4 h-4 shrink-0", readOnly && "hidden")}
            />
          </button>
        }
        value={field.value}
        onValueChange={onChange}
        type={type}
        minDate={minDate}
        maxDate={maxDate}
        allowNull={allowNull}
        title={label}
      />
      {error && (
        <ErrorMessage label={label} error={error} overrides={errorMessages} />
      )}
    </div>
  );
}
