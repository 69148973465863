import {
  CreateApplicationForm,
  CreateApplicationFormProps,
} from "./CreateApplicationForm";

import { Dialog, DialogProps } from "~/components/Dialog";

interface CreateApplicationDialogProps extends Omit<DialogProps, "title"> {
  form: CreateApplicationFormProps;
}

export function CreateApplicationDialog({
  form,
  ...props
}: CreateApplicationDialogProps) {
  return (
    <Dialog {...props} title="New workspace">
      <div className="p-4">
        <CreateApplicationForm {...form} />
      </div>
    </Dialog>
  );
}
