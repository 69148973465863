import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import {
  updateOrganizationMutationOptions,
  useOrganizationQuery,
} from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";

import { Form } from "~/components/_forms/Form";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { OrganizationAvatar } from "~/components/OrganizationAvatar";
import { TextField } from "~/components/TextField";
import { Titlebar } from "~/components/Titlebar";
import { Link, useParams } from "~/router";

const OrgSettingsFormSchema = z.object({
  org_name: z.string().min(1),
});

type OrgSettingsFormSchema = z.infer<typeof OrgSettingsFormSchema>;

function OrgSettings() {
  const params = useParams("/settings/o/:org");
  const organizationQuery = useOrganizationQuery({ slug: params.org });
  const org = organizationQuery.data ?? null;

  const updateOrgMutation = useMutation(updateOrganizationMutationOptions());

  const defaultValues = useMemo(
    () => ({
      org_name: org?.name || "",
    }),
    [org?.name],
  );

  const methods = useForm<OrgSettingsFormSchema>({
    resolver: zodResolver(OrgSettingsFormSchema),
    defaultValues,
  });

  const preventSubmit =
    !methods.formState.isValid || !methods.formState.isDirty;

  const submit = methods.handleSubmit((data) => {
    if (preventSubmit || updateOrgMutation.isPending) return;
    updateOrgMutation.mutate({
      name: data.org_name,
      slug: params.org,
    });
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="org">
            <Link className="p-1" to="/settings/o/:org" params={params}>
              <div className="flex items-center">
                <OrganizationAvatar size="xs" organization={org} />
                <p className="text-sm font-medium ml-2">{org?.name ?? ""}</p>
              </div>
            </Link>
          </BreadcrumbItem>,
        ]}
      />

      <div className="p-4 pt-1 flex flex-col flex-1 gap-4 w-full max-w-2xl mx-auto">
        <h1 className="font-semibold text-2xl">General</h1>

        <Form onSubmit={submit} className="flex flex-col gap-4">
          <TextField
            name="org_name"
            label="Organization name"
            control={methods.control}
          />
          <Button
            type="submit"
            disabled={preventSubmit}
            busy={updateOrgMutation.isPending}
          >
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default OrgSettings;
