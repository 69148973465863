import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { XClose } from "@untitled-ui/icons-react";
import { createUsersPresignedPost, updateMeMutationOptions } from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { Avatar } from "~/components/Avatar";
import { ErrorMessage } from "~/components/ErrorMessage";
import {
  FileInput,
  FileInputPreview,
  FileInputRemove,
  FileInputTrigger,
} from "~/components/FileInput";
import { TextField } from "~/components/TextField";
import { useCurrentUser } from "~/lib/current";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { uploadFileMutationOptions } from "~/mutations";
import { useNavigate } from "~/router";

const CreateAccountFormSchema = z.object({
  name: z.string(),
  avatar_key: z.string().nullable().optional(),
  avatar_url: z.string().nullable(),
});

type CreateAccountForm = z.infer<typeof CreateAccountFormSchema>;

function CreateAccount() {
  const me = useCurrentUser();

  const {
    formState: { errors },
    setError,
    watch,
    setValue,
    handleSubmit,
    control,
  } = useForm<CreateAccountForm>({
    resolver: zodResolver(CreateAccountFormSchema),
    defaultValues: {
      name: me?.display_name ?? "",
      avatar_key: undefined,
      avatar_url: me?.avatar_url ?? null,
    },
  });

  const navigate = useNavigate();
  const update = useMutation(updateMeMutationOptions());

  const displayName = watch("name");

  const uploadFileMutation = useMutation(uploadFileMutationOptions());

  const onSubmit = handleSubmit(
    async ({ name, avatar_key }: CreateAccountForm) => {
      update.mutate(
        { display_name: name, avatar_key },
        {
          onSuccess() {
            navigate("/organization");
          },
          onError: (error) => handleFormErrors(error, setError),
        },
      );
    },
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1 text-icon w-full mb-6">
        <h2 className="text-xl font-semibold text-primary">
          Tell us about yourself
        </h2>
        <p className="text-secondary">
          Finish setting up your account on Baselayer
        </p>
      </div>

      <div className="w-full">
        <form className="flex flex-col gap-4 mb-4" onSubmit={onSubmit}>
          <div className="flex flex-col gap-2 items-start">
            <FieldLabel htmlFor="avatar">Your avatar</FieldLabel>

            <FileInput
              className="flex items-start gap-2 group/input"
              name="avatar"
              accept={["image"]}
              maxSizeMb={2}
              value={watch("avatar_url")}
              onValueChange={(blob) => {
                if (!blob) {
                  setValue("avatar_key", null, {
                    shouldDirty: true,
                  });
                  setValue("avatar_url", null, {
                    shouldDirty: true,
                  });
                } else {
                  uploadFileMutation.mutate(
                    {
                      file: blob,
                      presign: () =>
                        createUsersPresignedPost({ mime_type: blob.type }),
                    },
                    {
                      onSuccess(key) {
                        setValue("avatar_key", key, {
                          shouldDirty: true,
                        });
                        setValue("avatar_url", URL.createObjectURL(blob), {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      },
                    },
                  );
                }
              }}
              onError={(error) => {
                if (error.code === "file-too-large") {
                  toast.error(`File is too large (max 2MB)`);
                } else if (error.code === "file-invalid-type") {
                  toast.error(`Invalid file type (only *.png, *.jpeg)`);
                }
              }}
            >
              <div className="relative flex rounded-full shadow cursor-pointer">
                <div
                  className={cn(
                    "absolute inset-0 rounded-full ring-1 ring-inset ring-action-active",
                    "opacity-0 group-data-[dragging=true]/input:opacity-100",
                    "pointer-events-none",
                  )}
                />

                <FileInputPreview asChild>
                  {(url) => (
                    <div className="flex group-data-[dragging=true]/input:opacity-50">
                      {me && (
                        <Avatar
                          size="lg"
                          user={{
                            ...me,
                            full_name: displayName || "Johnny",
                            avatar_url: url,
                          }}
                        />
                      )}
                    </div>
                  )}
                </FileInputPreview>

                <FileInputRemove asChild>
                  <button
                    className={cn(
                      "dark absolute -top-1.5 -left-1.5 z-10 bg-main rounded-full p-1",
                      "ring-1 ring-inset ring-primary shadow",
                      "opacity-0 focus:opacity-100 group-hover/input:opacity-100",
                      "group-data-[dragging=true]/input:!opacity-0",
                      "transition-opacity duration-150",
                    )}
                  >
                    <XClose className="text-primary w-2.5 h-2.5 [&_*]:stroke-3" />
                    <span className="sr-only">Remove avatar</span>
                  </button>
                </FileInputRemove>
              </div>

              <div className="flex flex-col items-start gap-1.5">
                <FileInputTrigger asChild>
                  <Button type="button" size="sm" variant="secondary">
                    Upload image
                  </Button>
                </FileInputTrigger>

                <p className="text-xs text-placeholder">
                  *.png and *.jpeg files up to 2MB
                </p>
              </div>
            </FileInput>
          </div>

          <TextField
            id="name"
            name="name"
            label="Display name"
            type="text"
            placeholder="Johnny Appleseed"
            control={control}
          />

          <div className="flex flex-col mt-1">
            <Button size="lg">Continue</Button>
          </div>
        </form>

        {errors.root && <ErrorMessage label="Form" error={errors.root} />}
      </div>
    </div>
  );
}

export default CreateAccount;
