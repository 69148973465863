import { ReactNode, useMemo } from "react";
import {
  EventModelRecordActionView,
  ModelRecordAction,
  useOrganizationsModelsFieldsQuery,
} from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { Avatar } from "~/components/Avatar";
import { Tooltip } from "~/components/Tooltip";
import { useCurrentModel, useCurrentRecord } from "~/lib/current";
import { formatDateTime, formatTimestamp } from "~/lib/date";
import { useParams } from "~/router";

const actionVerbs: Record<ModelRecordAction["action_type"], string> = {
  create: "created",
  update: "updated",
  delete: "deleted",
};

export interface ModelRecordActionCellProps {
  event: EventModelRecordActionView;
}

export function ModelRecordActionCell({ event }: ModelRecordActionCellProps) {
  const params = useParams("/o/:org/w/:app/m/:model/r/:record");
  const model = useCurrentModel();
  const record = useCurrentRecord();

  const fieldsQuery = useOrganizationsModelsFieldsQuery({
    organizationSlug: params.org,
    modelId: params.model,
  });
  const fields = useMemo(() => fieldsQuery.data ?? [], [fieldsQuery.data]);

  const affectedFields = useMemo(() => {
    if (!event.eventable.affected_field_names) return [];
    const fieldMap = new Map(fields.map((field) => [field.field_name, field]));
    return event.eventable.affected_field_names.flatMap(
      (name) => fieldMap.get(name) ?? [],
    );
  }, [event, fields]);

  let message = (
    <span>
      <span className="font-medium">{record?.title ?? record?.id}</span> was{" "}
      {actionVerbs[event.eventable.action_type]}.
    </span>
  );

  if (event.eventable.actor) {
    let subject: ReactNode = model?.names.singular;
    if (event.eventable.affected_field_names.length > 0) {
      subject = (
        <span>
          {affectedFields.map((field, index) => (
            <span key={field.id}>
              <span className="font-medium">{field.names.camelized}</span>
              {index === affectedFields.length - 2 &&
                (affectedFields.length > 2 ? ", and " : " and ")}
              {affectedFields.length > 1 &&
                index < affectedFields.length - 2 &&
                ", "}
            </span>
          ))}{" "}
          field{affectedFields.length > 1 ? "s" : ""}
        </span>
      );
    }
    message = (
      <span>
        <span className="font-medium">
          {event.eventable.actor?.user.full_name}
        </span>{" "}
        {actionVerbs[event.eventable.action_type]} the {subject}.
      </span>
    );
  }

  return (
    <li
      key={event.id}
      className={cn(
        "relative flex items-start gap-2",
        "before:absolute before:left-2.5 before:bottom-0 before:translate-x-px",
        "[&:last-child]:before:opacity-0 before:border-r before:border-primary before:w-px before:rounded-full",
        {
          "before:translate-y-3 before:h-[calc(100%-0.625rem)]":
            !event.eventable.actor,
          "before:translate-y-3 before:h-[calc(100%-1rem)]":
            event.eventable.actor,
        },
      )}
    >
      <div className="shrink-0 w-6 h-6 flex items-center justify-center">
        {event.eventable.actor ? (
          <Avatar size="sm" user={event.eventable.actor.user} />
        ) : (
          <div className="p-1 bg-current text-secondary rounded-full">
            <div className="w-1 h-1 bg-main rounded-full" />
          </div>
        )}
      </div>

      <p className="text-sm leading-normal mt-px">
        {message}
        {"  "}
        <Tooltip label={formatDateTime(event.created_at)}>
          <time
            className="text-secondary whitespace-nowrap"
            dateTime={formatDateTime(event.created_at)}
          >
            {formatTimestamp(event.created_at, {
              capitalize: true,
              addSuffix: false,
            })}
          </time>
        </Tooltip>
      </p>
    </li>
  );
}
