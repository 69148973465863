import { SVGProps } from "react";

export function Google(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.0001 12.2596C23.0001 11.4492 22.9274 10.67 22.7923 9.922H12.0283V14.3478H18.1792C17.909 15.7712 17.0986 16.9763 15.883 17.7867V20.6645H19.5922C21.7533 18.6698 23.0001 15.74 23.0001 12.2596Z"
        fill="#4285F4"
      />
      <path
        d="M12.0281 23.4283C15.1139 23.4283 17.701 22.4101 19.592 20.6647L15.8828 17.7869C14.8646 18.4726 13.5658 18.8882 12.0281 18.8882C9.05659 18.8882 6.53184 16.883 5.62791 14.1818H1.8252V17.1324C3.70577 20.8621 7.56044 23.4283 12.0281 23.4283Z"
        fill="#34A853"
      />
      <path
        d="M5.6281 14.1713C5.39952 13.4856 5.26445 12.7584 5.26445 12C5.26445 11.2416 5.39952 10.5143 5.6281 9.82861V6.87805H1.82538C1.04613 8.41567 0.599365 10.1507 0.599365 12C0.599365 13.8493 1.04613 15.5843 1.82538 17.1219L4.78651 14.8155L5.6281 14.1713Z"
        fill="#FBBC05"
      />
      <path
        d="M12.0281 5.12223C13.7113 5.12223 15.2074 5.70403 16.4023 6.82607L19.6751 3.55344C17.6906 1.70415 15.1139 0.571716 12.0281 0.571716C7.56044 0.571716 3.70577 3.13787 1.8252 6.87802L5.62791 9.82857C6.53184 7.12736 9.05659 5.12223 12.0281 5.12223Z"
        fill="#EA4335"
      />
    </svg>
  );
}
