import { useRef } from "react";
import { ChevronDown, XClose } from "@untitled-ui/icons-react";
import { Filter, ModelFieldManyReferenceView } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { NumberField } from "~/components/NumberField";
import { UnionKeys } from "~/types";

const MANY_REFERENCE_OPERATORS = {
  eq: "equals",
  neq: "not equals",
  gt: "greater than",
  gte: "greater than or equals",
  lt: "less than",
  lte: "less than or equals",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<number>>,
  string | ((value: number | null) => string)
>;

export interface ManyReferenceFieldFilterProps {
  field: ModelFieldManyReferenceView;
  value: Filter<number>;
  onValueChange: (value: Filter<number>) => void;
  onRemove: () => void;
}

export function ManyReferenceFieldFilter({
  field,
  value,
  onValueChange,
  onRemove,
}: ManyReferenceFieldFilterProps) {
  const number = Object.values(value)[0];
  const operator = Object.keys(value)[0] as UnionKeys<typeof value>;

  const getOperatorLabel = (operator: UnionKeys<typeof value>) => {
    return MANY_REFERENCE_OPERATORS[operator];
  };

  // Store last selected number (to keep in memory between `null` values)
  const lastSelectedNumberRef = useRef<number | null>(null);
  if (number !== null) {
    lastSelectedNumberRef.current = number;
  }
  const lastSelectedNumber = lastSelectedNumberRef.current ?? 1;
  const currentNumber = number ?? lastSelectedNumber;

  return (
    <div
      className={cn(
        "sm:contents max-sm:border-t first-of-type:border-t-0 border-primary",
        "items-center sm:rounded-xl sm:h-9 py-3 first-of-type:pt-0 last-of-type:pb-0",
        "relative overflow-hidden group/filter",
      )}
    >
      <div className="flex items-center gap-2 pl-1.5 pr-2 sm:max-w-[220px] mr-7 sm:mr-0">
        <Icon
          className="w-4 h-4 text-icon shrink-0"
          name={field.icon}
          fallback={<div className="w-4 h-4 rounded bg-avatar shrink-0" />}
        />
        <p className="text-sm font-medium truncate">
          {field.names.camelized_plural}
        </p>
      </div>

      <div className="grid sm:contents min-[450px]:grid-cols-2 items-center gap-1 sm:gap-0 mt-2 sm:mt-0">
        <DropdownMenu
          modal
          value={getOperatorLabel(operator)}
          align="start"
          trigger={
            <button
              className={cn(
                "pl-2.5 pr-2 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
                "flex items-center gap-1.5 w-full max-sm:overflow-hidden",
                "data-[state=open]:bg-action-active",
              )}
            >
              <p className="text-sm truncate flex-1 text-left">
                {getOperatorLabel(operator)}
              </p>
              <ChevronDown className="w-4 h-4 text-icon" />
            </button>
          }
          title="Type"
        >
          <DropdownItem onSelect={() => onValueChange({ lt: currentNumber })}>
            {getOperatorLabel("lt")}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ lte: currentNumber })}>
            {getOperatorLabel("lte")}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ eq: currentNumber })}>
            {getOperatorLabel("eq")}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ neq: currentNumber })}>
            {getOperatorLabel("neq")}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ gt: currentNumber })}>
            {getOperatorLabel("gt")}
          </DropdownItem>

          <DropdownItem onSelect={() => onValueChange({ gte: currentNumber })}>
            {getOperatorLabel("gte")}
          </DropdownItem>
        </DropdownMenu>

        <div className="flex-1">
          {number !== null && (
            <NumberField
              label="Filter"
              hideLabel
              size="sm"
              step={1}
              min={0}
              placeholder="value"
              value={currentNumber}
              onValueChange={(value) => {
                onValueChange({ [operator]: value } as any as Filter<number>);
              }}
            />
          )}
        </div>
      </div>

      <div className="px-0.5 flex items-center max-sm:absolute max-sm:top-2.5 group-first-of-type/filter:max-sm:-top-0.5 max-sm:right-0">
        <IconButton
          icon={XClose}
          variant="subtle"
          accessibilityLabel="Remove filter"
          hideTooltip
          type="button"
          onClick={onRemove}
        />
      </div>
    </div>
  );
}
