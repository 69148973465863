import { useMemo } from "react";
import { Lock02 } from "@untitled-ui/icons-react";
import { ApplicationSync } from "api-client";

import { Tooltip } from "~/components/Tooltip";
import { Rails } from "~/svgs/Rails";

export interface SdkLockupProps {
  sync: ApplicationSync;
}

export function SdkLockup({ sync }: SdkLockupProps) {
  const type = "sdk_type" in sync && sync.sdk_type;
  const version = "sdk_version" in sync && sync.sdk_version;

  const name = useMemo(() => {
    switch (type) {
      case "rails": {
        return (
          <span>
            <span className="inline-flex mr-1 w-4 h-4 translate-y-0.5 text-icon-subtle">
              <Rails />
            </span>
            Rails
          </span>
        );
      }
      default: {
        return null;
      }
    }
  }, [type]);

  if (!type || !version) {
    return null;
  }

  return (
    <div className="flex items-center gap-1.5">
      <p className="text-sm text-secondary">
        Synced with{" "}
        <span className="font-medium">
          {name} v{version}
        </span>
      </p>
      {sync.sdk_request_verified && (
        <Tooltip label="Secure connection">
          <div>
            <Lock02 className="w-4 h-4 text-action" />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
