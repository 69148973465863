import toast from "react-hot-toast/headless";
import { generatePath } from "react-router-dom";
import { Copy01, Link01, Star01 } from "@untitled-ui/icons-react";
import {
  favoriteModelMutationOptions,
  Model,
  mutate,
  unfavoriteModelMutationOptions,
} from "api-client";

import { Path } from "~/router";
import { Favorite } from "~/svgs/Favorite";
import { Action } from "~/types";

export const favoriteModelAction: Action<Model> = {
  id: "favoriteModel",
  label: "Favorite",
  icon: Star01,
  applicable: (context) => !context.target.viewer_favorited_at,
  execute: async (evt) => {
    await mutate(favoriteModelMutationOptions(), {
      modelId: evt.context.target.id,
      organizationSlug: evt.context.target.organization_slug,
    });
  },
};

export const unfavoriteModelAction: Action<Model> = {
  id: "unfavoriteModel",
  label: "Unfavorite",
  icon: Favorite,
  applicable: (context) => !!context.target.viewer_favorited_at,
  execute: async (evt) => {
    await mutate(unfavoriteModelMutationOptions(), {
      modelId: evt.context.target.id,
      organizationSlug: evt.context.target.organization_slug,
    });
  },
};

export const copyModelIdAction: Action<Model> = {
  id: "copyModelId",
  group: "copy",
  label: "Copy ID",
  shortcut: "meta+shift+.",
  icon: Copy01,
  execute: async (evt) => {
    const text = evt.context.target.id;
    await navigator.clipboard.writeText(text);
    toast(`"${text}" copied to clipboard`);
  },
};

export const copyModelUrlAction: Action<Model> = {
  id: "copyModelUrl",
  group: "copy",
  label: "Copy URL",
  shortcut: "meta+shift+,",
  icon: Link01,
  execute: async (evt) => {
    const pathname: Path = "/o/:org/w/:app/m/:model";
    const asPath = generatePath(pathname, {
      org: evt.context.target.organization_slug,
      app: evt.context.target.application_slug,
      model: evt.context.target.id,
    });
    const url = new URL(asPath, window.location.origin);
    await navigator.clipboard.writeText(url.toString());
    toast(`Model "${evt.context.target.name}" URL copied to clipboard`);
  },
};

export const modelActions = [
  favoriteModelAction,
  unfavoriteModelAction,
  copyModelIdAction,
  copyModelUrlAction,
];
