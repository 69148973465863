import {
  Children,
  cloneElement,
  forwardRef,
  Fragment,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useRef,
} from "react";
import { useBreadcrumbItem, useBreadcrumbs } from "react-aria";
import { composeRefs } from "@radix-ui/react-compose-refs";
import { DotsHorizontal, Lock02, SlashDivider } from "@untitled-ui/icons-react";
import { Application, Model, ModelRecord } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { applicationActions } from "~/actions/application";
import {
  favoriteModelAction,
  modelActions,
  unfavoriteModelAction,
} from "~/actions/model";
import {
  destroyRecordAction,
  favoriteRecordAction,
  recordActions,
  unfavoriteRecordAction,
} from "~/actions/record";
import { RecordThumbnail } from "~/components/_records/RecordThumbnail";
import { ActionMenu } from "~/components/ActionMenu";
import { AppIcon } from "~/components/AppIcon";
import { Icon } from "~/components/Icon";
import { ActionIconButton, IconButton } from "~/components/IconButton";
import { Slot } from "~/components/Slot";
import { useCurrentModel } from "~/lib/current";
import { Link } from "~/router";

export interface BreadcrumbsProps extends PropsWithChildren {}

export function Breadcrumbs({ children }: BreadcrumbsProps) {
  const { navProps } = useBreadcrumbs({});
  const items = Children.toArray(children).filter(Boolean);
  const count = items.length;

  return (
    <nav {...navProps} className="flex w-full">
      <ol className="flex items-center text-sm font-medium min-h-4 w-full">
        {items.map((child, index) => {
          if (!isValidElement<InternalBreadcrumbItemProps>(child)) {
            return child;
          } else {
            const isCurrent = index === count - 1;
            return cloneElement(child, { isCurrent });
          }
        })}
      </ol>
    </nav>
  );
}

interface InternalBreadcrumbItemProps extends PropsWithChildren {
  className?: string;
  isCurrent?: boolean;
  actions?: ReactNode;
}

export type BreadcrumbItemProps = Omit<
  InternalBreadcrumbItemProps,
  "isCurrent"
>;
export const BreadcrumbItem = forwardRef<
  HTMLLIElement,
  InternalBreadcrumbItemProps
>(function BreadcrumbItem({ actions, children, className, isCurrent }, ref) {
  const liRef = useRef<HTMLLIElement>(null);

  const { itemProps } = useBreadcrumbItem(
    { children: children ?? null, isCurrent },
    liRef,
  );

  return (
    <li
      ref={composeRefs(ref, liRef)}
      className={cn(
        "flex items-center min-w-24 pr-1 [&:not(:last-of-type)]:max-sm:hidden max-sm:w-full py-1",
        className,
      )}
    >
      <Slot
        {...itemProps}
        className="outline-none flex overflow-hidden min-w-0"
      >
        {children}
      </Slot>

      {isCurrent && <div className="ml-1 -my-0.5 flex gap-1.5">{actions}</div>}
      {!isCurrent && (
        <span className="ml-1">
          <SlashDivider className="w-3.5 h-3.5 text-icon-subtle" />
        </span>
      )}
    </li>
  );
});

export interface ApplicationBreadcrumbItemProps {
  application: Application;
}
export function ApplicationBreadcrumbItem({
  application,
  ...props
}: ApplicationBreadcrumbItemProps) {
  return (
    <BreadcrumbItem
      {...props}
      className="shrink-[6]"
      actions={
        <ActionMenu
          trigger={
            <IconButton
              hideTooltip
              icon={DotsHorizontal}
              accessibilityLabel="Actions"
            />
          }
          actions={applicationActions}
          target={application}
        />
      }
    >
      <ActionMenu
        as="contextmenu"
        actions={applicationActions}
        target={application}
        trigger={
          <span className="group/crumb flex overflow-hidden">
            <Link
              className="group/link flex overflow-hidden p-1"
              to="/o/:org/w/:app"
              params={{
                org: application.organization_slug,
                app: application.slug,
              }}
            >
              <span
                className={cn(
                  "flex items-center gap-2",
                  "py-1 pl-1 pr-1.5 rounded-lg",
                  "group-focus-visible/link:focus-outline group-data-[state=open]/crumb:bg-subtle",
                  "overflow-hidden",
                )}
              >
                <AppIcon
                  className="w-5 h-5 shrink-0"
                  application={application}
                />
                <p className="truncate min-w-0">{application.name}</p>
                {application.visibility === "private" && (
                  <Lock02 className={cn("w-3.5 h-3.5 text-icon")} />
                )}
              </span>
            </Link>
          </span>
        }
      />
    </BreadcrumbItem>
  );
}

export interface ModelBreadcrumbItemProps {
  model: Model;
}
export function ModelBreadcrumbItem({
  model,
  ...props
}: ModelBreadcrumbItemProps) {
  return (
    <BreadcrumbItem
      {...props}
      className="-ml-0.5"
      actions={
        <Fragment>
          <ActionIconButton
            action={
              model.viewer_favorited_at
                ? unfavoriteModelAction
                : favoriteModelAction
            }
            target={model}
          />
          <ActionMenu
            trigger={
              <IconButton
                hideTooltip
                icon={DotsHorizontal}
                accessibilityLabel="Actions"
              />
            }
            actions={modelActions}
            target={model}
          />
        </Fragment>
      }
    >
      <ActionMenu
        as="contextmenu"
        actions={modelActions}
        target={model}
        trigger={
          <span className="group/crumb flex overflow-hidden">
            <Link
              className="group/link flex overflow-hidden p-1 pl-1.5"
              to="/o/:org/w/:app/m/:model"
              params={{
                org: model.organization_slug,
                app: model.application_slug,
                model: model.id,
              }}
            >
              <span
                className={cn(
                  "flex items-center gap-2",
                  "py-0.5 pr-0.5 rounded-lg",
                  "group-focus-visible/link:focus-outline group-data-[state=open]/crumb:bg-subtle",
                  "overflow-hidden",
                )}
              >
                <Icon
                  name={model.icon}
                  className="text-icon w-4 h-4 shrink-0"
                />
                <p className="truncate min-w-0">{model.name}</p>
              </span>
            </Link>
          </span>
        }
      />
    </BreadcrumbItem>
  );
}

export interface RecordBreadcrumbItemProps {
  record: ModelRecord;
}
export function RecordBreadcrumbItem({
  record,
  ...props
}: RecordBreadcrumbItemProps) {
  const model = useCurrentModel();
  if (!model) return null;

  return (
    <BreadcrumbItem
      {...props}
      actions={
        model && (
          <Fragment>
            <ActionIconButton
              action={
                record.viewer_favorited_at
                  ? unfavoriteRecordAction
                  : favoriteRecordAction
              }
              target={record}
            />
            <ActionMenu
              trigger={
                <IconButton
                  hideTooltip
                  icon={DotsHorizontal}
                  accessibilityLabel="Actions"
                />
              }
              actions={[...recordActions, destroyRecordAction]}
              target={record}
            />
          </Fragment>
        )
      }
    >
      <ActionMenu
        as="contextmenu"
        actions={[...recordActions, destroyRecordAction]}
        target={record}
        trigger={
          <span className="group/crumb flex overflow-hidden">
            <Link
              className="group/link flex overflow-hidden p-1"
              to="/o/:org/w/:app/m/:model/r/:record"
              params={{
                org: record.organization_slug,
                app: record.application_slug,
                model: record.model_id,
                record: record.id,
              }}
            >
              <span
                className={cn(
                  "flex items-center gap-2",
                  "py-0.5 pr-0.5 rounded-lg",
                  "group-focus-visible/link:focus-outline group-data-[state=open]/crumb:bg-subtle",
                  "overflow-hidden",
                )}
              >
                <RecordThumbnail record={record} model={model} />
                <p className="truncate min-w-0">{record.title ?? record.id}</p>
              </span>
            </Link>
          </span>
        }
      />
    </BreadcrumbItem>
  );
}
