import {
  ComponentType,
  createContext,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  RefAttributes,
  RefObject,
  SVGProps,
  useContext,
  useRef,
  useState,
} from "react";
import { generatePath, useLocation } from "react-router-dom";
import * as Collapsible from "@radix-ui/react-collapsible";
import { ChevronDown } from "@untitled-ui/icons-react";
import { AnimatePresence, m, Reorder } from "framer-motion";
import { createStore, StoreApi, useStore } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { cn } from "@baselayer/ui/lib/cn";
import { easeOutSmooth } from "@baselayer/ui/lib/motion";

import { useScreenMatches } from "~/lib/breakpoint";
import { Link, Params, Path } from "~/router";
import { DragHandle } from "~/svgs/DragHandle";

interface SidebarGroupStore {
  open: boolean;
  setOpen(open: boolean): void;
  reorderable: boolean;
}
interface SidebarGroupStoreOptions {
  name?: string;
  reorderable?: boolean;
}
const createSidebarGroupStore = (options: SidebarGroupStoreOptions) => {
  if (!options.name) {
    return createStore<SidebarGroupStore>((set) => ({
      open: true,
      setOpen: (open) => set({ open }),
      reorderable: options.reorderable ?? false,
    }));
  } else {
    return createStore(
      persist<SidebarGroupStore, [], [], Pick<SidebarGroupStore, "open">>(
        (set) => ({
          open: true,
          setOpen: (open) => set({ open }),
          reorderable: options.reorderable ?? false,
        }),
        {
          name: options.name,
          storage: createJSONStorage(() => localStorage),
          partialize: (state) => ({ open: state.open }),
        },
      ),
    );
  }
};

const SidebarGroupStoreContext = createContext<StoreApi<SidebarGroupStore>>(
  createSidebarGroupStore({}),
);
const SidebarGroupRefContext = createContext<
  RefObject<HTMLUListElement | HTMLOListElement>
>({
  current: null,
});

export interface SidebarItemProps<P extends Path, T>
  extends PropsWithChildren<unknown> {
  className?: string;
  variant?: "primary" | "secondary";
  to?: P;
  params?: P extends keyof Params ? Params[P] : never;
  state?: any;
  onClick?(): void;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  label: string;
  accessory?: ReactNode;
  action?: ReactNode;
  exact?: boolean;
  reorderOptions?: { value: T };
}
const _SidebarItem = forwardRef<HTMLButtonElement, SidebarItemProps<Path, any>>(
  function SidebarItem(
    {
      children,
      variant = "primary",
      label,
      accessory,
      to,
      params,
      state,
      onClick,
      icon: Icon,
      action,
      exact,
      reorderOptions,
      ...props
    },
    ref,
  ) {
    const groupRef = useContext(SidebarGroupRefContext);
    const store = useContext(SidebarGroupStoreContext);
    const reorderable = useStore(store, (state) => state.reorderable);
    const isReorderable = reorderable && !!reorderOptions;

    const location = useLocation();

    const content = (
      <div className={cn("flex items-center gap-2.5 pl-0.5")}>
        <div className="shrink-0 w-4 h-4 flex items-center justify-center text-icon">
          <Icon className="w-full h-full" />
        </div>
        <p
          className={cn(
            "text-sm font-medium truncate",
            variant === "secondary" && "text-secondary",
          )}
        >
          {label}
        </p>
        {accessory}
      </div>
    );

    const pathname = to ? generatePath(to, params as any) : null;
    let isCurrent =
      !!pathname &&
      (exact
        ? location.pathname === pathname
        : location.pathname.startsWith(pathname));
    if (pathname === "/") {
      isCurrent = location.pathname === pathname;
    }

    const Component = isReorderable ? Reorder.Item : m.li;

    const [isDragging, setIsDragging] = useState(false);

    const disableAnimation = useScreenMatches("collapse-sidebar");

    return (
      <Component
        value={reorderOptions?.value}
        {...props}
        layout={disableAnimation ? undefined : "position"}
        className={cn("w-full px-1 group/item", props.className)}
        variants={{
          closed: { opacity: 0, scale: 0.95, height: 0 },
          open: { opacity: 1, scale: 1, height: "auto" },
        }}
        initial={disableAnimation ? "open" : "closed"}
        animate="open"
        exit={disableAnimation ? "open" : "closed"}
        transition={{ duration: 0.2, ease: easeOutSmooth }}
        data-dragging={isDragging}
        dragConstraints={groupRef}
        dragElastic={0.05}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 35 }}
        onDragStart={() => {
          setIsDragging(true);
          document.body.classList.add("[&_*]:cursor-grabbing");
        }}
        onDragEnd={() => {
          setIsDragging(false);
          document.body.classList.remove("[&_*]:cursor-grabbing");
        }}
        onClickCapture={(e) => {
          if (isDragging) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <div
          className={cn(
            "relative flex-1 flex items-center gap-2 p-2 rounded-xl ring-1 ring-inset ring-transparent",
            "[&:has(>[aria-current='page'])]:bg-action [&:has(>[aria-current='page'])]:ring-action [&:has(>[aria-current='page'])]:shadow",
            "group-data-[state=open]/item:bg-subtle",
            "hover:bg-subtle",
            "[&:has(>:focus-visible)]:focus-outline",
            "group-data-[dragging=true]/item:bg-transparent",
            "transition-opacity group-data-[dragging=true]/item:opacity-40",
          )}
        >
          {isReorderable && (
            <DragHandle
              className={cn(
                "absolute -left-3 w-3.5 h-3.5 text-icon",
                "cursor-grab group-data-[dragging=true]/item:opacity-0",
                "opacity-0 group-hover/item:opacity-100",
              )}
            />
          )}

          {to ? (
            <Link
              ref={ref}
              className="flex-1 truncate before:absolute before:inset-0 focus-visible:outline-none"
              {...({ to, params, state } as any)}
              aria-current={isCurrent ? "page" : undefined}
              draggable={false}
              onClick={onClick}
            >
              {content}
            </Link>
          ) : (
            <button
              ref={ref}
              className="flex-1 truncate before:absolute before:inset-0 focus-visible:outline-none"
              onClick={onClick}
              draggable={false}
            >
              {content}
            </button>
          )}

          <div className="relative shrink-0 flex z-10 pr-px group-data-[dragging=true]/item:opacity-0">
            {action}
          </div>
        </div>
        {children}
      </Component>
    );
  },
);
export const SidebarItem = _SidebarItem as <P extends Path, T>(
  props: SidebarItemProps<P, T> & RefAttributes<HTMLLIElement>,
) => ReactElement;

interface SidebarGroupProps<T> extends PropsWithChildren<unknown> {
  className?: string;
  title?: string;
  action?: ReactNode;
  collapsible?: boolean;
  reorderOptions?: {
    values: T[];
    onReorder(values: T[]): void;
  };
}

export function SidebarGroup<T>({
  className,
  title,
  action,
  children,
  collapsible,
  reorderOptions,
}: SidebarGroupProps<T>) {
  const [store] = useState(() =>
    createSidebarGroupStore({ name: title, reorderable: !!reorderOptions }),
  );
  const { open, setOpen } = useStore(store);

  const ref = useRef<HTMLUListElement | HTMLOListElement>(null);

  const disableAnimation = useScreenMatches("collapse-sidebar");

  return (
    <SidebarGroupRefContext.Provider value={ref}>
      <SidebarGroupStoreContext.Provider value={store}>
        <Collapsible.Root open={open} onOpenChange={setOpen} asChild>
          <div
            className={cn("py-1.5 w-full flex flex-col bg-panel", className)}
          >
            {title && (
              <m.div
                layout={!disableAnimation}
                transition={{ duration: 0.2, ease: easeOutSmooth }}
                className="pl-2 pr-1 pb-1.5 group flex items-center justify-between"
              >
                {collapsible ? (
                  <Collapsible.Trigger asChild>
                    <button
                      className={cn(
                        "flex items-center gap-1.5 py-1.5 pl-2 pr-1.5 -mx-1.5 -my-1 rounded-lg",
                        "focus-visible:focus-outline",
                      )}
                    >
                      <span className="text-xs font-medium text-secondary">
                        {title}
                      </span>

                      <AnimatePresence initial={false}>
                        <m.span
                          animate={{ rotate: open ? 0 : -90 }}
                          transition={{ duration: 0.2, ease: easeOutSmooth }}
                        >
                          <ChevronDown className="w-3 h-3 text-icon [&_path]:stroke-[2.25px]" />
                        </m.span>
                      </AnimatePresence>
                    </button>
                  </Collapsible.Trigger>
                ) : (
                  <p className="py-0.5 pl-0.5 text-xs font-medium text-secondary">
                    {title}
                  </p>
                )}

                {action}
              </m.div>
            )}

            <Collapsible.Content className="data-[state=open]:animate-expand data-[state=closed]:animate-collapse">
              <AnimatePresence initial={false}>
                {reorderOptions ? (
                  <Reorder.Group
                    ref={ref}
                    axis="y"
                    as="ol"
                    values={reorderOptions.values}
                    onReorder={reorderOptions.onReorder}
                    className="flex-1 flex flex-col gap-0.5 pb-2 group/list"
                  >
                    {children}
                  </Reorder.Group>
                ) : (
                  <ul
                    ref={ref}
                    className="flex-1 flex flex-col gap-0.5 pb-2 group/list"
                  >
                    {children}
                  </ul>
                )}
              </AnimatePresence>
            </Collapsible.Content>
          </div>
        </Collapsible.Root>
      </SidebarGroupStoreContext.Provider>
    </SidebarGroupRefContext.Provider>
  );
}
