import { useState } from "react";
import { Plus } from "@untitled-ui/icons-react";
import { useOrganizationQuery } from "api-client";

import { Button } from "@baselayer/ui/components/Button";

import { InviteForm } from "~/components/_settings/InviteForm";
import { InviteList } from "~/components/_settings/InviteList";
import { MemberList } from "~/components/_settings/MemberList";
import { BreadcrumbItem } from "~/components/Breadcrumbs";
import { Dialog } from "~/components/Dialog";
import { OrganizationAvatar } from "~/components/OrganizationAvatar";
import { Titlebar } from "~/components/Titlebar";
import { Link, useParams } from "~/router";

function OrgSettings() {
  const params = useParams("/settings/o/:org/members");
  const organizationQuery = useOrganizationQuery({ slug: params.org });
  const org = organizationQuery.data ?? null;
  const [isInviteOpen, setIsInviteOpen] = useState(false);

  return (
    <div className="flex flex-col flex-1">
      <Titlebar
        breadcrumbs={[
          <BreadcrumbItem key="org">
            <Link className="p-1" to="/settings/o/:org" params={params}>
              <div className="flex items-center">
                <OrganizationAvatar size="xs" organization={org} />
                <p className="text-sm font-medium ml-2">{org?.name ?? ""}</p>
              </div>
            </Link>
          </BreadcrumbItem>,
          <BreadcrumbItem key="members">
            <Link className="p-1" to="/settings/o/:org/members" params={params}>
              Members
            </Link>
          </BreadcrumbItem>,
        ]}
      />

      <div className="p-4 pt-1 flex flex-col flex-1 gap-4 w-full max-w-2xl mx-auto">
        <div className="flex justify-between gap-x-6 gap-y-3 flex-wrap">
          <h1 className="font-semibold text-2xl">Members</h1>
          <Dialog
            open={isInviteOpen}
            onOpenChange={setIsInviteOpen}
            title="Invite people"
            trigger={
              <Button size="sm" variant="secondary" icon={Plus}>
                Invite people
              </Button>
            }
          >
            <div className="p-4">
              <InviteForm
                org={params.org}
                onSuccess={() => {
                  setIsInviteOpen(false);
                }}
              />
            </div>
          </Dialog>
        </div>

        <div className="flex flex-col gap-4">
          <MemberList org={params.org} />

          <InviteList org={params.org} />
        </div>
      </div>
    </div>
  );
}

export default OrgSettings;
