import { ReactNode, useCallback, useRef, useState } from "react";
import {
  DataTag,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { flexRender } from "@tanstack/react-table";
import { CubeOutline, DotsVertical } from "@untitled-ui/icons-react";
import { Model, ModelRecord, Pagination } from "api-client";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { destroyRecordAction, recordActions } from "~/actions/record";
import { useRecordTable } from "~/components/_records/RecordTable/data";
import { RecordFilters } from "~/components/_records/RecordTable/Filters";
import { RecordTablePagination } from "~/components/_records/RecordTable/Pagination";
import { ActionMenu } from "~/components/ActionMenu";
import { IconButton } from "~/components/IconButton";
import { Wrap } from "~/components/Wrap";

interface RoundedBorderProps {
  className?: string;
  bgColor?: "panel" | "main";
  size?: "10px" | "13px";
}

function RoundedBorder({
  className,
  bgColor,
  size = "10px",
}: RoundedBorderProps) {
  return size === "10px" ? (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden
    >
      <path
        d="M10.0039 9.5C4.75216 9.5 0.50349 5.2563 0.503904 0.00647018L0.5 9.5H10.0039Z"
        className={
          bgColor === "panel"
            ? "fill-[rgb(var(--bg-panel))] stroke-[rgb(var(--bg-panel))]"
            : "fill-[rgb(var(--bg-main))] stroke-[rgb(var(--bg-main))]"
        }
      />
      <path
        d="M0.503906 0C0.5 5.25281 4.75 9.5 10.0039 9.5H0.5L0.503906 0Z"
        className={
          bgColor === "panel"
            ? "fill-[rgb(var(--bg-panel))] stroke-[rgb(var(--bg-panel))]"
            : "fill-[rgb(var(--bg-main))] stroke-[rgb(var(--bg-main))]"
        }
      />
      <path
        d="M0.5 0C0.5 5 4.5 9.5 10 9.5"
        className="stroke-[rgb(var(--border-action))]"
      />
    </svg>
  ) : (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden
    >
      <path
        d="M13 12.5C6 12.5 0.50001 7 0.5 0L0.500017 12.5H13Z"
        className={
          bgColor === "panel"
            ? "fill-[rgb(var(--bg-panel))] stroke-[rgb(var(--bg-panel))]"
            : "fill-[rgb(var(--bg-main))] stroke-[rgb(var(--bg-main))]"
        }
      />
      <path
        d="M0.5 0C0.5 7 6 12.5 13 12.5"
        className="stroke-[rgb(var(--border-action))]"
      />
    </svg>
  );
}

interface TableHeaderProps {
  isEmpty: boolean;
  variant?: "default" | "contained";
  className?: string;
}

function TableHeader({
  isEmpty,
  variant,
  className,
  children,
}: React.PropsWithChildren<TableHeaderProps>) {
  return (
    <th className={cn("group/cell p-0", className)}>
      <div
        className={cn(
          "h-full px-3.5 pb-2 pt-1.5 flex flex-row items-center gap-2 relative",
          "text-left text-secondary font-medium text-sm",
          !isEmpty && "border-b border-action",
          {
            "bg-main": variant !== "contained",
            "bg-panel pt-2.5 shadow-[inset_0_1px_0_rgb(var(--border-action))]":
              variant === "contained",
          },
        )}
      >
        {children}

        <RoundedBorder
          className="absolute top-full left-0 rotate-90 hidden group-first-of-type/cell:inline"
          bgColor={variant === "contained" ? "panel" : "main"}
        />
        <RoundedBorder
          className="absolute top-full right-0 rotate-180 hidden group-last-of-type/cell:inline"
          bgColor={variant === "contained" ? "panel" : "main"}
        />

        {variant === "contained" ? (
          <>
            <RoundedBorder
              className="absolute top-0 -left-1 rotate-90 hidden group-first-of-type/cell:inline"
              bgColor="main"
              size={variant === "contained" ? "13px" : "10px"}
            />
            <RoundedBorder
              className="absolute top-0 -right-1 rotate-180 hidden group-last-of-type/cell:inline"
              bgColor="main"
              size={variant === "contained" ? "13px" : "10px"}
            />
          </>
        ) : null}
      </div>
    </th>
  );
}

interface TableCellProps {
  isPending?: boolean;
  className?: string;
}

function TableCell({
  isPending,
  className,
  children,
}: React.PropsWithChildren<TableCellProps>) {
  return (
    <td className={cn("p-0 overflow-hidden", className)}>
      <div
        className={cn(
          "overflow-hidden flex items-center",
          "h-full min-h-[50px]",
          "border-action border-b",
          "bg-action group-data-[state=open]/row:bg-action-active",
          !isPending &&
            "group-[:hover:not(:has([data-cell-menu]:hover))]/row:bg-action-active",
        )}
      >
        {children}
      </div>
    </td>
  );
}

export interface RecordTableProps {
  variant?: "default" | "contained";
  record?: ModelRecord | null;
  models: Model[];
  queryOptions: UseInfiniteQueryOptions<
    Pagination<ModelRecord>,
    Error,
    InfiniteData<Pagination<ModelRecord>>,
    Pagination<ModelRecord>,
    QueryKey,
    number | undefined
  > & {
    queryKey: DataTag<QueryKey, InfiniteData<Pagination<ModelRecord>>>;
    enabled?: boolean;
  };
  renderTitleCell(args: {
    record: ModelRecord;
    className: string;
    children: ReactNode;
  }): ReactNode;
  actions?: ReactNode;
  where?: Record<string, any>;
  onWhereChange?(where: Record<string, any>): void;
  page?: number;
  onPageChange?(page: number | ((current: number) => number)): void;
  inDialog?: boolean;
  readOnly?: boolean;
}

export function RecordTable({
  variant = "default",
  record,
  models,
  queryOptions,
  renderTitleCell,
  actions,
  where,
  onWhereChange,
  page: propsPage,
  onPageChange,
  inDialog,
  readOnly,
}: RecordTableProps) {
  const bodyRef = useRef<HTMLTableSectionElement>(null);

  const rowActions = readOnly
    ? recordActions
    : [...recordActions, destroyRecordAction];

  const [statePage, setStatePage] = useState(1);
  const page = propsPage ?? statePage;
  const setPage = onPageChange ?? setStatePage;
  const onPageParamChange = useCallback(
    (updater: number | ((current: number) => number)) => {
      setPage(updater);
      bodyRef.current?.scrollTo({ top: 0, behavior: "auto" });
    },
    [setPage],
  );

  const [table, isPending] = useRecordTable({
    pendingCount: variant === "contained" ? 10 : 25,
    record,
    models,
    queryOptions,
    pageParam: page,
    onPageParamChange,
  });
  const isEmpty = !isPending && table.getRowModel().rows.length === 0;

  return (
    <div
      className={cn(
        "flex-1 flex flex-col relative",
        inDialog && "min-h-[80vh]",
      )}
    >
      <div
        className={cn(
          "flex flex-col flex-1 overflow-hidden pt-1",
          variant === "default" && "absolute inset-0",
        )}
      >
        <div className="flex-1 max-h-full flex flex-col gap-2.5">
          <div
            className={cn(
              "flex items-start justify-between gap-3 flex-wrap",
              "px-4",
              variant === "contained" && "w-full max-w-2xl mx-auto",
            )}
          >
            <RecordFilters
              models={models}
              where={where}
              onWhereChange={onWhereChange}
            />

            {actions}
          </div>

          <div
            className={cn(
              "flex flex-col w-full flex-1 relative",
              variant === "contained" &&
                "max-w-2xl mx-auto rounded-[13px] overflow-hidden",
            )}
          >
            {variant === "contained" && (
              <div className="absolute inset-y-0 inset-x-4 rounded-[13px] border border-action z-30 pointer-events-none" />
            )}

            <div
              className={cn(
                "flex flex-col flex-1 relative",
                variant === "default" && "absolute inset-0",
                variant === "contained" && "px-4",
              )}
            >
              <div
                className={cn(
                  "flex flex-1 overflow-auto",
                  variant === "default" && "px-4",
                  variant === "contained" && "rounded-[13px]",
                )}
              >
                <div
                  className={cn(
                    "flex flex-col flex-1 bg-panel relative",
                    "shadow-[inset_1px_0_0_rgb(var(--border-action)),inset_-1px_0_0_rgb(var(--border-action)),inset_0_-1px_0_rgb(var(--border-action))]",
                    variant === "contained" && "shadow-none rounded-[13px]",
                  )}
                >
                  <div
                    className={cn(
                      "flex flex-col w-full",
                      variant === "contained" &&
                        "px-1 bg-panel shadow-[inset_1px_0_0_rgb(var(--border-action)),inset_-1px_0_0_rgb(var(--border-action))]",
                      variant === "contained" && !isEmpty && "pb-1",
                      !isEmpty && "flex-1",
                    )}
                  >
                    <table className={cn("w-full", !isEmpty && "h-full")}>
                      <thead className="sticky top-0 z-20">
                        {table.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id} className="flex w-full">
                            {headerGroup.headers.map((header, index) => (
                              <TableHeader
                                key={header.id}
                                isEmpty={isEmpty}
                                variant={variant}
                                className={cn(
                                  "flex-1 w-48",
                                  index === 0 && !isPending && "min-w-64",
                                )}
                              >
                                <div className="flex-1 truncate">
                                  {header.isPlaceholder ? (
                                    <div className="w-full h-4 rounded-md bg-subtle" />
                                  ) : (
                                    flexRender(
                                      header.column.columnDef.header,
                                      header.getContext(),
                                    )
                                  )}
                                </div>
                              </TableHeader>
                            ))}
                            <TableHeader
                              className="w-12"
                              isEmpty={isEmpty}
                              variant={variant}
                            />
                          </tr>
                        ))}
                      </thead>

                      <tbody
                        ref={bodyRef}
                        className={cn(
                          "w-full flex flex-col flex-1",
                          "border-x border-action",
                          isEmpty && "invisible h-0 flex-none overflow-hidden",
                        )}
                      >
                        {table.getRowModel().rows.map((row, i) => (
                          <Wrap
                            key={row.id + i}
                            condition={!isPending}
                            wrap={(children) => (
                              <ActionMenu
                                as="contextmenu"
                                target={row.original}
                                actions={rowActions}
                                trigger={children}
                              />
                            )}
                          >
                            <tr className="group/row flex w-full relative">
                              {row.getVisibleCells().map((cell, index) => (
                                <TableCell
                                  key={cell.id}
                                  className={cn(
                                    "flex-1 w-48",
                                    index === 0 && !isPending && "min-w-64",
                                  )}
                                  isPending={isPending}
                                >
                                  <div className="p-3.5 flex-1 flex overflow-hidden text-ellipsis whitespace-nowrap">
                                    <div className="flex truncate has-[:focus-visible]:focus-outline">
                                      <Wrap
                                        condition={index === 0 && !isPending}
                                        wrap={(children) =>
                                          renderTitleCell({
                                            record: row.original,
                                            className:
                                              "before:content-[''] before:absolute before:inset-0 before:z-10 truncate",
                                            children,
                                          })
                                        }
                                      >
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext(),
                                        )}
                                      </Wrap>
                                    </div>
                                  </div>
                                </TableCell>
                              ))}

                              <TableCell
                                className="w-12 shrink-0"
                                isPending={isPending}
                              >
                                <div className="w-full flex items-center justify-center">
                                  {isPending ? (
                                    <div className="w-7 h-4 rounded-md bg-subtle" />
                                  ) : (
                                    <div className="z-10" data-cell-menu>
                                      <ActionMenu
                                        as="dropdown"
                                        target={row.original}
                                        actions={rowActions}
                                        align="end"
                                        trigger={
                                          <IconButton
                                            hideTooltip
                                            icon={DotsVertical}
                                            accessibilityLabel="Actions"
                                          />
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                            </tr>
                          </Wrap>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {isEmpty && (
                    <div
                      className={cn(
                        "flex flex-col flex-1",
                        variant === "contained" &&
                          "px-1 bg-panel shadow-[inset_1px_0_0_rgb(var(--border-action)),inset_-1px_0_0_rgb(var(--border-action))]",
                      )}
                    >
                      <div
                        className={cn(
                          "w-full flex flex-col flex-1",
                          "border border-action bg-panel",
                          variant === "contained" &&
                            "max-h-[25rem] bg-main shadow",
                        )}
                      >
                        <div className="min-h-48 w-full flex-1 flex flex-col gap-2.5 items-center justify-center">
                          <CubeOutline className="text-icon-subtle w-6 h-6" />
                          <div className="text-center text-placeholder text-sm">
                            No results
                          </div>

                          {where && Object.keys(where).length > 0 && (
                            <Button
                              className="mt-0.5 text-secondary"
                              size="xs"
                              variant="subtle"
                              onClick={() => onWhereChange?.({})}
                            >
                              Clear filters
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="sticky inset-x-0 bottom-0 z-10">
                    {variant === "contained" ? (
                      <>
                        <div className="absolute bottom-full inset-x-0 bg-panel h-1" />
                        <div className="absolute bottom-1 inset-x-0 [background-color:rgb(var(--border-action))] h-px" />

                        <RoundedBorder
                          className="absolute left-1 bottom-1"
                          bgColor="panel"
                        />
                        <RoundedBorder
                          className="absolute right-1 bottom-1 -rotate-90"
                          bgColor="panel"
                        />
                      </>
                    ) : null}

                    <div className="absolute bottom-full inset-x-0 [background-color:rgb(var(--border-action))] h-px" />
                    <RoundedBorder
                      className="absolute left-0 bottom-full"
                      bgColor="main"
                      size={variant === "contained" ? "13px" : "10px"}
                    />
                    <RoundedBorder
                      className="absolute right-0 bottom-full -rotate-90"
                      bgColor="main"
                      size={variant === "contained" ? "13px" : "10px"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={cn(
              "flex justify-end px-4 pb-1",
              variant === "contained" && "w-full max-w-2xl mx-auto",
            )}
          >
            <RecordTablePagination table={table} />
          </div>
        </div>
      </div>
    </div>
  );
}
