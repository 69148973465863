import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import {
  ModelField,
  ModelRecord,
  organizationsModelQueryOptions,
} from "api-client";
import { isValid } from "date-fns";

import { cn } from "@baselayer/ui/lib/cn";

import { useRecordFields } from "~/components/_forms/RecordForm/hook";
import { DateInput } from "~/components/DateInput";
import { ManyReferenceField } from "~/components/ManyReferenceField";
import { NumberField } from "~/components/NumberField";
import { OneReferenceField } from "~/components/OneReferenceField";
import { Select } from "~/components/Select";
import { TextField } from "~/components/TextField";
import { ToggleField } from "~/components/Toggle";

export interface RecordFieldsProps {
  parentField?: ModelField;
  record: ModelRecord | null;
}

export function RecordFields({ parentField, record }: RecordFieldsProps) {
  const methods = useFormContext<ModelRecord>();

  const modelQuery = useQuery({
    ...organizationsModelQueryOptions({
      organizationSlug: record?.organization_slug ?? "",
      id: record?.model_id ?? "",
    }),
    enabled: !!record,
  });
  const model = modelQuery.data ?? null;

  const fields = useRecordFields(model, record);

  return (
    <div className="flex flex-col items-start gap-4 flex-1">
      {fields.map((field) => {
        const name = `data.${field.field_name}` as const;
        const value = methods.watch(name);
        const isManyReferenceField =
          field.field_type === "reference" &&
          field.reference_type === "to_many";

        return (
          <div
            key={field.id}
            className={cn(
              "flex flex-col gap-2 w-full",
              !isManyReferenceField && "px-4 max-w-2xl mx-auto",
            )}
          >
            {field.field_type === "string" &&
              (field.enum_options.length > 0 ? (
                <Select
                  label={field.name}
                  options={field.enum_options.map((option) => option.value)}
                  value={value}
                  onValueChange={(value) =>
                    methods.setValue(name, value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  readOnly={field.read_only}
                />
              ) : (
                <TextField
                  control={methods.control}
                  name={name}
                  label={field.name}
                  readOnly={field.read_only}
                />
              ))}

            {field.field_type === "number" && (
              <NumberField
                name={field.field_name}
                label={field.name}
                value={value}
                onValueChange={(value) =>
                  methods.setValue(name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                min={field.min ?? undefined}
                max={field.max ?? undefined}
                step={field.step ?? undefined}
                readOnly={field.read_only}
              />
            )}

            {field.field_type === "boolean" && (
              <ToggleField
                value={value}
                label={field.name}
                onValueChanged={(value) =>
                  methods.setValue(name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                readOnly={field.read_only}
              />
            )}

            {field.field_type === "date" && (
              <Fragment>
                {(field.specificity === "date" ||
                  field.specificity === "datetime") && (
                  <DateInput
                    label={field.name}
                    name={name}
                    type={field.specificity}
                    control={methods.control}
                    minDate={
                      field.min && isValid(new Date(field.min))
                        ? new Date(field.min)
                        : undefined
                    }
                    maxDate={
                      field.max && isValid(new Date(field.max))
                        ? new Date(field.max)
                        : undefined
                    }
                    readOnly={field.read_only}
                    allowNull={!field.nullable}
                  />
                )}
                {/* TODO: time only */}
              </Fragment>
            )}

            {field.field_type === "reference" &&
              field.reference_type === "to_one" && (
                <OneReferenceField
                  field={field}
                  name={name}
                  control={methods.control}
                  readOnly={
                    field.read_only ||
                    (parentField && field.inverse_of?.id === parentField?.id)
                  }
                />
              )}

            {isManyReferenceField && (
              <ManyReferenceField field={field} record={record} />
            )}

            {/* TODO: JSON editor */}
            {field.field_type === "json" && (
              <code>
                <pre>{JSON.stringify(value, null, 2)}</pre>
              </code>
            )}
          </div>
        );
      })}
    </div>
  );
}
