import { Fragment, PropsWithChildren } from "react";
import { m } from "framer-motion";

import { easeOutExpo } from "@baselayer/ui/lib/motion";

export interface ActionsBarProps extends PropsWithChildren {
  hidden?: boolean;
}

export function ActionsBar({ hidden, children }: ActionsBarProps) {
  return (
    <Fragment>
      <div className="h-20" />

      <div className="sticky inset-x-0 bottom-staff-bar pb-4 flex justify-center pointer-events-none origin-bottom overflow-hidden z-50">
        <m.div
          className="reverse pointer-events-auto bg-action border border-action p-1.5 rounded-xl shadow"
          initial={{
            opacity: !hidden ? 1 : 0,
            y: !hidden ? 0 : "150%",
            scale: !hidden ? 1 : 0.6,
            filter: !hidden ? "blur(0px)" : "blur(4px)",
          }}
          animate={{
            opacity: !hidden ? 1 : 0,
            y: !hidden ? 0 : "150%",
            scale: !hidden ? 1 : 0.6,
            filter: !hidden ? "blur(0px)" : "blur(4px)",
          }}
          transition={{ duration: 0.4, ease: easeOutExpo }}
        >
          {children}
        </m.div>
      </div>
    </Fragment>
  );
}
