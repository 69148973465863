import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";

import { ErrorMessage } from "~/components/ErrorMessage";
import { TextField } from "~/components/TextField";
import { withAuth } from "~/lib/auth";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { useForgotPasswordMutation } from "~/mutations";
import { Link } from "~/router";
import { Logo } from "~/svgs/Logo";

const ForgotPasswordFormSchema = z.object({
  email: z.string().email("Invalid email address"),
});

type ForgotPasswordForm = z.infer<typeof ForgotPasswordFormSchema>;

function ForgotPassword() {
  const {
    formState: { errors },
    setError,
    handleSubmit,
    control,
  } = useForm<ForgotPasswordForm>({
    resolver: zodResolver(ForgotPasswordFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const [showEmailSent, setShowEmailSent] = useState(false);
  const forgotPassword = useForgotPasswordMutation();

  const onSubmit = handleSubmit(async (data: ForgotPasswordForm) => {
    forgotPassword.mutate(data, {
      onSuccess() {
        setShowEmailSent(true);
      },
      onError: (error) => handleFormErrors(error, setError),
    });
  });

  return (
    <div className="p-4 py-12 gap-4 flex-1 flex flex-col items-center justify-center">
      <div className="flex flex-col gap-1 items-center text-center w-full max-w-lg">
        <div className="w-8 aspect-square text-icon-subtle mb-2.5">
          <Logo />
        </div>
        <h1 className="text-xl font-semibold text-primary">Forgot password</h1>
        <p className="text-placeholder">
          Remembered your password?{" "}
          <Link
            className="text-secondary hover:text-primary transition-colors"
            to="/signin"
          >
            Sign in
          </Link>
        </p>
      </div>

      <div className="flex flex-col gap-6 w-full max-w-lg mt-4">
        {showEmailSent ? (
          <p className="text-secondary text-center">
            If the email you entered is associated with an account, you will
            receive an email with instructions to reset your password.
          </p>
        ) : (
          <Fragment>
            <form className="flex flex-col" onSubmit={onSubmit}>
              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="john@appleseed.com"
                control={control}
              />
              <div className="flex flex-col mt-3">
                <Button size="lg">Send password request</Button>
              </div>
            </form>

            {errors.root && <ErrorMessage label="Form" error={errors.root} />}
          </Fragment>
        )}
      </div>
    </div>
  );
}

const ProtectedForgotPassword = withAuth(ForgotPassword, { type: "public" });

export default ProtectedForgotPassword;
