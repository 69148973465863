"use client";

import {
  ButtonHTMLAttributes,
  Children,
  cloneElement,
  ComponentType,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  SVGProps,
} from "react";
import { Slot } from "@radix-ui/react-slot";

import { ActivityIndicator } from "@baselayer/ui/components/ActivityIndicator";
import { cn } from "@baselayer/ui/lib/cn";

export interface ButtonProps
  extends PropsWithChildren,
    ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  className?: string;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "subtle";
  danger?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  busy?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      asChild,
      children,
      variant = "primary",
      danger,
      size = "md",
      icon: Icon,
      busy,
      ...props
    },
    ref,
  ) {
    const variantClassName = cn([
      variant === "primary" &&
        "bg-reverse text-reverse ring-1 ring-inset shadow ring-reverse",
      variant === "secondary" && [
        "bg-action text-primary ring-1 ring-inset ring-action shadow not-disabled:hover:bg-action-active",
        "data-[state=open]:bg-action-active",
      ],
      variant === "subtle" &&
        "-m-0.5 hover:not-disabled:bg-subtle data-[state=open]:bg-subtle",
    ]);
    const dangerClassName = cn({
      "bg-danger-subtle text-danger ring-1 ring-inset ring-danger not-disabled:hover:bg-danger-subtle-active":
        variant === "primary" || variant === "secondary",
      "text-danger hover:not-disabled:bg-danger-subtle": variant === "subtle",
    });
    const sizeClassName = cn({
      "px-2 h-7 text-[13px] rounded-lg": size === "xs",
      "px-3 h-9 text-sm": size === "sm",
      "px-3.5 h-10 text-[15px]": size === "md",
      "px-4.5 h-11": size === "lg",
    });
    const className = cn(
      "flex items-center justify-center shrink-0 rounded-xl group/button",
      "font-medium overflow-hidden relative",
      "disabled:cursor-not-allowed disabled:opacity-40",
      "transition-opacity duration-100",
      variantClassName,
      danger && dangerClassName,
      sizeClassName,
      props.className,
    );

    const Component = asChild ? Slot : "button";
    let content = children;
    if (asChild) {
      const onlyChild = Children.toArray(children).at(0);
      if (isValidElement(onlyChild)) {
        content = onlyChild.props.children;
      }
    }

    const child = (
      <span className="flex items-center justify-center whitespace-nowrap">
        {variant === "primary" && (
          <span
            className={cn(
              "absolute inset-0 bg-gradient-to-b text-reverse from-current to-transparent",
              "opacity-0 group-hover/button:opacity-15",
              "transition-opacity duration-100",
            )}
          />
        )}
        <span
          className={cn(
            "flex items-center justify-center transition-opacity duration-100",
            {
              "gap-1.5": size === "xs",
              "gap-2": size === "sm" || size === "md" || size === "lg",
            },
            busy && "opacity-40",
          )}
        >
          {Icon && (
            <Icon
              aria-hidden="true"
              className={cn(
                variant === "primary" ? "text-icon-reverse" : "text-icon",
                "shrink-0",
                {
                  "w-3.5 h-3.5": size === "xs",
                  "w-4 h-4": size === "sm" || size === "md" || size === "lg",
                },
                danger && "text-danger",
              )}
            />
          )}
          <span className="flex items-center justify-center">{content}</span>
        </span>

        <span
          className="flex w-0 opacity-0 transition-all duration-250 data-[state=open]:w-6.5 data-[state=open]:opacity-100"
          aria-hidden="true"
          data-state={busy ? "open" : "closed"}
        >
          <span className="flex ml-2.5">
            <ActivityIndicator className="w-4 h-4" />
          </span>
        </span>
      </span>
    );

    let childNode = child;
    if (asChild) {
      const onlyChild = Children.toArray(children).at(0);
      if (isValidElement(onlyChild)) {
        childNode = cloneElement(onlyChild, undefined, child);
      }
    }

    return (
      <Component
        ref={ref}
        {...props}
        className={className}
        onClick={props.disabled ? undefined : props.onClick}
        onPointerDown={props.disabled ? undefined : props.onPointerDown}
        aria-busy={busy}
      >
        {childNode}
      </Component>
    );
  },
);
