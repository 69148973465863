import { DotsHorizontal, Mail01 } from "@untitled-ui/icons-react";
import {
  useInfiniteData,
  useOrganizationsInvitesInfiniteQuery,
} from "api-client";

import { revokeInviteAction } from "~/actions";
import { ActionMenu } from "~/components/ActionMenu";
import { IconButton } from "~/components/IconButton";

export interface InviteListProps {
  org: string;
}

export function InviteList({ org }: InviteListProps) {
  const invitesQuery = useOrganizationsInvitesInfiniteQuery({
    organizationSlug: org,
  });
  const invites = useInfiniteData(invitesQuery.data);

  return (
    <section className="flex flex-col gap-3">
      <h2 className="font-medium">Invites</h2>

      {invites.length > 0 && (
        <ul className="flex flex-col divide-y divide-primary">
          {invites.map((invite) => (
            <li
              key={invite.id}
              className="items-center grid gap-2 grid-cols-[6fr,1fr,1fr] py-3"
            >
              <div className="flex items-center gap-2.5 mr-2">
                <span className="shrink-0 rounded-full bg-avatar w-6 sm:w-8 h-6 sm:h-8 flex items-center justify-center">
                  <Mail01 className="w-3.5 sm:w-5 h-3.5 sm:h-5 text-secondary" />
                </span>

                <dl className="flex-1 items-center overflow-hidden grid grid-cols-subgrid">
                  <div className="flex flex-col flex-1 -space-y-0.5 min-w-8">
                    <dt className="sr-only">Email</dt>
                    <dd className="truncate text-sm sm:text-base">
                      {invite.recipient_email}
                    </dd>
                  </div>
                </dl>
              </div>

              <dl className="flex-1 mr-2">
                <dt className="sr-only">Role</dt>
                <dd className="text-secondary text-sm sm:text-base">Member</dd>
              </dl>

              <div className="flex justify-end">
                <ActionMenu
                  target={invite}
                  actions={[revokeInviteAction]}
                  trigger={
                    <IconButton
                      hideTooltip
                      icon={DotsHorizontal}
                      accessibilityLabel="Actions"
                    />
                  }
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
