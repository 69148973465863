import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { AtSign, XClose } from "@untitled-ui/icons-react";
import {
  createPresignedPost,
  getOptimisticOrganization,
  useCreateOrganizationMutation,
} from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { ErrorMessage } from "~/components/ErrorMessage";
import {
  FileInput,
  FileInputPreview,
  FileInputRemove,
  FileInputTrigger,
} from "~/components/FileInput";
import { OrganizationAvatar } from "~/components/OrganizationAvatar";
import { TextField } from "~/components/TextField";
import { useCurrentUser } from "~/lib/current";
import { useFlagEnabled } from "~/lib/flags";
import { handleFormErrors } from "~/lib/handleFormErrors";
import { uploadFileMutationOptions } from "~/mutations";
import { Navigate, useNavigate } from "~/router";

const CreateOrganizationFormSchema = z.object({
  name: z.string(),
  slug: z.string().max(64, "URL must be 64 characters or less"),
  avatar_key: z.string().optional(),
  username: z.string(),
});

type CreateOrganizationForm = z.infer<typeof CreateOrganizationFormSchema>;

function CreateOrganization() {
  const {
    formState: { errors, touchedFields },
    setError,
    resetField,
    watch,
    setValue,
    handleSubmit,
    control,
  } = useForm<CreateOrganizationForm>({
    resolver: zodResolver(CreateOrganizationFormSchema),
    defaultValues: {
      name: "",
      slug: "",
      avatar_key: undefined,
      username: "",
    },
  });

  const navigate = useNavigate();
  const create = useCreateOrganizationMutation();

  const orgName = watch("name");
  const slug = watch("slug");
  const username = watch("username");

  const avatarKey = watch("avatar_key");
  const uploadFileMutation = useMutation(uploadFileMutationOptions());

  useEffect(() => {
    if (!orgName) {
      resetField("slug", { keepTouched: false });
      return;
    }

    if (touchedFields?.slug) {
      return;
    }

    setValue("slug", orgName.toLowerCase().replace(/\s/g, "-").slice(0, 64));
  }, [orgName, setValue, touchedFields, resetField]);

  const onSubmit = handleSubmit(
    async ({ username, ...data }: CreateOrganizationForm) => {
      create.mutate(
        { ...data, organization_membership: { username } },
        {
          onSuccess(membership) {
            navigate("/o/:org", {
              params: { org: membership.organization.slug },
            });
          },
          onError: (error) => handleFormErrors(error, setError),
        },
      );
    },
  );

  const alphaEnabled = useFlagEnabled("alpha");
  const me = useCurrentUser();
  if (!me) return null;
  if (!me.display_name) {
    return <Navigate to="/account" replace />;
  } else if (me.organization_memberships.length > 0) {
    return <Navigate to="/waitlist" replace />;
  } else if (alphaEnabled) {
    return (
      <Navigate
        to="/o/:org"
        params={{ org: me.organization_memberships[0].organization.slug }}
        replace
      />
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1 text-icon w-full mb-6">
        <h2 className="text-xl font-semibold text-primary">
          Create organization
        </h2>
        <p className="text-secondary">
          Collaborate with your team on applications
        </p>
      </div>

      <div className="w-full">
        <form className="flex flex-col gap-4 mb-4" onSubmit={onSubmit}>
          <div className="flex flex-col gap-2 items-start">
            <FieldLabel htmlFor="avatar">Organization avatar</FieldLabel>

            <FileInput
              className="flex items-start gap-2 group/input"
              name="avatar"
              accept={["image"]}
              maxSizeMb={2}
              value={null}
              onValueChange={(blob) => {
                if (!blob) {
                  setValue("avatar_key", undefined, {
                    shouldDirty: true,
                  });
                } else {
                  uploadFileMutation.mutate(
                    {
                      file: blob,
                      presign: () =>
                        createPresignedPost({ mime_type: blob.type }),
                    },
                    {
                      onSuccess(key) {
                        setValue("avatar_key", key, {
                          shouldDirty: true,
                        });
                      },
                    },
                  );
                }
              }}
              onError={(error) => {
                if (error.code === "file-too-large") {
                  toast.error(`File is too large (max 2MB)`);
                } else if (error.code === "file-invalid-type") {
                  toast.error(`Invalid file type (only *.png, *.jpeg)`);
                }
              }}
            >
              <div className="relative flex rounded-xl shadow cursor-pointer">
                <div
                  className={cn(
                    "absolute inset-0 rounded-xl ring-1 ring-inset ring-action-active",
                    "opacity-0 group-data-[dragging=true]/input:opacity-100",
                    "pointer-events-none",
                  )}
                />

                <FileInputPreview asChild>
                  {(url) => (
                    <div className="flex group-data-[dragging=true]/input:opacity-50">
                      <OrganizationAvatar
                        size="lg"
                        organization={getOptimisticOrganization({
                          name: orgName || "Acme",
                          slug: slug,
                          organization_membership: { username },
                          avatar_url: url,
                          avatar_key: avatarKey,
                        })}
                      />
                    </div>
                  )}
                </FileInputPreview>

                <FileInputRemove asChild>
                  <button
                    className={cn(
                      "dark absolute -top-1.5 -left-1.5 z-10 bg-main rounded-full p-1",
                      "ring-1 ring-inset ring-primary shadow",
                      "opacity-0 focus:opacity-100 group-hover/input:opacity-100",
                      "group-data-[dragging=true]/input:!opacity-0",
                      "transition-opacity duration-150",
                    )}
                  >
                    <XClose className="text-primary w-2.5 h-2.5 [&_*]:stroke-3" />
                    <span className="sr-only">Remove avatar</span>
                  </button>
                </FileInputRemove>
              </div>

              <div className="flex flex-col items-start gap-1.5">
                <FileInputTrigger asChild>
                  <Button type="button" size="sm" variant="secondary">
                    Upload image
                  </Button>
                </FileInputTrigger>

                <p className="text-xs text-placeholder">
                  *.png and *.jpeg files up to 2MB
                </p>
              </div>
            </FileInput>
          </div>

          <TextField
            id="name"
            name="name"
            label="Organization name"
            type="text"
            placeholder="Acme Inc."
            control={control}
          />

          <div className="flex flex-col gap-1.5">
            <TextField
              name="slug"
              label="Organization URL"
              type="text"
              placeholder="acme-inc"
              leadingAccessory={<p>https://baselayer.app/t/</p>}
              control={control}
            />
            <p
              className={cn(
                "text-placeholder text-xs text-right -mb-4.5",
                slug.length > 64 && "!text-danger",
              )}
            >
              {slug.length} / 64
            </p>
          </div>

          <TextField
            name="username"
            label="Your username"
            type="text"
            placeholder="johnny"
            leadingAccessory={<AtSign />}
            control={control}
          />

          <div className="flex flex-col mt-1">
            <Button size="lg">Create organization</Button>
          </div>
        </form>

        {errors.root && <ErrorMessage label="Form" error={errors.root} />}
      </div>
    </div>
  );
}

export default CreateOrganization;
