import { useRef } from "react";
import { ApiModel } from "api-client";

import { Button } from "@baselayer/ui/components/Button";

import { ExtractContext, PropsWithExtraContext } from "~/components/ActionMenu";
import { apply, useAction, useActionContext } from "~/lib/actions";
import { ActionReactContext } from "~/lib/actions/context";
import { Action, Prettify } from "~/types";

export type ActionButtonProps<T extends ApiModel, A extends Action<T, any>> = {
  target: T | null;
  action: A;
  variant?: "primary" | "secondary" | "subtle";
  size?: "xs" | "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
} & PropsWithExtraContext<Prettify<ExtractContext<[A]>>>;

export function ActionButton<T extends ApiModel, A extends Action<T, any>>({
  action,
  target,
  variant,
  size,
  type,
  ...props
}: ActionButtonProps<T, A>) {
  const ref = useRef<HTMLButtonElement>(null);

  const context = useActionContext(target, props.context);
  const label = context ? apply(action.label)(context) : "";
  const disabled =
    context && action.disabled ? apply(action.disabled)(context) : false;
  const actionVariant = context
    ? apply(action.variant ?? "primary")(context)
    : undefined;

  const [node, execute] = useAction(target, props.context, {
    onCleanup() {
      ref.current?.focus();
    },
  });

  if (action.applicable && !action.applicable?.(context)) return null;

  return (
    <ActionReactContext.Provider value={context}>
      {node}
      <Button
        ref={ref}
        type={type}
        icon={action.icon}
        variant={variant}
        disabled={disabled}
        size={size}
        danger={actionVariant === "danger"}
        onClick={() => execute(action)}
      >
        {label}
      </Button>
    </ActionReactContext.Provider>
  );
}
