import { ReverseLeft } from "@untitled-ui/icons-react";

import { cn } from "@baselayer/ui/lib/cn";

import { EmailForm } from "~/components/_signin/EmailForm";
import { OAuthProviders } from "~/components/_signin/OAuthProviders";
import { withAuth } from "~/lib/auth";
import { Logo } from "~/svgs/Logo";

function SignIn() {
  return (
    <div className="flex flex-1 flex-col">
      <div className="hidden todesktop:flex h-12 app-drag" />

      <div className="p-4 py-12 gap-4 flex-1 flex flex-col items-center justify-center">
        <div className="flex flex-col gap-1 items-center text-center w-full max-w-lg">
          <div className="w-8 aspect-square text-icon-subtle mb-2.5">
            <Logo />
          </div>
          <h1 className="text-xl font-semibold text-primary">
            Welcome to Baselayer
          </h1>
          <p className="text-placeholder">
            Access, share, and collaborate on product data.
          </p>
        </div>

        <div className="flex flex-col gap-6 w-full max-w-lg mt-4">
          <EmailForm />

          <div className="flex items-center gap-4">
            <div className="h-0 flex-1 border-t border-primary" />
            <p className="text-secondary text-sm">or</p>
            <div className="h-0 flex-1 border-t border-primary" />
          </div>

          <OAuthProviders />
        </div>
      </div>

      <div className="p-4 flex items-center justify-center">
        <a
          href="/"
          className={cn(
            "flex items-center gap-2 py-1.5 px-2.5 rounded-lg",
            "hover:bg-subtle",
          )}
        >
          <ReverseLeft className="w-3.5 h-3.5 text-icon" aria-hidden />
          <span className="text-sm text-secondary">Back to home</span>
        </a>
      </div>
    </div>
  );
}

const ProtectedSignIn = withAuth(SignIn, { type: "unauthenticated" });

export default ProtectedSignIn;
