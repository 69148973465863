import { Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { organizationsModelsRecordQueryOptions } from "api-client";

import NotFound from "~/pages/404";
import { useParams } from "~/router";

function RecordLayout() {
  const params = useParams("/o/:org/w/:app/m/:model/r/:record");
  const record = useQuery({
    ...organizationsModelsRecordQueryOptions({
      organizationSlug: params.org,
      modelId: params.model,
      id: params.record,
    }),
    refetchOnWindowFocus: "always",
    refetchOnMount: "always",
  });

  if (record.isPending) return null;

  if (!record.data) {
    return <NotFound />;
  }

  return (
    <Outlet
      key={`${params.org}/${params.app}/${params.model}/${params.record}`}
    />
  );
}

export default RecordLayout;
