import { useState } from "react";
import { Plus } from "@untitled-ui/icons-react";
import {
  infinitePrepend,
  ModelFieldManyReferenceView,
  ModelRecord,
  organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions,
  queryClient,
  Where,
} from "api-client";
import { useDebounce } from "use-debounce";

import { Button } from "@baselayer/ui/components/Button";

import { FieldLabel } from "~/components/_fields/FieldLabel";
import { NewRecordDialog } from "~/components/_records/NewRecordDialog";
import { RecordTable } from "~/components/_records/RecordTable";
import { Link } from "~/router";

export interface ManyReferenceFieldProps {
  field: ModelFieldManyReferenceView;
  record: ModelRecord | null;
}

export function ManyReferenceField({ field, record }: ManyReferenceFieldProps) {
  const [where, setWhere] = useState<Where>({});
  const [debouncedWhere] = useDebounce(where, 300, { leading: true });

  return (
    <div className="flex flex-col gap-2 group">
      <FieldLabel
        htmlFor={field.id}
        readOnly={field.read_only}
        className="px-4 w-full max-w-2xl mx-auto"
      >
        {field.name}
      </FieldLabel>

      <div id={field.id} className="mt-0.5">
        <RecordTable
          variant="contained"
          record={record}
          models={field.models}
          where={where}
          onWhereChange={setWhere}
          readOnly={field.read_only}
          queryOptions={{
            ...organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions({
              organizationSlug: record?.organization_slug ?? "",
              modelId: record?.model_id ?? "",
              recordId: record?.id ?? "",
              fieldName: field.field_name,
              where: debouncedWhere,
            }),
            enabled: !!record,
          }}
          renderTitleCell={({ record, className, children }) => (
            <Link
              to="/o/:org/w/:app/m/:model/r/:record"
              params={{
                org: record.organization_slug,
                app: record.application_slug,
                model: record.model_id,
                record: record.id,
              }}
              className={className}
            >
              {children}
            </Link>
          )}
          actions={
            !field.read_only ? (
              <NewRecordDialog
                trigger={
                  <Button icon={Plus} size="sm" variant="secondary">
                    New {field.names.humanized_singular}
                  </Button>
                }
                field={field}
                record={record}
                onSuccess={(newRecord) => {
                  const query =
                    organizationsModelsRecordsFieldsRecordsInfiniteQueryOptions(
                      {
                        organizationSlug: record?.organization_slug ?? "",
                        modelId: record?.model_id ?? "",
                        recordId: record?.id ?? "",
                        fieldName: field.field_name,
                      },
                    );
                  queryClient.setQueryData(query.queryKey, (cache) => {
                    if (!cache) return cache;
                    return infinitePrepend(cache, newRecord);
                  });
                  queryClient.invalidateQueries(query);
                }}
              />
            ) : undefined
          }
        />
      </div>
    </div>
  );
}
