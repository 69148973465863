import { useCallback, useEffect, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  createModelRecordViewMutationOptions,
  createModelViewMutationOptions,
  Model,
  ModelRecord,
  touchViewMutationOptions,
  View,
} from "api-client";

export function useLogView(model: Model | null, record?: ModelRecord | null) {
  const viewRef = useRef<View | null>(null);

  const createModelViewMutation = useMutation(createModelViewMutationOptions());
  const createModelRecordViewMutation = useMutation(
    createModelRecordViewMutationOptions(),
  );
  const log = useCallback(() => {
    if (!model) return;

    if (typeof record === "undefined") {
      createModelViewMutation.mutate(
        {
          organizationSlug: model.organization_slug,
          modelId: model.id,
        },
        {
          onSuccess(data) {
            viewRef.current = data;
          },
        },
      );
    } else if (record) {
      createModelRecordViewMutation.mutate(
        {
          organizationSlug: record.organization_slug,
          modelId: record.model_id,
          recordId: record.id,
        },
        {
          onSuccess(data) {
            viewRef.current = data;
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, record]);

  const touchViewMutation = useMutation(touchViewMutationOptions());
  const touch = useCallback(() => {
    if (!viewRef.current) return;
    touchViewMutation.mutate({
      organizationSlug: viewRef.current.viewer.organization_slug,
      id: viewRef.current.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Log the view when the component is mounted
    if (!viewRef.current) {
      log();
    }
    // Touch the view when the window is about to unload (i.e., window is closed)
    window.addEventListener("beforeunload", touch);

    return () => {
      window.removeEventListener("beforeunload", touch);
      // Touch the view when the component is unmounted
      touch();
    };
  }, [log, touch]);
}
