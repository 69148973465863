import { Outlet } from "react-router-dom";
import { useOrganizationsModelQuery } from "api-client";

import NotFound from "~/pages/404";
import { useParams } from "~/router";

function ModelLayout() {
  const params = useParams("/o/:org/w/:app/m/:model");
  const model = useOrganizationsModelQuery({
    organizationSlug: params.org,
    id: params.model,
  });

  if (model.isPending) return null;

  if (!model.data) {
    return <NotFound />;
  }

  return <Outlet key={`${params.org}/${params.app}/${params.model}`} />;
}

export default ModelLayout;
