import { useMemo } from "react";
import { ChevronDown, XClose } from "@untitled-ui/icons-react";
import { Filter, Model, ModelFieldBooleanView } from "api-client";

import { cn } from "@baselayer/ui/lib/cn";

import { DropdownItem, DropdownMenu } from "~/components/DropdownMenu";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { UnionKeys } from "~/types";

const BOOLEAN_OPERATORS = {
  eq: "is",
  neq: "is not",
  gt: "",
  gte: "",
  lt: "",
  lte: "",
  like: "",
  nlike: "",
  starts: "",
  ends: "",
} satisfies Record<
  UnionKeys<Filter<boolean>>,
  string | ((value: boolean | null) => string)
>;

export interface BooleanFieldFilterProps {
  models: Model[];
  field: ModelFieldBooleanView;
  value: Filter<boolean>;
  onValueChange: (value: Filter<boolean>) => void;
  onRemove: () => void;
}

export function BooleanFieldFilter({
  models,
  field,
  value,
  onValueChange,
  onRemove,
}: BooleanFieldFilterProps) {
  const rawValue = Object.values(value)[0];

  const recordName = useMemo(() => {
    if (!models.length || models.length > 1) {
      return "Record";
    } else {
      return models[0].names.camel_singular;
    }
  }, [models]);

  return (
    <div
      className={cn(
        "sm:contents max-sm:border-t first-of-type:border-t-0 border-primary group/filter",
        "items-center sm:rounded-xl sm:h-9 py-3 first-of-type:pt-0 last-of-type:pb-0",
        "relative overflow-hidden group/filter",
      )}
    >
      <div className="flex items-center gap-2 pl-1.5 pr-2 sm:max-w-[220px] mr-7 sm:mr-0">
        <Icon
          className="w-4 h-4 text-icon shrink-0"
          name={field.icon}
          fallback={<div className="w-4 h-4 rounded bg-avatar shrink-0" />}
        />
        <p className="text-sm font-medium truncate">{recordName}</p>
      </div>

      <div className="grid sm:contents min-[450px]:grid-cols-2 items-center gap-1 sm:gap-0 mt-2 sm:mt-0">
        <DropdownMenu
          modal
          value={rawValue ? BOOLEAN_OPERATORS["eq"] : BOOLEAN_OPERATORS["neq"]}
          align="start"
          trigger={
            <button
              className={cn(
                "pl-2.5 pr-2 h-9 border border-action shadow rounded-xl bg-action hover:bg-action-active",
                "flex items-center gap-1.5 w-full max-sm:overflow-hidden",
                "data-[state=open]:bg-action-active",
              )}
            >
              <p className="text-sm whitespace-nowrap flex-1 text-left">
                {rawValue ? BOOLEAN_OPERATORS["eq"] : BOOLEAN_OPERATORS["neq"]}
              </p>
              <ChevronDown className="w-4 h-4 text-icon" />
            </button>
          }
          title="Type"
        >
          <DropdownItem onSelect={() => onValueChange({ eq: true })}>
            {BOOLEAN_OPERATORS["eq"]}
          </DropdownItem>
          <DropdownItem onSelect={() => onValueChange({ eq: false })}>
            {BOOLEAN_OPERATORS["neq"]}
          </DropdownItem>
        </DropdownMenu>

        <div className="px-2 flex items-center">
          <p className="text-sm whitespace-nowrap font-medium">
            {field.names.humanized}
          </p>
        </div>
      </div>

      <div className="px-0.5 flex items-center max-sm:absolute max-sm:top-2.5 group-first-of-type/filter:max-sm:-top-0.5 max-sm:right-0">
        <IconButton
          icon={XClose}
          variant="subtle"
          accessibilityLabel="Remove filter"
          hideTooltip
          type="button"
          onClick={onRemove}
        />
      </div>
    </div>
  );
}
