import { useEffect, useId, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Repeat01 } from "@untitled-ui/icons-react";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { FieldLabel } from "~/components/_fields/FieldLabel";

const ACCEPT_TYPES = {
  image: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
};

export interface FileFieldProps {
  label: string;
  hideLabel?: boolean;
  readOnly?: boolean;
  value: string | Blob | null;
  onValueChange(value: Blob | null): void;
  busy?: boolean;
  accept?: (keyof typeof ACCEPT_TYPES)[];
  maxSizeMb?: number;
}

export function FileField({
  label,
  hideLabel,
  readOnly,
  value,
  onValueChange,
  busy,
  accept = [],
  maxSizeMb = 1,
}: FileFieldProps) {
  const id = useId();

  const [internalValue, setInternalValue] = useState<string | Blob | null>(
    value,
  );
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const [isDragging, setIsDragging] = useState(false);
  const dropzone = useDropzone({
    accept: accept
      .map((key) => ACCEPT_TYPES[key])
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    maxSize: maxSizeMb * 1024 * 1024,
    maxFiles: 1,
    noClick: true,
    onDropAccepted(files) {
      setInternalValue(files[0]);
      onValueChange(files[0]);
    },
    onDragEnter() {
      setIsDragging(true);
    },
    onDragLeave() {
      setIsDragging(false);
    },
    onDrop() {
      setIsDragging(false);
    },
    disabled: busy,
  });

  let previewUrl: string | undefined;
  if (typeof internalValue === "string") {
    previewUrl = internalValue;
  } else if (internalValue) {
    previewUrl = URL.createObjectURL(internalValue);
  }

  return (
    <div className="flex flex-col gap-2 flex-1 items-start group">
      <FieldLabel hidden={hideLabel} htmlFor={id} readOnly={readOnly}>
        {label}
      </FieldLabel>

      <div
        {...dropzone.getRootProps({
          className: cn(
            "flex flex-col w-full items-center",
            "p-3 gap-4",
            "bg-action text-primary rounded-xl border border-action shadow",
            isDragging && "bg-action-active",
            "focus-visible:focus-outline",
          ),
        })}
      >
        <input {...dropzone.getInputProps()} />

        <div
          className={cn(
            "w-full h-44 mt-2 flex items-center justify-center",
            (isDragging || busy) && "opacity-50",
          )}
        >
          {previewUrl ? (
            <img
              className="max-w-full max-h-full rounded-xl shadow"
              src={previewUrl}
              alt="Preview"
            />
          ) : (
            <p className="text-placeholder">
              {isDragging ? "Release" : "Drag and drop"} to upload
            </p>
          )}
        </div>

        {previewUrl && (
          <div
            className={cn(
              "flex justify-center w-full gap-2",
              isDragging && "opacity-50",
            )}
          >
            <Button
              disabled={busy}
              type="button"
              variant="secondary"
              icon={Repeat01}
              onClick={() => {
                dropzone.open();
              }}
            >
              Replace
            </Button>
            <Button
              disabled={busy}
              type="button"
              danger
              onClick={() => {
                setInternalValue(null);
                onValueChange(null);
              }}
            >
              Remove
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
