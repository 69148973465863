import { ReactNode, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";
import { useLocation } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { createFeedbackMutationOptions } from "api-client";
import { z } from "zod";

import { Button } from "@baselayer/ui/components/Button";
import { cn } from "@baselayer/ui/lib/cn";

import { Form } from "~/components/_forms/Form";
import { Popover } from "~/components/Popover";
import { TextAreaField } from "~/components/TextAreaField";
import { useScreenMatches } from "~/lib/breakpoint";
import { useParams } from "~/router";
import { Bluesky } from "~/svgs/Bluesky";

const feedbackSchema = z.object({
  description: z.string().trim().min(1, ""),
});
type FeedbackSchema = z.infer<typeof feedbackSchema>;

export interface FeedbackPopoverProps {
  trigger?: ReactNode;
}

export function FeedbackPopover({ trigger }: FeedbackPopoverProps) {
  const [open, setOpen] = useState(false);
  const params = useParams("/o/:org/w/:app");
  const location = useLocation();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const isTablet = useScreenMatches("md");

  const methods = useForm<FeedbackSchema>({
    resolver: zodResolver(feedbackSchema),
    defaultValues: {
      description: "",
    },
  });

  const createFeedbackMutation = useMutation(createFeedbackMutationOptions());

  const submit = methods.handleSubmit((data) => {
    createFeedbackMutation.mutate(
      {
        description: data.description,
        organization_slug: params.org,
        application_slug: params.app,
        pathname: location.pathname,
      },
      {
        onSuccess() {
          setOpen(false);
          methods.reset();
          toast.success("Your feedback was submitted. Thank you!");
        },
      },
    );
  });

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      modal
      dark={false}
      align="end"
      alignOffset={-8}
      sideOffset={22}
      side={isTablet ? "top" : "right"}
      trigger={trigger}
      title="Feedback"
      onOpenAutoFocus={(event) => {
        if (textAreaRef.current) {
          event.preventDefault();
          textAreaRef.current.focus();
        }
      }}
    >
      <Form
        onSubmit={submit}
        className="flex flex-col gap-2 p-4 sm:p-2.5 w-full sm:w-[28rem]"
      >
        <TextAreaField
          ref={textAreaRef}
          label="Feedback"
          hideLabel
          name="description"
          placeholder="Feedback, feature requests, bug reports..."
          control={methods.control}
          size="sm"
          rows={3}
          readOnly={createFeedbackMutation.isPending}
        />

        <div className="flex items-center justify-between mt-px">
          <a
            className={cn(
              "flex items-center gap-1.5 group/link",
              "-mx-0.5 px-2 py-1.5 rounded-lg",
            )}
            href="https://bsky.app/profile/baselayer.app"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Baselayer on Bluesky"
          >
            <Bluesky className="w-4 h-4 text-icon-subtle group-hover/link:text-icon" />
            <span className="text-sm text-secondary group-hover/link:text-primary">
              @baselayer.app
            </span>
          </a>

          <Button
            size="sm"
            busy={createFeedbackMutation.isPending}
            disabled={!methods.formState.isValid}
          >
            Send feedback
          </Button>
        </div>
      </Form>
    </Popover>
  );
}
